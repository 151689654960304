.aboutusPage {
    margin-top: 7.8rem;
    display: flex;
    align-items: center;
    padding: 8rem 0;

    @media (max-width: $desktopSmall - 1) {
        margin-top: 11.9rem;
    }

    @media (max-width: $mobileBig) {
        margin-top: 10.9rem;
        padding: 3rem 0;
    }

    @media (max-width: $mobile) {
        margin-top: 9rem;
    }

    h2 {
        font-size: 6.5rem;
        font-family: $secondary_font;
        font-weight: 700;
        color: $primary_color;
        margin: 0 0 1.5rem;

        background: #f9c802;
        background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
        background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        position: relative;

        &:before {
            content: attr(data-heading);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            text-shadow: .3rem 0 0 rgba(#05164e, 0.6);
            z-index: -1;
            color: transparent;
        }

        @media (max-width: $mobileBig) {
            font-size: 4.5rem;
        }
    }

    p {
        font-size: 1.5rem;
        font-family: $primary_font;
        font-weight: 300;
        color: $heading_color;
        letter-spacing: 0.01rem;
        margin: 0;
    }
}

.howitworks {
    padding: 8rem 0 0;
    background-color: $primary_color;
    position: relative;
    z-index: 0;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 7.5rem;
        background-color: #e9141a;
        z-index: -1;

        @media (max-width: $desktopSmall - 1) {
            display: none;
        }
    }

    @media (max-width: $desktop - 1) {
        background-size: auto 100%;
        background-position: center center;
    }

    @media (max-width: $desktopSmall - 1) {
        padding: 5rem 0;
    }

    @media (max-width: $mobileBig) {
        background: $primary_color none;
        padding: 3rem 0;
    }

    &__heading {
        display: flex;
        flex-direction: column;
        margin: 0 0 5rem;

        @media (max-width: $mobileBig) {
            align-items: center;
        }

        h2 {
            font-size: 5.5rem;
            font-family: $secondary_font;
            font-weight: 200;
            line-height: 1.2;
            margin: 0;

            background: #f9c802;
            background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
            background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
            background-size: 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            position: relative;

            &:before {
                content: attr(data-heading);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                text-shadow: .3rem 0 0 #04113c;
                z-index: -1;
                color: transparent;
            }

            @media (max-width: $mobileBig) {
                font-size: 3.5rem;
            }
        }
    }

    &__list {
        height: 100%;
        display: flex;
        align-items: center;

        ul {
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;

                &:not(:last-child) {
                    margin-bottom: 5rem;
                }

                .img {
                    display: flex;

                    img {
                        width: 100%;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 3.2rem);

                    h4 {
                        font-family: $primary_font;
                        font-weight: 600;
                        font-size: 1.9rem;
                        line-height: 1.2;
                        letter-spacing: 0.01rem;
                        color: $white_color;
                        margin: 0 0 1rem;
                    }

                    p {
                        font-family: $primary_font;
                        font-weight: 300;
                        font-size: 1.6rem;
                        line-height: 1.2;
                        letter-spacing: 0.01rem;
                        color: $white_color;
                        margin: 0;
                    }
                }
            }
        }

        &--left {

            ul {

                li {
                    flex-direction: row-reverse;

                    @media (max-width: $desktopSmall - 1) {
                        flex-direction: row;
                    }

                    @media (max-width: $mobileBig) {

                        &:last-child {
                            margin-bottom: 5rem;
                        }
                    }

                    .content {
                        text-align: right;
                        padding-right: 1.5rem;

                        @media (max-width: $desktopSmall - 1) {
                            text-align: left;
                            padding-right: 0;
                            padding-left: 1.5rem;
                        }
                    }
                }
            }
        }

        &--right {

            ul {

                li {
                    flex-direction: row;

                    .content {
                        text-align: left;
                        padding-left: 1.5rem;
                    }
                }
            }
        }
    }
}

.whyplaybharatludo {
    background: transparent url(../img/aboutus/whyplaybharatludo-bg.jpg) no-repeat center center / cover;

    h2 {
        font-size: 7rem;
        font-family: $secondary_font;
        font-weight: 700;
        line-height: 1.75;
        color: $primary_color;
        margin: 0 0 1.5rem;

        background: #f9c802;
        background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
        background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        position: relative;

        &:before {
            content: attr(data-heading);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            text-shadow: .3rem 0 0 rgba(#05164e, 0.6);
            z-index: -1;
            color: transparent;
        }

        @media (max-width: $mobileBig) {
            font-size: 5rem;
        }
    }

    p {
        font-size: 1.7rem;
        font-family: $primary_font;
        font-weight: 300;
        color: $white_color;
        letter-spacing: 0.01rem;
        margin: 0;
    }
}