.featuresPage {
    margin-top: 7.8rem;
    display: flex;
    align-items: center;
    padding: 8rem 0;

    @media (max-width: $desktopSmall - 1) {
        margin-top: 11.9rem;
    }

    @media (max-width: $mobileBig) {
        margin-top: 10.9rem;
        padding: 3rem 0;
    }

    @media (max-width: $mobile) {
        margin-top: 9rem;
    }

    h2 {
        font-size: 6.5rem;
        font-family: $secondary_font;
        font-weight: 700;
        color: $primary_color;
        margin: 0 0 1.5rem;

        background: #f9c802;
        background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
        background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
        background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        position: relative;

        &:before {
            content: attr(data-heading);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            text-shadow: .3rem 0 0 rgba(#05164e, 0.6);
            z-index: -1;
            color: transparent;
        }

        @media (max-width: $mobileBig) {
            font-size: 4.5rem;
        }
    }

    p {
        font-size: 1.5rem;
        font-family: $primary_font;
        font-weight: 300;
        color: $heading_color;
        letter-spacing: 0.01rem;
        margin: 0;
    }
}

.features {
    padding: 0;
    background: $white_color url(../img/features/features-bg.png) no-repeat left -3rem center / 60% auto;
    position: relative;
    z-index: 0;

    @media (max-width: $desktop - 1) {}

    @media (max-width: $desktopSmall - 1) {}

    @media (max-width: $mobileBig) {
        background-image: none;
    }

    &__list {
        height: 100%;
        min-height: 56rem;
        display: flex;
        align-items: center;

        @media (max-width: $mobileBig) {
            min-height: auto;
        }

        ul {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 100%;

            .featuresbox {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;

                border-radius: 4rem;
                position: absolute;

                @media (max-width: $mobileBig) {
                    position: relative;
                    width: 100%;
                    margin-bottom: 3rem;
                }

                &__img {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);

                    width: 7rem;
                    height: 7rem;
                    border-radius: 50%;
                    background-color: $white_color;
                    box-shadow: -0.5rem .75rem .9rem .2rem rgba(#0424c6, 0.11);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: auto;
                    }
                }

                &__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 7rem;
                    padding-left: 8.5rem;
                    padding-right: 3rem;

                    @media (max-width: $mobile - 1) {
                        padding-left: 8rem;
                        padding-right: 1rem;
                    }

                    h4 {
                        font-family: $primary_font;
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 1.2;
                        letter-spacing: 0.01rem;
                        color: $black_color;
                        margin: 0;

                        @media (max-width: $mobile - 1) {
                            font-size: 1.4rem;
                        }
                    }

                    p {
                        font-family: $primary_font;
                        font-weight: 300;
                        font-size: 1.4rem;
                        line-height: 1.2;
                        letter-spacing: 0.01rem;
                        color: #232323;
                        margin: 0;

                        @media (max-width: $mobile - 1) {
                            font-size: 1.2rem;
                        }
                    }
                }

                &--first {
                    background-color: #01bdbe;

                    top: 1rem;
                    left: 0;

                    @media (max-width: $desktopSmall - 1) {
                        left: 0;
                    }

                    @media (max-width: $mobileBig) {
                        top: 0;
                    }
                }

                &--second {
                    background-color: #a2cc38;

                    top: 10rem;
                    left: 8rem;

                    @media (max-width: $desktopSmall - 1) {
                        left: 0;
                    }

                    @media (max-width: $mobileBig) {
                        top: 0;
                    }
                }

                &--third {
                    background-color: #f8615a;

                    top: 19rem;
                    left: 11rem;

                    @media (max-width: $desktopSmall - 1) {
                        left: 0;
                    }

                    @media (max-width: $mobileBig) {
                        top: 0;
                    }
                }

                &--fourth {
                    background-color: #e99e26;

                    bottom: 21rem;
                    left: 11rem;

                    @media (max-width: $desktopSmall - 1) {
                        left: 0;
                    }

                    @media (max-width: $mobileBig) {
                        bottom: 0;
                    }
                }
                
                &--fifth {
                    background-color: #71b1ed;

                    bottom: 12rem;
                    left: 8rem;

                    @media (max-width: $desktopSmall - 1) {
                        left: 0;
                    }

                    @media (max-width: $mobileBig) {
                        bottom: 0;
                    }
                }
                
                &--sixth {
                    background-color: #9568b5;

                    bottom: 3rem;
                    left: 0;

                    @media (max-width: $desktopSmall - 1) {
                        left: 0;
                    }

                    @media (max-width: $mobileBig) {
                        bottom: 0;
                    }
                }
            }
        }
    }
}