.download {
    padding-top: 20rem;
    position: relative;
    z-index: 0;
    background: $white_color url(../img/download/download-bg.png) no-repeat left -27.5rem center / 85%;
    
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 35%;
        height: 100%;
        background-color: rgba($color: $primary_color, $alpha: 0.13);
        z-index: -1;
    }

    @media (max-width: $desktopSmall - 1) {
        padding-top: 10rem;
        background-position: left 0 top 10rem;
        background-size: 100%;

        &::after {
            display: none;
        }
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $desktopSmall - 1) {
            margin-bottom: 6rem;
            text-align: center;
        }

        h3 {
            font-size: 5.5rem;
            font-family: $secondary_font;
            font-weight: 200;
            line-height: 1.75;
            margin: 0 0 1rem;

            background: #f9c802;
            background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
            background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
            background-size: 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            position: relative;

            &:before {
                content: attr(data-heading);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                text-shadow: .3rem 0 0 #04113c;
                z-index: -1;
                color: transparent;
            }
        }

        p {
            font-family: $primary_font;
            font-weight: 300;
            font-size: 1.8rem;
            line-height: 1.75;
            color: #232323;
            margin: 0 0 3rem;

            @media (max-width: $desktop_xl) {
                font-size: 1.4rem;
            }
        }

        ul {
            display: flex;
            align-items: center;

            @media (max-width: $desktopSmall - 1) {
                justify-content: center;
            }

            li {
                display: block;

                a {
                    display: block;
                    margin-right: 1rem;

                    img {
                        width: 12.5rem;
                    }
                }
            }
        }
    }

    &__img {

        @media (max-width: $desktopSmall - 1) {
            text-align: center;
        }

        img {
            width: auto;
        }
    }
}