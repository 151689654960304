.contactus {
	background: #dbdbf0 url(../img/contactus/form-bg.png) no-repeat right 7.5% top 17.5%;
	margin-top: 7.8rem;
	padding: 12rem 0;

	@media (max-width: $desktopSmall - 1) {
		margin-top: 11.9rem;
		padding: 5rem 0;
	}

	@media (max-width: $mobileBig) {
		margin-top: 10.9rem;
		padding: 3rem 0;
	}

	@media (max-width: $mobile) {
		margin-top: 9rem;
	}

	&__details {
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		@media (max-width: $desktopSmall - 1) {
			padding-bottom: 5rem;
		}

		@media (max-width: $mobileBig) {}

		h3 {
			font-family: $primary_font;
			font-weight: 500;
			font-size: 3rem;
			line-height: 1.2;
			color: $heading_color;
			margin: 0 0 3rem;

			@media (max-width: $mobileBig) {
				font-size: 3rem;
				text-align: center;
			}

			span {
				font-weight: 800;
			}
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			@media (max-width: $mobile - 1) {
				flex-direction: column;
			}

			li {
				width: 50%;
				display: flex;
				flex-direction: column;

				@media (max-width: $mobile - 1) {
					width: 100%;

					&:nth-last-child(2) {
						padding-top: 2rem;
						border-top: .1rem solid rgba(#000000, 0.18);
						margin-top: 2rem;
					}
				}

				&:last-child {
					width: 100%;
					padding-top: 2rem;
					border-top: .1rem solid rgba(#000000, 0.18);
					margin-top: 2rem;
				}

				// @media (max-width: $mobileBig) {

				// 	&:not(:last-child) {
				// 		padding-right: 0;
				// 		border-right: none;
				// 		margin-right: 0;
				// 		padding-bottom: 2rem;
				// 		border-bottom: .1rem solid #c1ced9;
				// 		margin-bottom: 2rem;
				// 	}
				// }

				h5 {
					font-family: $primary_font;
					font-weight: 600;
					font-size: 1.8rem;
					line-height: 3.2rem;
					letter-spacing: -0.005rem;
					color: $black_color;
					margin: 0 0 1rem;
					position: relative;
					padding-left: 4rem;

					img {
						width: auto;
						position: absolute;
						top: 1.6rem;
						left: 0;
						transform: translateY(-50%);
					}
				}

				a {
					font-family: $primary_font;
					font-weight: 500;
					font-size: 1.6rem;
					line-height: 1.2;
					color: $primary_color;
					padding-left: 4rem;
					
					&:hover {
						color: $black_color;
					}
				}

				p {
					font-family: $primary_font;
					font-weight: 500;
					font-size: 1.6rem;
					line-height: 1.75;
					color: $primary_color;
					padding-left: 4rem;
					margin: 0;
				}
			}
		}
	}

	&__form {
		background-color: #e8eaec;
		box-shadow: 0 0 1.5rem .2rem rgba(#000000, 0.24);
		padding: 3rem;
		border-radius: 1rem;

		@media (max-width: $mobile - 1) {
			padding: 1.5rem;
		}

		.form-group {
			margin: 0 0 3rem;

			input,
			select,
			textarea {
				padding: 1.2rem 2rem;
				resize: none;
				font-family: $primary_font;
				font-weight: 300;
				font-size: 1.5rem;
				line-height: 1.2;
				color: #3f3e3f;
				border: .1rem solid #ffffff;
				border-radius: 0.5rem;
				// -webkit-appearance: none;
				// appearance: none;

				background: rgb(255, 255, 255);
				background: -moz-linear-gradient(83deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 244, 1) 100%);
				background: -webkit-linear-gradient(83deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 244, 1) 100%);
				background: linear-gradient(83deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 244, 1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f2f2f4", GradientType=1);

				&::-webkit-input-placeholder {
					font-family: $primary_font;
					font-weight: 300;
					font-size: 1.5rem;
					line-height: 1.2;
					color: #3f3e3f;
				}

				&::-moz-placeholder {
					font-family: $primary_font;
					font-weight: 300;
					font-size: 1.5rem;
					line-height: 1.2;
					color: #3f3e3f;
				}

				&:-ms-input-placeholder {
					font-family: $primary_font;
					font-weight: 300;
					font-size: 1.5rem;
					line-height: 1.2;
					color: #3f3e3f;
				}

				&:-moz-placeholder {
					font-family: $primary_font;
					font-weight: 300;
					font-size: 1.5rem;
					line-height: 1.2;
					color: #3f3e3f;
				}

				@media (max-width: $mobile - 1) {
					padding: 1rem 1rem;
				}
			}
		}

		button.btn-primary {
			font-family: $primary_font;
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 1.2;
			color: #343434;
			border-radius: 1.5rem;
			overflow: hidden;
			padding: 1.5rem 5rem;
			transition: all 0.5s ease;
			width: 100%;

			background: rgb(254, 196, 45);
			background: -moz-linear-gradient(180deg, rgba(254, 196, 45, 1) 0%, rgba(255, 156, 38, 1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(254, 196, 45, 1) 0%, rgba(255, 156, 38, 1) 100%);
			background: linear-gradient(180deg, rgba(254, 196, 45, 1) 0%, rgba(255, 156, 38, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fec42d", endColorstr="#ff9c26", GradientType=1);

			&:hover {
				background: $primary_color;
				color: $white_color;
			}

			&:before,
			&:after {
				content: none;
			}
		}
	}
}