.reviews {
	padding-top: 15rem;
	position: relative;
	z-index: 0;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 40%;
		background-color: rgba($color: $primary_color, $alpha: 0.13);
		z-index: -1;

		@media (max-width: $mobileBig) {
			height: 30%;
		}
	}

	&__img {

		img {
			width: auto;
		}
	}

	&__content {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: $desktopSmall - 1) {
			margin-bottom: 6rem;
			text-align: center;
		}

		h3 {
			font-size: 4rem;
			font-family: $primary_font;
			font-weight: 700;
			line-height: 1.75;
			margin: 0 0 1rem;
		}

		a {
			display: inline-block;

			img {
				width: auto;
			}
		}
	}

	&__slider {
		margin-top: 8rem;

		* {
			outline: none !important;
		}

		.slick-track {
			padding: 4rem 0 6rem;
		}

		.item {
			background-color: $white_color;
			padding: 5rem 3rem;
			margin-right: 2rem;
			border-radius: .5rem;
			box-shadow: 0 0 1rem .1rem rgba($black_color, 0.05);

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;

			@media (max-width: $mobileBig) {
				margin: 0 1.5rem;
			}

			.propic {
				width: 10rem;
				height: 10rem;
				position: relative;

				&::after {
					content: "";
					width: 9.5rem;
					height: 7.5rem;
					background-image: url(../img/reviews/reviewsslider-propic-bg.jpg);

					position: absolute;
					top: -3rem;
					left: -5rem;
				}

				img {
					width: 10rem;
					height: 10rem;
					border-radius: 50%;
				}
			}

			.user {
				font-size: 1.5rem;
				font-family: $primary_font;
				font-weight: 700;
				line-height: 1.2;
				color: #252e3b;
				letter-spacing: 0.01rem;
				margin: 0 0 1rem;
			}

			.discription {
				color: #111111;
				font-size: 1.6rem;
				line-height: 1.75;
				margin: 0 0 2rem;
			}
		}

		.slick-dots {
			bottom: 0;
			display: flex !important;
			align-items: center;
			justify-content: center;

			li {
				background-color: #a2a2a2;
				opacity: 1;

				&.slick-active {
					background-color: #3a6dee;
					opacity: 1;
				}

				button {}
			}
		}
	}
}