.menuWrapper {
	position:fixed;
	top:0;
	right:0;
	height:100%;
	z-index:9999;
    max-width:430px;
    width: 100%;
	background:#000;
	
	opacity:0;
    visibility:hidden;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include css3Transition(150ms);
	
	&.showMenu {
		opacity:1;
		visibility:visible;
		@include css3Transition(400ms);
	}
	
	.closeMenu {
		position:absolute;
		top:30px;
		right:30px;
		width:20px;
		z-index:100;
	}
	
	.menuNav {
		position:relative;
        z-index:1;
        
        li {
            position:relative;
            display:block;
            font-size:16px;
            text-transform: uppercase;
            
            a {
                position:relative;
                display:block;
                color:#939393;
                padding:15px 60px;
            }
            
            
            &:hover {
                background: #070707;
                a {
                    color: white;
                }
            }
        }	
    }
    
    .btnBox {
        display: flex;
        padding: 40px 60px 0;

        a {
            display: block;
            width: 40px;
            height: 40px;
            font-size: 21px;
            color: black;
            line-height: 40px;
            text-align: center;
            background: white;
            margin-left: 20px;
            @include css3BorderRadius(50%);

            &:first-child {margin-left: 0;}

            &:hover {
                color: white;
                background: $secondary_color;
            }
        }
    }
}
