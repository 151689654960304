.mCustomScrollbar {
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;

    >.mCustomScrollBox {
        >.mCSB_scrollTools.mCSB_scrollTools_onDrag {
            opacity: 1;
            filter: "alpha(opacity=100)";
            -ms-filter: "alpha(opacity=100)";
        }
    }

    >.mCustomScrollBox~.mCSB_scrollTools.mCSB_scrollTools_onDrag {
        opacity: 1;
        filter: "alpha(opacity=100)";
        -ms-filter: "alpha(opacity=100)";
    }
}

.mCustomScrollbar.mCS_no_scrollbar {
    -ms-touch-action: auto;
    touch-action: auto;
}

.mCustomScrollbar.mCS_touch_action {
    -ms-touch-action: auto;
    touch-action: auto;
}

.mCustomScrollBox {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    outline: 0;
    direction: ltr;

    &:hover {
        >.mCSB_scrollTools {
            opacity: 1;
            filter: "alpha(opacity=100)";
            -ms-filter: "alpha(opacity=100)";
        }
    }

    &:hover~.mCSB_scrollTools {
        opacity: 1;
        filter: "alpha(opacity=100)";
        -ms-filter: "alpha(opacity=100)";
    }
}

.mCSB_container {
    overflow: hidden;
    width: auto;
    height: auto;
}

.mCSB_inside {
    >.mCSB_container {
        margin-right: 0;
    }
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0;
}

.mCS-dir-rtl {
    >.mCSB_inside {
        >.mCSB_container {
            margin-right: 0;
            margin-left: 30px;
        }

        >.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
            margin-left: 0;
        }

        >.mCSB_scrollTools {
            right: auto;
            left: 0;
        }

        >.mCSB_container_wrapper {
            margin-right: 0;
            margin-left: 30px;
        }
    }

    >.mCSB_outside+.mCSB_scrollTools {
        right: auto;
        left: 0;
        left: -26px;
    }

    >.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside {
        >.mCSB_scrollTools.mCSB_scrollTools_horizontal {
            right: 0;
            left: 20px;
        }

        >.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
            left: 0;
        }
    }

    >.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical {
        left: 0;
        right: auto;
    }

    >.mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical {
        left: 0;
        right: auto;
    }
}

.mCSB_scrollTools {
    position: absolute;
    width: 16px;
    height: auto;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .75;
    filter: "alpha(opacity=75)";
    -ms-filter: "alpha(opacity=75)";
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;

    .mCSB_draggerContainer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: auto;
    }

    a+.mCSB_draggerContainer {
        margin: 20px 0;
    }

    .mCSB_draggerRail {
        width: 2px;
        height: 100%;
        margin: 0 auto;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .4);
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
    }

    .mCSB_dragger {
        cursor: pointer;
        width: 100%;
        height: 30px;
        z-index: 1;

        .mCSB_dragger_bar {
            position: relative;
            width: 4px;
            height: 100%;
            margin: 0 auto;
            -webkit-border-radius: 16px;
            -moz-border-radius: 16px;
            border-radius: 16px;
            text-align: center;
            -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
            -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
            -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
            transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
            background-color: $primary_color;
            filter: "alpha(opacity=75)";
            -ms-filter: "alpha(opacity=75)";
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: $primary_color;
                filter: "alpha(opacity=85)";
                -ms-filter: "alpha(opacity=85)";
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: $primary_color;
                filter: "alpha(opacity=90)";
                -ms-filter: "alpha(opacity=90)";
            }
        }
    }

    .mCSB_buttonDown {
        display: block;
        position: absolute;
        height: 20px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
        bottom: 0;
        -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        // background-image: url(mCSB_buttons.png);
        background-repeat: no-repeat;
        opacity: .4;
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
        background-position: 0 -20px;

        &:hover {
            opacity: .75;
            filter: "alpha(opacity=75)";
            -ms-filter: "alpha(opacity=75)";
        }

        &:active {
            opacity: .9;
            filter: "alpha(opacity=90)";
            -ms-filter: "alpha(opacity=90)";
        }
    }

    .mCSB_buttonUp {
        display: block;
        position: absolute;
        height: 20px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
        -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        // background-image: url(mCSB_buttons.png);
        background-repeat: no-repeat;
        opacity: .4;
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
        background-position: 0 0;

        &:hover {
            opacity: .75;
            filter: "alpha(opacity=75)";
            -ms-filter: "alpha(opacity=75)";
        }

        &:active {
            opacity: .9;
            filter: "alpha(opacity=90)";
            -ms-filter: "alpha(opacity=90)";
        }
    }

    .mCSB_buttonLeft {
        -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        // background-image: url(mCSB_buttons.png);
        background-repeat: no-repeat;
        opacity: .4;
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
        background-position: 0 -40px;

        &:hover {
            opacity: .75;
            filter: "alpha(opacity=75)";
            -ms-filter: "alpha(opacity=75)";
        }

        &:active {
            opacity: .9;
            filter: "alpha(opacity=90)";
            -ms-filter: "alpha(opacity=90)";
        }
    }

    .mCSB_buttonRight {
        -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        // background-image: url(mCSB_buttons.png);
        background-repeat: no-repeat;
        opacity: .4;
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
        background-position: 0 -56px;

        &:hover {
            opacity: .75;
            filter: "alpha(opacity=75)";
            -ms-filter: "alpha(opacity=75)";
        }

        &:active {
            opacity: .9;
            filter: "alpha(opacity=90)";
            -ms-filter: "alpha(opacity=90)";
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: $primary_color;
            filter: "alpha(opacity=90)";
            -ms-filter: "alpha(opacity=90)";
        }
    }
}

.mCSB_outside+.mCSB_scrollTools {
    right: -26px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded {
        .mCSB_dragger_bar {
            width: 12px;
        }
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    width: 12px;
                }
            }

            .mCSB_draggerRail {
                width: 8px;
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        width: 8px;
    }

    .mCSB_draggerRail {
        -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    }

    .mCSB_dragger_bar {
        -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    }
}

.mCSB_horizontal.mCSB_inside {
    >.mCSB_container {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

.mCSB_horizontal.mCSB_outside {
    >.mCSB_container {
        min-height: 100%;
    }
}

.mCSB_horizontal {
    >.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
        margin-bottom: 0;
    }
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    width: auto;
    height: 16px;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;

    a+.mCSB_draggerContainer {
        margin: 0 20px;
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 2px;
        margin: 7px 0;
    }

    .mCSB_dragger {
        width: 30px;
        height: 100%;
        left: 0;

        .mCSB_dragger_bar {
            width: 100%;
            height: 4px;
            margin: 6px auto;
        }
    }

    .mCSB_buttonLeft {
        display: block;
        position: absolute;
        width: 20px;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
        left: 0;
    }

    .mCSB_buttonRight {
        display: block;
        position: absolute;
        width: 20px;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
        right: 0;
    }
}

.mCustomScrollBox+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: -26px;
}

.mCustomScrollBox+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: -26px;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded {
        .mCSB_dragger_bar {
            height: 12px;
            margin: 2px auto;
        }
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    height: 12px;
                    margin: 2px auto;
                }
            }

            .mCSB_draggerRail {
                height: 8px;
                margin: 4px 0;
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        height: 8px;
        margin: 4px 0;
    }

    .mCSB_draggerRail {
        -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    }

    .mCSB_dragger_bar {
        -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    }
}

.mCSB_container_wrapper {
    position: absolute;
    height: auto;
    width: auto;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 30px;

    >.mCSB_container {
        padding-right: 30px;
        padding-bottom: 30px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.mCSB_vertical_horizontal {
    >.mCSB_scrollTools.mCSB_scrollTools_vertical {
        bottom: 20px;
    }

    >.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        right: 20px;
    }
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
    >.mCSB_container {
        padding-right: 0;
    }
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
    >.mCSB_container {
        padding-bottom: 0;
    }
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside {
    >.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
        margin-right: 0;
        margin-left: 0;
    }

    >.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
        margin-bottom: 0;
    }
}

.mCS-autoHide {
    >.mCustomScrollBox {
        >.mCSB_scrollTools {
            opacity: 0;
            filter: "alpha(opacity=0)";
            -ms-filter: "alpha(opacity=0)";
        }
    }

    >.mCustomScrollBox~.mCSB_scrollTools {
        opacity: 0;
        filter: "alpha(opacity=0)";
        -ms-filter: "alpha(opacity=0)";
    }

    &:hover {
        >.mCustomScrollBox {
            >.mCSB_scrollTools {
                opacity: 1;
                filter: "alpha(opacity=100)";
                -ms-filter: "alpha(opacity=100)";
            }
        }

        >.mCustomScrollBox~.mCSB_scrollTools {
            opacity: 1;
            filter: "alpha(opacity=100)";
            -ms-filter: "alpha(opacity=100)";
        }
    }
}

.mCS-dark.mCSB_scrollTools {
    .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, .15);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -80px 0;
    }

    .mCSB_buttonDown {
        background-position: -80px -20px;
    }

    .mCSB_buttonLeft {
        background-position: -80px -40px;
    }

    .mCSB_buttonRight {
        background-position: -80px -56px;
    }
}

.mCS-dark-2.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, .1);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 4px;
            background-color: #fff;
            background-color: rgba(255, 255, 255, .75);
            -webkit-border-radius: 1px;
            -moz-border-radius: 1px;
            border-radius: 1px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
            -webkit-border-radius: 1px;
            -moz-border-radius: 1px;
            border-radius: 1px;
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -112px 0;
    }

    .mCSB_buttonDown {
        background-position: -112px -20px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -40px;
    }

    .mCSB_buttonRight {
        background-position: -120px -56px;
    }
}

.mCS-light-2.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, .1);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 4px;
            background-color: #fff;
            background-color: rgba(255, 255, 255, .75);
            -webkit-border-radius: 1px;
            -moz-border-radius: 1px;
            border-radius: 1px;
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #fff;
                background-color: rgba(255, 255, 255, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #fff;
                background-color: rgba(255, 255, 255, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #fff;
            background-color: rgba(255, 255, 255, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -32px 0;
    }

    .mCSB_buttonDown {
        background-position: -32px -20px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -40px;
    }

    .mCSB_buttonRight {
        background-position: -40px -56px;
    }
}

.mCS-dark-2.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 4px;
            margin: 6px auto;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 4px;
        margin: 6px auto;
    }
}

.mCS-light-2.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 4px;
            margin: 6px auto;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 4px;
        margin: 6px auto;
    }
}

.mCS-dark-thick.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, .1);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            background-color: #fff;
            background-color: rgba(255, 255, 255, .75);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -96px 0;
    }

    .mCSB_buttonDown {
        background-position: -96px -20px;
    }

    .mCSB_buttonLeft {
        background-position: -100px -40px;
    }

    .mCSB_buttonRight {
        background-position: -100px -56px;
    }
}

.mCS-light-thick.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, .1);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            background-color: #fff;
            background-color: rgba(255, 255, 255, .75);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #fff;
                background-color: rgba(255, 255, 255, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #fff;
                background-color: rgba(255, 255, 255, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #fff;
            background-color: rgba(255, 255, 255, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -16px 0;
    }

    .mCSB_buttonDown {
        background-position: -16px -20px;
    }

    .mCSB_buttonLeft {
        background-position: -20px -40px;
    }

    .mCSB_buttonRight {
        background-position: -20px -56px;
    }
}

.mCS-dark-thick.mCSB_scrollTools_horizontal {
    .mCSB_draggerRail {
        width: 100%;
        height: 4px;
        margin: 6px 0;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 6px;
            margin: 5px auto;
        }
    }
}

.mCS-light-thick.mCSB_scrollTools_horizontal {
    .mCSB_draggerRail {
        width: 100%;
        height: 4px;
        margin: 6px 0;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 6px;
            margin: 5px auto;
        }
    }
}

.mCS-light-thin.mCSB_scrollTools {
    .mCSB_draggerRail {
        background-color: #fff;
        background-color: rgba(255, 255, 255, .1);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 2px;
        }
    }
}

.mCS-dark-thin.mCSB_scrollTools {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 2px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, .15);
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -80px 0;
    }

    .mCSB_buttonDown {
        background-position: -80px -20px;
    }

    .mCSB_buttonLeft {
        background-position: -80px -40px;
    }

    .mCSB_buttonRight {
        background-position: -80px -56px;
    }
}

.mCS-dark-thin.mCSB_scrollTools_horizontal {
    .mCSB_draggerRail {
        width: 100%;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 2px;
            margin: 7px auto;
        }
    }
}

.mCS-light-thin.mCSB_scrollTools_horizontal {
    .mCSB_draggerRail {
        width: 100%;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 2px;
            margin: 7px auto;
        }
    }
}

.mCS-rounded.mCSB_scrollTools {
    .mCSB_draggerRail {
        background-color: #fff;
        background-color: rgba(255, 255, 255, .15);
    }

    .mCSB_dragger {
        height: 14px;

        .mCSB_dragger_bar {
            width: 14px;
            margin: 0 1px;
        }
    }

    .mCSB_buttonUp {
        background-position: 0 -72px;
    }

    .mCSB_buttonDown {
        background-position: 0 -92px;
    }

    .mCSB_buttonLeft {
        background-position: 0 -112px;
    }

    .mCSB_buttonRight {
        background-position: 0 -128px;
    }
}

.mCS-rounded-dark.mCSB_scrollTools {
    .mCSB_dragger {
        height: 14px;

        .mCSB_dragger_bar {
            width: 14px;
            margin: 0 1px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, .15);
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -80px -72px;
    }

    .mCSB_buttonDown {
        background-position: -80px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -80px -112px;
    }

    .mCSB_buttonRight {
        background-position: -80px -128px;
    }
}

.mCS-rounded-dots-dark.mCSB_scrollTools {
    .mCSB_dragger {
        height: 14px;

        .mCSB_dragger_bar {
            width: 14px;
            margin: 0 1px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
        background-position: center;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
        background-repeat: repeat-y;
        opacity: .3;
        filter: "alpha(opacity=30)";
        -ms-filter: "alpha(opacity=30)";
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=);
    }

    .mCSB_buttonUp {
        background-position: -96px -72px;
    }

    .mCSB_buttonDown {
        background-position: -96px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -100px -112px;
    }

    .mCSB_buttonRight {
        background-position: -100px -128px;
    }
}

.mCS-rounded-dots.mCSB_scrollTools {
    .mCSB_dragger {
        height: 14px;

        .mCSB_dragger_bar {
            width: 14px;
            margin: 0 1px;
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
        background-position: center;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
        background-repeat: repeat-y;
        opacity: .3;
        filter: "alpha(opacity=30)";
        -ms-filter: "alpha(opacity=30)";
    }

    .mCSB_buttonUp {
        background-position: -16px -72px;
    }

    .mCSB_buttonDown {
        background-position: -16px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -20px -112px;
    }

    .mCSB_buttonRight {
        background-position: -20px -128px;
    }
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        width: 14px;

        .mCSB_dragger_bar {
            height: 14px;
            margin: 1px 0;
        }
    }
}

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        width: 14px;

        .mCSB_dragger_bar {
            height: 14px;
            margin: 1px 0;
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
        background-position: center;
        height: 4px;
        margin: 6px 0;
        background-repeat: repeat-x;
    }
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        width: 14px;

        .mCSB_dragger_bar {
            height: 14px;
            margin: 1px 0;
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
        background-position: center;
        height: 4px;
        margin: 6px 0;
        background-repeat: repeat-x;
    }
}

.mCS-rounded.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        width: 14px;

        .mCSB_dragger_bar {
            height: 14px;
            margin: 1px 0;
        }
    }
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded {
        .mCSB_dragger_bar {
            width: 16px;
            height: 16px;
            margin: -1px 0;
        }
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    width: 16px;
                    height: 16px;
                    margin: -1px 0;
                }
            }

            .mCSB_draggerRail {
                width: 4px;
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        width: 4px;
    }
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded {
        .mCSB_dragger_bar {
            width: 16px;
            height: 16px;
            margin: -1px 0;
        }
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    width: 16px;
                    height: 16px;
                    margin: -1px 0;
                }
            }

            .mCSB_draggerRail {
                width: 4px;
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        width: 4px;
    }
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded {
        .mCSB_dragger_bar {
            height: 16px;
            width: 16px;
            margin: 0 -1px;
        }
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    height: 16px;
                    width: 16px;
                    margin: 0 -1px;
                }
            }

            .mCSB_draggerRail {
                height: 4px;
                margin: 6px 0;
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        height: 4px;
        margin: 6px 0;
    }
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded {
        .mCSB_dragger_bar {
            height: 16px;
            width: 16px;
            margin: 0 -1px;
        }
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    height: 16px;
                    width: 16px;
                    margin: 0 -1px;
                }
            }

            .mCSB_draggerRail {
                height: 4px;
                margin: 6px 0;
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        height: 4px;
        margin: 6px 0;
    }
}

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical {
    .mCSB_draggerRail {
        width: 4px;
    }
}

.mCS-rounded-dots.mCSB_scrollTools_vertical {
    .mCSB_draggerRail {
        width: 4px;
    }
}

.mCS-3d-dark.mCSB_scrollTools {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-y;
            background-image: -moz-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to right, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            -webkit-border-radius: 16px;
            -moz-border-radius: 16px;
            border-radius: 16px;
            background-color: #555;
            width: 8px;
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #555;
            }
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #555;
            }
        }
    }

    opacity: 1;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";

    .mCSB_draggerRail {
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        width: 8px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        box-shadow: inset 1px 0 1px rgba(0, 0, 0, .5), inset -1px 0 1px rgba(255, 255, 255, .2);
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
        box-shadow: inset 1px 0 1px rgba(0, 0, 0, .1);
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #555;
        }
    }

    .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCSB_buttonRight {
        background-position: -120px -128px;
    }
}

.mCS-3d-thick-dark.mCSB_scrollTools {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-y;
            background-image: -moz-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to right, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            box-shadow: inset 1px 0 0 rgba(255, 255, 255, .4);
            width: 12px;
            margin: 2px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            box-shadow: inset 1px 0 0 rgba(255, 255, 255, .4), inset -1px 0 0 rgba(0, 0, 0, .2);
            background-color: #777;
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #777;
            }
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #777;
            }
        }
    }

    opacity: 1;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    box-shadow: inset 0 0 14px rgba(0, 0, 0, .2);

    .mCSB_draggerContainer {
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        background-color: #fff;
        background-color: rgba(0, 0, 0, .05);
        box-shadow: inset 1px 1px 16px rgba(0, 0, 0, .1);
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #777;
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCSB_buttonRight {
        background-position: -120px -128px;
    }
}

.mCS-3d-thick.mCSB_scrollTools {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-y;
            background-image: -moz-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to right, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            box-shadow: inset 1px 0 0 rgba(255, 255, 255, .4);
            width: 12px;
            margin: 2px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #555;
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #555;
            }
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #555;
            }
        }
    }

    opacity: 1;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;

    .mCSB_draggerContainer {
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .05);
        box-shadow: inset 1px 1px 16px rgba(0, 0, 0, .1);
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #555;
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCSB_buttonRight {
        background-position: -40px -128px;
    }
}

.mCS-3d.mCSB_scrollTools {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-y;
            background-image: -moz-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to right, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            -webkit-border-radius: 16px;
            -moz-border-radius: 16px;
            border-radius: 16px;
            background-color: #555;
            width: 8px;
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #555;
            }
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #555;
            }
        }
    }

    opacity: 1;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";

    .mCSB_draggerRail {
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        width: 8px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        box-shadow: inset 1px 0 1px rgba(0, 0, 0, .5), inset -1px 0 1px rgba(255, 255, 255, .2);
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #555;
        }
    }

    .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCSB_buttonRight {
        background-position: -40px -128px;
    }
}

.mCS-3d-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-x;
            background-image: -moz-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            width: 100%;
            height: 8px;
            margin: 4px auto;
        }

        width: 70px;
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 8px;
        margin: 4px 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5), inset 0 -1px 1px rgba(255, 255, 255, .2);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
    }
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-x;
            background-image: -moz-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4);
            height: 12px;
            width: auto;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4), inset 0 -1px 0 rgba(0, 0, 0, .2);
        }
    }

    bottom: 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1),
    inset 0 0 14px rgba(0, 0, 0, .5);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1),
    inset 0 0 14px rgba(0, 0, 0, .2);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-x;
            background-image: -moz-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4);
            height: 12px;
            width: auto;
        }
    }

    bottom: 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1),
    inset 0 0 14px rgba(0, 0, 0, .5);
}

.mCS-3d.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-repeat: repeat-x;
            background-image: -moz-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -o-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: -ms-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
            width: 100%;
            height: 8px;
            margin: 4px auto;
        }

        width: 70px;
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 8px;
        margin: 4px 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5), inset 0 -1px 1px rgba(255, 255, 255, .2);
    }
}

.mCS-3d-dark.mCSB_scrollTools_vertical {
    .mCSB_dragger {
        height: 70px;
    }
}

.mCS-3d.mCSB_scrollTools_vertical {
    .mCSB_dragger {
        height: 70px;
    }
}

.mCSB_inside+.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
    right: 1px;
}

.mCSB_inside+.mCS-3d-thick.mCSB_scrollTools_vertical {
    right: 1px;
}

.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, .1), inset 0 0 14px rgba(0, 0, 0, .5);
}

.mCS-3d-thick.mCSB_scrollTools_vertical {
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, .1), inset 0 0 14px rgba(0, 0, 0, .5);
}

.mCS-minimal-dark.mCSB_scrollTools {
    .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .2);
            filter: "alpha(opacity=20)";
            -ms-filter: "alpha(opacity=20)";
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .5);
                filter: "alpha(opacity=50)";
                -ms-filter: "alpha(opacity=50)";
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .5);
            filter: "alpha(opacity=50)";
            -ms-filter: "alpha(opacity=50)";
        }
    }
}

.mCS-minimal.mCSB_scrollTools {
    .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-color: #fff;
            background-color: rgba(255, 255, 255, .2);
            filter: "alpha(opacity=20)";
            -ms-filter: "alpha(opacity=20)";
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #fff;
                background-color: rgba(255, 255, 255, .5);
                filter: "alpha(opacity=50)";
                -ms-filter: "alpha(opacity=50)";
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #fff;
            background-color: rgba(255, 255, 255, .5);
            filter: "alpha(opacity=50)";
            -ms-filter: "alpha(opacity=50)";
        }
    }
}

.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical {
    right: 0;
    margin: 12px 0;
}

.mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical {
    right: 0;
    margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 0;
    margin: 0 12px;
}

.mCustomScrollBox.mCS-minimal+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 0;
    margin: 0 12px;
}

.mCustomScrollBox.mCS-minimal-dark+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 0;
    margin: 0 12px;
}

.mCustomScrollBox.mCS-minimal-dark+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 0;
    margin: 0 12px;
}

.mCS-minimal-dark.mCSB_scrollTools_vertical {
    .mCSB_dragger {
        height: 50px;
    }
}

.mCS-minimal.mCSB_scrollTools_vertical {
    .mCSB_dragger {
        height: 50px;
    }
}

.mCS-minimal-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        width: 50px;
    }
}

.mCS-minimal.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        width: 50px;
    }
}

.mCS-dark-3.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 6px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCSB_buttonRight {
        background-position: -120px -128px;
    }
}

.mCS-light-3.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 6px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
        }
    }

    .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCSB_buttonRight {
        background-position: -40px -128px;
    }
}

.mCS-dark-3.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 6px;
            margin: 5px 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 6px;
        margin: 5px 0;
    }
}

.mCS-light-3.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 100%;
            height: 6px;
            margin: 5px 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 6px;
        margin: 5px 0;
    }
}

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        width: 12px;
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_draggerRail {
                width: 12px;
            }
        }
    }
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        width: 12px;
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_draggerRail {
                width: 12px;
            }
        }
    }
}

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        height: 12px;
        margin: 2px 0;
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_draggerRail {
                height: 12px;
                margin: 2px 0;
            }
        }
    }
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
    .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail {
        height: 12px;
        margin: 2px 0;
    }

    .mCSB_draggerContainer {
        &:hover {
            .mCSB_draggerRail {
                height: 12px;
                margin: 2px 0;
            }
        }
    }
}

.mCS-inset-2-dark.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: #fff;
        border-color: rgba(255, 255, 255, .2);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-color: #000;
        border-color: rgba(0, 0, 0, .2);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            margin: 3px 5px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCSB_buttonRight {
        background-position: -120px -128px;
    }
}

.mCS-inset-2.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: #fff;
        border-color: rgba(255, 255, 255, .2);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            margin: 3px 5px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCSB_buttonRight {
        background-position: -40px -128px;
    }
}

.mCS-inset-3-dark.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
        background-color: #000;
        background-color: rgba(0, 0, 0, .6);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            margin: 3px 5px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
            background-color: #fff;
            background-color: rgba(255, 255, 255, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
                background-color: #fff;
                background-color: rgba(255, 255, 255, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
                background-color: #fff;
                background-color: rgba(255, 255, 255, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
            background-color: #fff;
            background-color: rgba(255, 255, 255, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCSB_buttonRight {
        background-position: -120px -128px;
    }
}

.mCS-inset-3.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        background-color: #fff;
        background-color: rgba(255, 255, 255, .6);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            margin: 3px 5px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCSB_buttonRight {
        background-position: -40px -128px;
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }
}

.mCS-inset-dark.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
        background-color: #000;
        background-color: rgba(0, 0, 0, .1);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            margin: 3px 5px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            background-color: rgba(0, 0, 0, .75);
        }

        &:hover {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .85);
            }
        }

        &:active {
            .mCSB_dragger_bar {
                background-color: #000;
                background-color: rgba(0, 0, 0, .9);
            }
        }
    }

    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #000;
            background-color: rgba(0, 0, 0, .9);
        }
    }

    .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCSB_buttonRight {
        background-position: -120px -128px;
    }
}

.mCS-inset.mCSB_scrollTools {
    .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .2);
    }

    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            margin: 3px 5px;
            position: absolute;
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCSB_buttonRight {
        background-position: -40px -128px;
    }
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            height: 6px;
            margin: 5px 3px;
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }
}

.mCS-inset-2.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            height: 6px;
            margin: 5px 3px;
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }
}

.mCS-inset-3-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            height: 6px;
            margin: 5px 3px;
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }
}

.mCS-inset-3.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            height: 6px;
            margin: 5px 3px;
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }
}

.mCS-inset-dark.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            height: 6px;
            margin: 5px 3px;
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }
}

.mCS-inset.mCSB_scrollTools_horizontal {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            height: 6px;
            margin: 5px 3px;
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }
}
