.staticBanner {
    min-height: 32rem;
    background-repeat: no-repeat;
    background-size: cover;

    &.aboutusPage {
        background-image: url(../img/aboutus/banner-bg.jpg);
        background-size: 10%;
        background-position: left 2.5rem bottom 1.5rem;

        @media (max-width: $desktopSmall - 1) {
            background-image: none;
        }
    }

    &.privacyPage {
        background-image: url(../img/privacy/privacy-bg.jpg);

        @media (max-width: $mobileBig) {
            min-height: 24rem;
        }

        @media (max-width: $mobile - 1) {
            min-height: 16rem;
        }
    }

    &.featuresPage {
        background-image: url(../img/features/banner-bg.png);
        background-size: 15%;
        background-position: right -2rem bottom 6rem;

        @media (max-width: $desktopSmall - 1) {
            background-image: none;
        }
    }
}