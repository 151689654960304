.mainFooter {
    position: relative;
    z-index: 1;
    padding: 40px 0;
    background-color: $primary_color;
    
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .divider {
            width: 1px;
            background-color: #0b1c54;
            height: 125px;

            @media (max-width: $desktopSmall - 1) {
                width: 75%;
                height: 1px;
                margin: 50px 0;
            }
        }

        .item {

            &.item--first {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            &.item--second {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: $mobile - 1) {
                    flex-direction: column;
                }
            }

            &.item--third {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 1.2;
                color: $white_color;
                margin: 0 0 20px;
                text-transform: uppercase;
            }

            &_icon {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: 1px solid $white_color;
                margin: 0 0 15px;

                img {
                    width: auto;
                }
            }

            &_email {
                display: inline-block;
                color: $white_color;
                font-family: $primary_font;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.2;
                letter-spacing: 0.67px;
                
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }

            &_logo {
                display: block;
                width: 154px;
                margin-right: 20px;

                @media (max-width: $desktop_xl) {
                    width: 134px;
                }

                @media (max-width: $desktopSmall - 1) {
                    width: 104px;
                }

                @media (max-width: $mobile - 1) {
                    margin-right: 0;
                    margin-bottom: 20px;
                }

                img {
                    width: 100%;
                }
            }

            &_content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $white_color;
                    margin: 0 0 20px;
                    text-transform: uppercase;

                    span {
                        display: inline-block;
                        font-weight: 800;
                    }
                }

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        display: block;

                        a {
                            display: block;
                            margin-right: 10px;

                            img {
                                width: 100px;
                            }
                        }
                    }
                }
            }

            &_socialLinks {
                display: flex;
                margin-top: 5px;

                li {
                    display: inline-block;

                    a {
                        display: flex;
                        margin: 0 10px;

                        color: white;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 0;
                        font-size: 16px;

                        // &:after {
                        //     content: "";
                        //     position: absolute;
                        //     top: 0;
                        //     left: 0;
                        //     width: 100%;
                        //     height: 100%;
                        //     border: 1px solid rgba(#ffffff, 0.57);
                        //     z-index: -1;
                        //     transform: rotate(45deg);
                        // }

                        // img {
                        //     width: auto;
                        // }

                        &:hover {
                            transform: rotateY(360deg);

                            i {
                                color: $secondary_color;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: $desktop - 1) {}

        @media (max-width: $desktopSmall - 1) {
            flex-direction: column;
        }
    }
}

.backtoTop {
	display:none;
	position:fixed;
	right:30px;
	bottom:60px;
	width:40px;
	height:40px;
    z-index:999;
    z-index: 999;
	cursor:pointer;
	background: $primary_color;
	@include css3Transition(400ms);
	
	&:before {
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:40px;
		height:40px;
		z-index:-1;
		// background:rgba(0,0,0,0.2);
		@include css3Transition(400ms);
    }
    
	&:after {
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:40px;
		height:40px;
		z-index:-2;
		// background:rgba(0,0,0,0.2);
		@include css3Transition(400ms);
	}
	
	&:hover {
	    background: $secondary_color;

		&:before {
			background:rgba( $color: $primary_color, $alpha: 0.2);
		}
		&:after {
			opacity:0;
			background:rgba( $color: $primary_color, $alpha: 0.2);
			@include css3Transform(scale(1.5));
		}
    }
    
    img {
        float: left;
    }

	@media(max-width:$tablet2) {
		right: 10px;
		bottom: 100px;
    }
}