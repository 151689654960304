.margin-top-0 { margin-top:0 !important;}
.margin-top-5 {margin-top:5px !important}
.margin-top-10 {margin-top:10px !important}
.margin-top-15 {margin-top:15px !important}
.margin-top-20 {margin-top:20px !important}
.margin-top-25 {margin-top:25px !important}
.margin-top-30 {margin-top:30px !important}
.margin-top-35 {margin-top:35px !important}
.margin-top-40 {margin-top:40px !important}
.margin-top-45 {margin-top:45px !important}
.margin-top-50 {margin-top:50px !important}
.margin-top-55 {margin-top:55px !important}
.margin-top-60 {margin-top:60px !important}
.margin-top-65 {margin-top:65px !important}
.margin-top-70 {margin-top:70px !important}
.margin-top-75 {margin-top:75px !important}
.margin-top-80 {margin-top:80px !important}

.margin-left-0 {margin-left:0 !important}
.margin-left-5 {margin-left:5px !important}
.margin-left-10 {margin-left:10px !important}
.margin-left-15 {margin-left:15px !important}
.margin-left-20 {margin-left:20px !important}
.margin-left-25 {margin-left:25px !important}
.margin-left-30 {margin-left:30px !important}
.margin-left-35 {margin-left:35px !important}
.margin-left-40 {margin-left:40px !important}
.margin-left-45 {margin-left:45px !important}
.margin-left-50 {margin-left:50px !important}
.margin-left-55 {margin-left:55px !important}
.margin-left-60 {margin-left:60px !important}
.margin-left-65 {margin-left:65px !important}
.margin-left-70 {margin-left:70px !important}
.margin-left-75 {margin-left:75px !important}
.margin-left-80 {margin-left:80px !important}

.margin-bottom-0 {margin-bottom:0 !important}
.margin-bottom-5 {margin-bottom:5px !important}
.margin-bottom-10 {margin-bottom:10px !important}
.margin-bottom-15 {margin-bottom:15px !important}
.margin-bottom-20 {margin-bottom:20px !important}
.margin-bottom-25 {margin-bottom:25px !important}
.margin-bottom-30 {margin-bottom:30px !important}
.margin-bottom-35 {margin-bottom:35px !important}
.margin-bottom-40 {margin-bottom:40px !important}
.margin-bottom-45 {margin-bottom:45px !important}
.margin-bottom-50 {margin-bottom:50px !important}
.margin-bottom-55 {margin-bottom:55px !important}
.margin-bottom-60 {margin-bottom:60px !important}
.margin-bottom-65 {margin-bottom:65px !important}
.margin-bottom-70 {margin-bottom:70px !important}
.margin-bottom-75 {margin-bottom:75px !important}
.margin-bottom-80 {margin-bottom:80px !important}

.margin-right-0 {margin-right: 0 !important}
.margin-right-5 {margin-right:5px !important}
.margin-right-10 {margin-right:10px !important}
.margin-right-15 {margin-right:15px !important}
.margin-right-20 {margin-right:20px !important}
.margin-right-25 {margin-right:25px !important}
.margin-right-30 {margin-right:30px !important}
.margin-right-35 {margin-right:35px !important}
.margin-right-40 {margin-right:40px !important}
.margin-right-45 {margin-right:45px !important}
.margin-right-50 {margin-right:50px !important}
.margin-right-55 {margin-right:55px !important}
.margin-right-60 {margin-right:60px !important}
.margin-right-65 {margin-right:65px !important}
.margin-right-70 {margin-right:70px !important}
.margin-right-75 {margin-right:75px !important}
.margin-right-80 {margin-right:80px !important}



.padding-top-0 {padding-top:0 !important}
.padding-top-5 {padding-top:5px !important}
.padding-top-10 {padding-top:10px !important}
.padding-top-15 {padding-top:15px !important}
.padding-top-20 {padding-top:20px !important}
.padding-top-25 {padding-top:25px !important}
.padding-top-30 {padding-top:30px !important}
.padding-top-35 {padding-top:35px !important}
.padding-top-40 {padding-top:40px !important}
.padding-top-45 {padding-top:45px !important}
.padding-top-50 {padding-top:50px !important}
.padding-top-55 {padding-top:55px !important}
.padding-top-60 {padding-top:60px !important}
.padding-top-65 {padding-top:65px !important}
.padding-top-70 {padding-top:70px !important}
.padding-top-75 {padding-top:75px !important}
.padding-top-80 {padding-top:80px !important}

.padding-left-0 {padding-left:0 !important}
.padding-left-5 {padding-left:5px !important}
.padding-left-10 {padding-left:10px !important}
.padding-left-15 {padding-left:15px !important}
.padding-left-20 {padding-left:20px !important}
.padding-left-25 {padding-left:25px !important}
.padding-left-30 {padding-left:30px !important}
.padding-left-35 {padding-left:35px !important}
.padding-left-40 {padding-left:40px !important}
.padding-left-45 {padding-left:45px !important}
.padding-left-50 {padding-left:50px !important}
.padding-left-55 {padding-left:55px !important}
.padding-left-60 {padding-left:60px !important}
.padding-left-65 {padding-left:65px !important}
.padding-left-70 {padding-left:70px !important}
.padding-left-75 {padding-left:75px !important}
.padding-left-80 {padding-left:80px !important}

.padding-bottom-0 {padding-bottom:0 !important}
.padding-bottom-5 {padding-bottom:5px !important}
.padding-bottom-10 {padding-bottom:10px !important}
.padding-bottom-15 {padding-bottom:15px !important}
.padding-bottom-20 {padding-bottom:20px !important}
.padding-bottom-25 {padding-bottom:25px !important}
.padding-bottom-30 {padding-bottom:30px !important}
.padding-bottom-35 {padding-bottom:35px !important}
.padding-bottom-40 {padding-bottom:40px !important}
.padding-bottom-45 {padding-bottom:45px !important}
.padding-bottom-50 {padding-bottom:50px !important}
.padding-bottom-55 {padding-bottom:55px !important}
.padding-bottom-60 {padding-bottom:60px !important}
.padding-bottom-65 {padding-bottom:65px !important}
.padding-bottom-70 {padding-bottom:70px !important}
.padding-bottom-75 {padding-bottom:75px !important}
.padding-bottom-80 {padding-bottom:80px !important}

.padding-right-0 {padding-right:0 !important}
.padding-right-5 {padding-right:5px !important}
.padding-right-10 {padding-right:10px !important}
.padding-right-15 {padding-right:15px !important}
.padding-right-20 {padding-right:20px !important}
.padding-right-25 {padding-right:25px !important}
.padding-right-30 {padding-right:30px !important}
.padding-right-35 {padding-right:35px !important}
.padding-right-40 {padding-right:40px !important}
.padding-right-45 {padding-right:45px !important}
.padding-right-50 {padding-right:50px !important}
.padding-right-55 {padding-right:55px !important}
.padding-right-60 {padding-right:60px !important}
.padding-right-65 {padding-right:65px !important}
.padding-right-70 {padding-right:70px !important}
.padding-right-75 {padding-right:75px !important}
.padding-right-80 {padding-right:80px !important}

// Widths
.width10 { width:10%; }
.width15 { width:15%; }
.width20 { width:20%; }
.width25 { width:25%; }
.width30 { width:30%; }
.width35 { width:35%; }
.width40 { width:40%; }
.width45 { width:45%; }
.width50 { width:50%; }
.width55 { width:55%; }
.width60 { width:60%; }
.width65 { width:65%; }
.width70 { width:70%; }
.width75 { width:75%; }
.width80 { width:80%; }
.width85 { width:85%; }
.width90 { width:90%; }
.width95 { width:95%; }
.width100 { width:100%; }

.pTN_on_laptop {
	@media(max-width:$laptop) {
		padding-top:0 !important;
	}
}
.pTN_on_desktop {
	@media(max-width:$desktop) {
		padding-top:0 !important;
	}
}
.pTN_on_desktopSmall {
	@media(max-width:$desktopSmall) {
		padding-top:0 !important;
	}
}
.pTN_on_tablet {
	@media(max-width:$tablet) {
		padding-top:0 !important;
	}
}
.pTN_on_tabletSmall {
	@media(max-width:$tabletSmall) {
		padding-top:0 !important;
	}
}
.pTN_on_mobileBig {
	@media(max-width:$mobileBig) {
		padding-top:0 !important;
	}
}
.pTN_on_mobile {
	@media(max-width:$mobile) {
		padding-top:0 !important;
	}
}
.pTN_on_mobileSmall {
	@media(max-width:$mobileSmall) {
		padding-top:0 !important;
	}
}

.pBN_on_desktop {
	@media(max-width:$desktop) {
		padding-bottom:0 !important;
	}
}
.pBN_on_desktopSmall {
	@media(max-width:$desktopSmall) {
		padding-bottom:0 !important;
	}
}
.pBN_on_tablet {
	@media(max-width:$tablet) {
		padding-bottom:0 !important;
	}
}
.pBN_on_tabletSmall {
	@media(max-width:$tabletSmall) {
		padding-bottom:0 !important;
	}
}
.pBN_on_mobileBig {
	@media(max-width:$mobileBig) {
		padding-bottom:0 !important;
	}
}
.pBN_on_mobile {
	@media(max-width:$mobile) {
		padding-bottom:0 !important;
	}
}
.pBN_on_mobileSmall {
	@media(max-width:$mobileSmall) {
		padding-bottom:0 !important;
	}
}

.pRN_on_desktop {
	@media(max-width:$desktop) {
		padding-right:0 !important;
	}
}
.pRN_on_desktopSmall {
	@media(max-width:$desktopSmall) {
		padding-right:0 !important;
	}
}
.pRN_on_tablet {
	@media(max-width:$tablet) {
		padding-right:0 !important;
	}
}
.pRN_on_tabletSmall {
	@media(max-width:$tabletSmall) {
		padding-right:0 !important;
	}
}
.pRN_on_mobileBig {
	@media(max-width:$mobileBig) {
		padding-right:0 !important;
	}
}
.pRN_on_mobile {
	@media(max-width:$mobile) {
		padding-top:0 !important;
	}
}
.pRN_on_mobileSmall {
	@media(max-width:$mobileSmall) {
		padding-right:0 !important;
	}
}

.pLN_on_desktop {
	@media(max-width:$desktop) {
		padding-left:0 !important;
	}
}
.pLN_on_desktopSmall {
	@media(max-width:$desktopSmall) {
		padding-left:0 !important;
	}
}
.pLN_on_tablet {
	@media(max-width:$tablet) {
		padding-left:0 !important;
	}
}
.pLN_on_tabletSmall {
	@media(max-width:$tabletSmall) {
		padding-left:0 !important;
	}
}
.pLN_on_mobileBig {
	@media(max-width:$mobileBig) {
		padding-left:0 !important;
	}
}
.pLN_on_mobile {
	@media(max-width:$mobile) {
		padding-left:0 !important;
	}
}
.pLN_on_mobileSmall {
	@media(max-width:$mobileSmall) {
		padding-left:0 !important;
	}
}

// Show On
.show_on_laptop {
	display:none !important;
	@media(max-width:$laptop) {
		display:inline-block !important;
	}
}
.show_on_desktop {
	display:none !important;
	@media(max-width:$desktop) {
		display:inline-block !important;
	}
}
.show_on_desktopSmall {
	display:none !important;
	@media(max-width:$desktopSmall) {
		display:inline-block !important;
	}
}
.show_on_tablet {
	display:none !important;
	@media(max-width:$tablet) {
		display:block !important;
	}
}
.show_on_tabletSmall {
	display:none !important;
	@media(max-width:$tabletSmall) {
		display:block !important;
	}
}
.show_on_mobileBig {
	display:none !important;
	@media(max-width:$mobileBig) {
		display:block !important;
	}
}
.show_on_mobile {
	display:none !important;
	@media(max-width:$mobile) {
		display:block !important;
	}
}

.hide {
	display: none !important;
}

// Hide On
.hide_on_laptop {
	@media(max-width:$laptop) {
		display:none !important;
	}
}
.hide_on_desktop {
	@media(max-width:$desktop) {
		display:none !important;
	}
}
.hide_on_desktopSmall {
	@media(max-width:$desktopSmall) {
		display:none !important;
	}
}
.hide_on_tablet {
	@media(max-width:$tablet) {
		display:none !important;
	}
}
.hide_on_tabletSmall {
	@media(max-width:$tabletSmall) {
		display:none !important;
	}
}
.hide_on_mobileBig {
	@media(max-width:$mobileBig) {
		display:none !important;
	}
}
.hide_on_mobile {
	@media(max-width:$mobile) {
		display:none !important;
	}
}
.hide_on_mobileSmall {
	@media(max-width:$mobileSmall) {
		display:none;
	}
}

//	Common Classes
.hidden_h1 {
	opacity:0;
	position:absolute;
	width:0;
	height:0;
	overflow:hidden;
}

.nopadding_LR {
   padding-left:0 !important;
   padding-right:0 !important;
}
.nopadding_L {
   padding-left:0 !important;
}
.nopadding_R {
   padding-right:0 !important;
}
.no_margin {
	margin:0;
}
.uppercase {
	text-transform:uppercase;
}
.nouppercase {
	text-transform:none !important;
}

.cursor {
	cursor: pointer;
}


@media (max-width: $mobileBig) {
	.text-xs-left {
		text-align: left !important;
	}

	.margin-top-xs-1 {
		margin-top: 1rem;
	}

	.margin-top-xs-2 {
		margin-top: 2rem;
	}

	.margin-top-xs-3 {
		margin-top: 3rem;
	}

	.margin-top-xs-4 {
		margin-top: 4rem;
	}

	.margin-top-xs-5 {
		margin-top: 5rem;
	}
	
	.margin-bottom-xs-1 {
		margin-bottom: 1rem;
	}
	
	.margin-bottom-xs-2 {
		margin-bottom: 2rem;
	}
	
	.margin-bottom-xs-3 {
		margin-bottom: 3rem;
	}
	
	.margin-bottom-xs-4 {
		margin-bottom: 4rem;
	}
	
	.margin-bottom-xs-5 {
		margin-bottom: 5rem;
	}
}