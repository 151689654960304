@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.flex-direction-column {
  flex-direction: column !important; }

.display-flex {
  display: flex !important; }

.mobileview-none {
  display: block !important; }

.mobileview-block {
  display: none !important; }

.laptopview-block {
  display: block !important; }

.laptopview-none {
  display: none !important; }

@media (max-width: 1279px) {
  .laptopview-block {
    display: none !important; }
  .laptopview-none {
    display: block !important; } }

@media (max-width: 1023px) {
  .margin-bottom-xs-30 {
    margin-bottom: 30px !important; }
  .mobileview-none {
    display: none !important; }
  .mobileview-block {
    display: block !important; } }

@media (max-width: 767px) {
  .text-xs-center {
    text-align: center !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .margin-bottom-xs-30 {
    margin-bottom: 3rem !important; } }

/*!
 * Bootoast CSS
 * @author odahcam
 * @version 1.0.0
 **/
.bootoast {
  opacity: 0;
  filter: alpha(opacity=0); }

.bootoast > .fa {
  display: inline-block;
  float: left;
  font-size: 20px;
  margin-right: 14px; }

.bootoast > .bootoast-alert-container {
  display: block;
  max-width: 100%;
  overflow: auto;
  text-align: left; }

.bootoast > .bootoast-alert-container > .bootoast-alert-content {
  display: table;
  width: 100%;
  height: 100%; }

.bootoast-container {
  position: fixed;
  left: 20px;
  right: 20px;
  top: initial;
  bottom: initial;
  width: 390px;
  max-width: 95%;
  z-index: 2060; }

.bootoast-container.top {
  top: 20px; }

.bootoast-container.bottom {
  bottom: 20px; }

.bootoast-container.right {
  left: initial; }

.bootoast-container.left {
  right: initial; }

.bootoast-container.center {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }

.datepicker {
  padding: 4px;
  direction: ltr; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }

.datepicker-rtl.dropdown-menu {
  left: auto; }

.datepicker-rtl table tr td span {
  float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0; }

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute; }

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none; }

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer; }

.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999; }

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default; }

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0; }

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000; }

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background-color: #fdf59a; }

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover:active {
  background-color: #fbf069\9; }

.datepicker table tr td.today:hover:hover {
  color: #000; }

.datepicker table tr td.today.active:hover {
  color: #fff; }

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover:hover, .datepicker table tr td.range.today.disabled:hover[disabled], .datepicker table tr td.range.today.disabled[disabled], .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today:hover[disabled], .datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a; }

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9; }

.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(grey));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: linear-gradient(to bottom, #b3b3b3, grey);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: grey grey #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover:hover, .datepicker table tr td.selected.disabled:hover[disabled], .datepicker table tr td.selected.disabled[disabled], .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected:hover[disabled], .datepicker table tr td.selected[disabled] {
  background-color: grey; }

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover:active {
  background-color: #666\9; }

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: #04c; }

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background-color: #039\9; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.datepicker table tr td span.focused, .datepicker table tr td span:hover {
  background: #eee; }

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default; }

.datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
  background-color: #04c; }

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background-color: #039\9; }

.datepicker table tr td span.new, .datepicker table tr td span.old {
  color: #999; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: #eee; }

.datepicker .next.disabled, .datepicker .prev.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-append.date .add-on, .input-prepend.date .add-on {
  cursor: pointer; }

.input-append.date .add-on i, .input-prepend.date .add-on i {
  margin-top: 3px; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0; }

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 20px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px; }

.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  color: #333;
  font-size: 13px;
  line-height: 20px; }

.datepicker.datepicker-inline td, .datepicker.datepicker-inline th, .datepicker.dropdown-menu td, .datepicker.dropdown-menu th {
  padding: 4px 5px; }

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; }
  .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; }
  .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; }

.mCustomScrollbar.mCS_no_scrollbar {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr; }
  .mCustomScrollBox:hover > .mCSB_scrollTools {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; }
  .mCustomScrollBox:hover ~ .mCSB_scrollTools {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; }

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto; }

.mCSB_inside > .mCSB_container {
  margin-right: 0; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools {
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0;
  left: -26px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
  left: 20px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }
  .mCSB_scrollTools .mCSB_draggerContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto; }
  .mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 20px 0; }
  .mCSB_scrollTools .mCSB_draggerRail {
    width: 2px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)"; }
  .mCSB_scrollTools .mCSB_dragger {
    cursor: pointer;
    width: 100%;
    height: 30px;
    z-index: 1; }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      position: relative;
      width: 4px;
      height: 100%;
      margin: 0 auto;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      text-align: center;
      -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
      -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
      -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
      transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
      background-color: #020c2d;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)"; }
    .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #020c2d;
      filter: "alpha(opacity=85)";
      -ms-filter: "alpha(opacity=85)"; }
    .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #020c2d;
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)"; }
  .mCSB_scrollTools .mCSB_buttonDown {
    display: block;
    position: absolute;
    height: 20px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    bottom: 0;
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    background-repeat: no-repeat;
    opacity: .4;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)";
    background-position: 0 -20px; }
    .mCSB_scrollTools .mCSB_buttonDown:hover {
      opacity: .75;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)"; }
    .mCSB_scrollTools .mCSB_buttonDown:active {
      opacity: .9;
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)"; }
  .mCSB_scrollTools .mCSB_buttonUp {
    display: block;
    position: absolute;
    height: 20px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    background-repeat: no-repeat;
    opacity: .4;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)";
    background-position: 0 0; }
    .mCSB_scrollTools .mCSB_buttonUp:hover {
      opacity: .75;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)"; }
    .mCSB_scrollTools .mCSB_buttonUp:active {
      opacity: .9;
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)"; }
  .mCSB_scrollTools .mCSB_buttonLeft {
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    background-repeat: no-repeat;
    opacity: .4;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)";
    background-position: 0 -40px; }
    .mCSB_scrollTools .mCSB_buttonLeft:hover {
      opacity: .75;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)"; }
    .mCSB_scrollTools .mCSB_buttonLeft:active {
      opacity: .9;
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)"; }
  .mCSB_scrollTools .mCSB_buttonRight {
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    background-repeat: no-repeat;
    opacity: .4;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)";
    background-position: 0 -56px; }
    .mCSB_scrollTools .mCSB_buttonRight:hover {
      opacity: .75;
      filter: "alpha(opacity=75)";
      -ms-filter: "alpha(opacity=75)"; }
    .mCSB_scrollTools .mCSB_buttonRight:active {
      opacity: .9;
      filter: "alpha(opacity=90)";
      -ms-filter: "alpha(opacity=90)"; }
  .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #020c2d;
    filter: "alpha(opacity=90)";
    -ms-filter: "alpha(opacity=90)"; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar {
  width: 12px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  width: 8px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
    margin: 0 20px; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 2px;
    margin: 7px 0; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 30px;
    height: 100%;
    left: 0; }
    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      width: 100%;
      height: 4px;
      margin: 6px auto; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    left: 0; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    right: 0; }

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }
  .mCSB_container_wrapper > .mCSB_container {
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }
  .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 14px;
    margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }
  .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 14px;
    margin: 0 1px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75); }
  .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85); }
  .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }
  .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 14px;
    margin: 0 1px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75); }
  .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85); }
  .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }
  .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 14px;
    margin: 0 1px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }
  .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 14px;
    margin: 1px 0; }

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }
  .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 14px;
    margin: 1px 0; }

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }
  .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 14px;
    margin: 1px 0; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }
  .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 14px;
    margin: 1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-y;
    background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    background-color: #555;
    width: 8px; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    width: 8px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px; }
  .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-y;
    background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    width: 12px;
    margin: 2px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
    background-color: #777; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #777; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #777; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background-color: #fff;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #777; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px; }
  .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px; }

.mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-y;
    background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    width: 12px;
    margin: 2px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #555; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px; }
  .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px; }

.mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }
  .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-y;
    background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    background-color: #555;
    width: 8px; }
  .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    width: 8px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }
  .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #555; }
  .mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px; }
  .mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px; }
  .mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px; }
  .mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }
  .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 8px;
    margin: 4px auto; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }
  .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
    height: 12px;
    width: auto;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }
  .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
    height: 12px;
    width: auto; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }
  .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 8px;
    margin: 4px auto; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.modal.fade {
  padding: 60px 0;
  background: rgba(0, 0, 0, 0.6); }
  .modal.fade .modal-dialog {
    width: 90%;
    margin: 0 auto;
    max-width: 775px; }
    .modal.fade .modal-dialog.md {
      max-width: 600px; }
    .modal.fade .modal-dialog .modelClose {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
      line-height: 1px; }
      .modal.fade .modal-dialog .modelClose img {
        width: 16px;
        opacity: 0.6; }
      .modal.fade .modal-dialog .modelClose:hover img {
        opacity: 1; }
    .modal.fade .modal-dialog .modal-content {
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
    @media (max-width: 767px) {
      .modal.fade .modal-dialog {
        max-width: 450px; } }

.modalContainer {
  position: relative;
  display: flex;
  align-items: stretch; }
  .modalContainer .brand-logo {
    background: #020c2d;
    width: 275px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .modalContainer .brand-logo .circle {
      width: 272px;
      height: 272px;
      display: flex;
      border-radius: 50%;
      background: white;
      margin-right: -55px;
      align-items: center;
      justify-content: center; }
      .modalContainer .brand-logo .circle img {
        width: 161px; }
    .modalContainer .brand-logo .element--lists, .modalContainer .brand-logo h4 {
      display: none; }
    @media (max-width: 767px) {
      .modalContainer .brand-logo {
        display: none; } }
  .modalContainer .contentBox {
    position: relative;
    width: 500px;
    padding: 40px; }
    .modalContainer .contentBox.full-width {
      width: 100%;
      padding: 30px; }
    .modalContainer .contentBox .btnVerify {
      text-align: center;
      margin-bottom: 15px; }
      .modalContainer .contentBox .btnVerify a {
        display: inline-block;
        font-family: "Pacifico", cursive;
        font-size: 16px;
        color: #212121;
        padding: 20px;
        line-height: .3; }
        .modalContainer .contentBox .btnVerify a:hover {
          background: #f1f1f1; }
    @media (max-width: 767px) {
      .modalContainer .contentBox {
        width: 100%;
        padding: 30px; } }
  .modalContainer .checkIcon {
    padding: 30px 0;
    text-align: center; }
    .modalContainer .checkIcon i {
      font-size: 75px;
      color: #6ac259; }
  .modalContainer .form-group {
    position: relative;
    margin-bottom: 25px; }
    .modalContainer .form-group .form-control {
      font-family: "Pacifico", cursive;
      font-size: 15px;
      color: #000;
      padding: 11px 12px;
      border-color: #ccc;
      -webkit-box-shadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)";
      -moz-box-shadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)";
      -o-box-shadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)";
      box-shadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)"; }
      .modalContainer .form-group .form-control::-webkit-input-placeholder {
        color: #333; }
      .modalContainer .form-group .form-control::-moz-placeholder {
        color: #333; }
      .modalContainer .form-group .form-control:-ms-input-placeholder {
        color: #333; }
      .modalContainer .form-group .form-control:-moz-placeholder {
        color: #333; }
      .modalContainer .form-group .form-control:focus {
        border-color: #676767; }
    .modalContainer .form-group .showPassword, .modalContainer .form-group .field-icon {
      position: absolute;
      top: 0;
      right: 15px;
      font-size: 13px;
      color: #777;
      line-height: 45px;
      text-transform: uppercase; }
      .modalContainer .form-group .showPassword:hover, .modalContainer .form-group .field-icon:hover {
        color: #000; }
    .modalContainer .form-group .forgotPass {
      color: #222; }
      .modalContainer .form-group .forgotPass:hover {
        color: #020c2d; }

.popupStyle2 {
  width: 100%;
  padding: 40px; }

.typeOfAddr {
  display: flex; }
  .typeOfAddr__list {
    margin-right: 15px; }

.orderdetailsPopup .productInfo {
  display: flex;
  padding: 15px 0;
  align-items: center;
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }
  .orderdetailsPopup .productInfo figure {
    width: 25%;
    margin: 0; }
  .orderdetailsPopup .productInfo .txtBox {
    width: 45%;
    padding-left: 10px; }
  .orderdetailsPopup .productInfo .totals {
    width: 40%; }

.trackorderPopup {
  padding: 0; }
  .trackorderPopup__shippingDetails {
    display: flex;
    padding: 10px 15px;
    border: 1px solid #ddd;
    justify-content: space-between; }
    .trackorderPopup__shippingDetails p {
      margin-bottom: 0; }
    .trackorderPopup__shippingDetails span {
      font-size: 15px;
      color: #ec181e; }
  .trackorderPopup__trackProgress {
    padding: 40px 0; }
    .trackorderPopup__trackProgress ul {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between; }
      .trackorderPopup__trackProgress ul:before {
        content: '';
        position: absolute;
        top: 80px;
        left: 10%;
        width: 80%;
        height: 4px;
        background: #f1f1f1; }
      .trackorderPopup__trackProgress ul:after {
        content: '';
        position: absolute;
        top: 80px;
        left: 10%;
        width: 0;
        height: 4px;
        background: #ec181e; }
      .trackorderPopup__trackProgress ul.step1:after {
        width: 0; }
      .trackorderPopup__trackProgress ul.step2:after {
        width: 26.666%; }
      .trackorderPopup__trackProgress ul.step3:after {
        width: 53.333%; }
      .trackorderPopup__trackProgress ul.step4:after {
        width: 80%; }
      .trackorderPopup__trackProgress ul li {
        position: relative;
        z-index: 1;
        display: block;
        width: 20%; }
        .trackorderPopup__trackProgress ul li:before {
          content: '';
          position: absolute;
          top: 72px;
          left: 50%;
          width: 20px;
          height: 20px;
          margin-left: -10px;
          background: #cdcdcd;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
        .trackorderPopup__trackProgress ul li:after {
          display: none;
          content: '';
          position: absolute;
          top: 72px;
          left: 50%;
          width: 20px;
          height: 20px;
          margin-left: -10px;
          background: url("../img/svg/tick-white.svg") center no-repeat #ec181e;
          background-size: 9px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
        .trackorderPopup__trackProgress ul li p {
          margin: 0;
          font-size: 15px;
          line-height: 18px;
          text-align: center; }
        .trackorderPopup__trackProgress ul li.active:after {
          display: block; }
        .trackorderPopup__trackProgress ul li.active .icon {
          background-position-x: -50px; }

.writeReviewPopoup {
  padding: 30px; }
  .writeReviewPopoup .bg-primary {
    margin: -30px -30px 10px -30px;
    padding: 20px 30px; }
  .writeReviewPopoup .orderId {
    font-size: 13px;
    color: #666666;
    text-align: right; }
  .writeReviewPopoup .productInfo {
    display: flex;
    padding: 0 0 15px;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd; }
    .writeReviewPopoup .productInfo figure {
      width: 25%;
      margin: 0; }
    .writeReviewPopoup .productInfo .txtBox {
      width: 75%;
      padding-left: 10px; }
  .writeReviewPopoup .yourRating {
    display: flex;
    align-items: center; }
    .writeReviewPopoup .yourRating .label {
      margin: 0;
      font-size: 14px;
      margin-right: 10px; }
  .writeReviewPopoup .recommendProduct {
    display: flex;
    flex-direction: column; }
    .writeReviewPopoup .recommendProduct span {
      margin-bottom: 10px; }
  .writeReviewPopoup .custom-form {
    margin: 0 auto;
    width: 90%; }
    .writeReviewPopoup .custom-form textarea.form-control {
      height: 60px; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  font-family: "Pacifico", cursive;
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: none;
  border-radius: 0; }
  @media (max-width: 992px) {
    .select2-container--default .select2-selection--single {
      border-radius: 25px; } }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: "Pacifico", cursive;
  font-size: 15px;
  color: #222;
  outline: none;
  padding: 0 18px;
  text-align: left;
  line-height: 47px; }
  @media (max-width: 992px) {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      font-size: 13px;
      padding: 0 15px; } }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 50%;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.firstBox .select2-container--default .select2-selection--single {
  border-radius: 25px 0 0 25px; }
  @media (max-width: 992px) {
    .firstBox .select2-container--default .select2-selection--single {
      border-radius: 25px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'FontAwesome';
    font-size: 24px;
    line-height: 1;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "\f104"; }
    [dir="rtl"] .slick-prev:before {
      content: "\f104"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "\f105"; }
    [dir="rtl"] .slick-next:before {
      content: "\f105"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -80px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 100; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    padding: 0;
    margin: 0 10px;
    cursor: pointer;
    background: #fff;
    text-indent: -9999px;
    border-radius: 8px;
    opacity: 0.3; }
    .slick-dots li.slick-active {
      opacity: 1; }

@-webkit-keyframes move {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes move {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes move {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes move {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes move {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.typed-cursor {
  display: none;
  font-size: 70px;
  opacity: 1;
  font-weight: 100;
  -webkit-animation: move 0.5s ease infinite alternate;
  -khtml-animation: move 0.5s ease infinite alternate;
  -moz-animation: move 0.5s ease infinite alternate;
  -ms-animation: move 0.5s ease infinite alternate;
  -o-animation: move 0.5s ease infinite alternate;
  animation: move 0.5s ease infinite alternate; }
  @media (max-width: 992px) {
    .typed-cursor {
      font-size: 70px; } }
  @media (max-width: 767px) {
    .typed-cursor {
      font-size: 40px; } }

.typed-fade-out {
  opacity: 0;
  animation: 0;
  transition: opacity .25s; }

.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 0; }

.appeared {
  opacity: 1; }

.fadeIn {
  animation-name: fadeIn; }

.fadeInUp {
  animation-name: fadeInUp; }

.fadeInDown {
  animation-name: fadeInDown; }

.fadeInLeft {
  animation-name: fadeInLeft; }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html {
  font-size: 10px; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  line-height: 36px;
  color: #414141;
  font-weight: normal;
  overflow-x: hidden; }
  body.lockScroll {
    overflow: hidden; }

img {
  max-width: 100%; }

a {
  color: #020c2d;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; }
  a:hover {
    text-decoration: none; }
  a.secondary {
    color: #ec181e; }
    a.secondary:hover {
      color: #333333; }
  a.bold {
    font-family: "Pacifico", cursive;
    color: #020c2d; }
    a.bold:hover {
      text-decoration: underline; }

hr {
  margin: 20px 0;
  border-color: #cacaca; }
  hr.darkBorder {
    border-color: #999; }
  hr.extraSpace {
    margin: 40px 0; }

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #020c2d; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #010515; }

.margin-top-0 {
  margin-top: 0 !important; }

.margin-top-5 {
  margin-top: 5px !important; }

.margin-top-10 {
  margin-top: 10px !important; }

.margin-top-15 {
  margin-top: 15px !important; }

.margin-top-20 {
  margin-top: 20px !important; }

.margin-top-25 {
  margin-top: 25px !important; }

.margin-top-30 {
  margin-top: 30px !important; }

.margin-top-35 {
  margin-top: 35px !important; }

.margin-top-40 {
  margin-top: 40px !important; }

.margin-top-45 {
  margin-top: 45px !important; }

.margin-top-50 {
  margin-top: 50px !important; }

.margin-top-55 {
  margin-top: 55px !important; }

.margin-top-60 {
  margin-top: 60px !important; }

.margin-top-65 {
  margin-top: 65px !important; }

.margin-top-70 {
  margin-top: 70px !important; }

.margin-top-75 {
  margin-top: 75px !important; }

.margin-top-80 {
  margin-top: 80px !important; }

.margin-left-0 {
  margin-left: 0 !important; }

.margin-left-5 {
  margin-left: 5px !important; }

.margin-left-10 {
  margin-left: 10px !important; }

.margin-left-15 {
  margin-left: 15px !important; }

.margin-left-20 {
  margin-left: 20px !important; }

.margin-left-25 {
  margin-left: 25px !important; }

.margin-left-30 {
  margin-left: 30px !important; }

.margin-left-35 {
  margin-left: 35px !important; }

.margin-left-40 {
  margin-left: 40px !important; }

.margin-left-45 {
  margin-left: 45px !important; }

.margin-left-50 {
  margin-left: 50px !important; }

.margin-left-55 {
  margin-left: 55px !important; }

.margin-left-60 {
  margin-left: 60px !important; }

.margin-left-65 {
  margin-left: 65px !important; }

.margin-left-70 {
  margin-left: 70px !important; }

.margin-left-75 {
  margin-left: 75px !important; }

.margin-left-80 {
  margin-left: 80px !important; }

.margin-bottom-0 {
  margin-bottom: 0 !important; }

.margin-bottom-5 {
  margin-bottom: 5px !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.margin-bottom-15 {
  margin-bottom: 15px !important; }

.margin-bottom-20 {
  margin-bottom: 20px !important; }

.margin-bottom-25 {
  margin-bottom: 25px !important; }

.margin-bottom-30 {
  margin-bottom: 30px !important; }

.margin-bottom-35 {
  margin-bottom: 35px !important; }

.margin-bottom-40 {
  margin-bottom: 40px !important; }

.margin-bottom-45 {
  margin-bottom: 45px !important; }

.margin-bottom-50 {
  margin-bottom: 50px !important; }

.margin-bottom-55 {
  margin-bottom: 55px !important; }

.margin-bottom-60 {
  margin-bottom: 60px !important; }

.margin-bottom-65 {
  margin-bottom: 65px !important; }

.margin-bottom-70 {
  margin-bottom: 70px !important; }

.margin-bottom-75 {
  margin-bottom: 75px !important; }

.margin-bottom-80 {
  margin-bottom: 80px !important; }

.margin-right-0 {
  margin-right: 0 !important; }

.margin-right-5 {
  margin-right: 5px !important; }

.margin-right-10 {
  margin-right: 10px !important; }

.margin-right-15 {
  margin-right: 15px !important; }

.margin-right-20 {
  margin-right: 20px !important; }

.margin-right-25 {
  margin-right: 25px !important; }

.margin-right-30 {
  margin-right: 30px !important; }

.margin-right-35 {
  margin-right: 35px !important; }

.margin-right-40 {
  margin-right: 40px !important; }

.margin-right-45 {
  margin-right: 45px !important; }

.margin-right-50 {
  margin-right: 50px !important; }

.margin-right-55 {
  margin-right: 55px !important; }

.margin-right-60 {
  margin-right: 60px !important; }

.margin-right-65 {
  margin-right: 65px !important; }

.margin-right-70 {
  margin-right: 70px !important; }

.margin-right-75 {
  margin-right: 75px !important; }

.margin-right-80 {
  margin-right: 80px !important; }

.padding-top-0 {
  padding-top: 0 !important; }

.padding-top-5 {
  padding-top: 5px !important; }

.padding-top-10 {
  padding-top: 10px !important; }

.padding-top-15 {
  padding-top: 15px !important; }

.padding-top-20 {
  padding-top: 20px !important; }

.padding-top-25 {
  padding-top: 25px !important; }

.padding-top-30 {
  padding-top: 30px !important; }

.padding-top-35 {
  padding-top: 35px !important; }

.padding-top-40 {
  padding-top: 40px !important; }

.padding-top-45 {
  padding-top: 45px !important; }

.padding-top-50 {
  padding-top: 50px !important; }

.padding-top-55 {
  padding-top: 55px !important; }

.padding-top-60 {
  padding-top: 60px !important; }

.padding-top-65 {
  padding-top: 65px !important; }

.padding-top-70 {
  padding-top: 70px !important; }

.padding-top-75 {
  padding-top: 75px !important; }

.padding-top-80 {
  padding-top: 80px !important; }

.padding-left-0 {
  padding-left: 0 !important; }

.padding-left-5 {
  padding-left: 5px !important; }

.padding-left-10 {
  padding-left: 10px !important; }

.padding-left-15 {
  padding-left: 15px !important; }

.padding-left-20 {
  padding-left: 20px !important; }

.padding-left-25 {
  padding-left: 25px !important; }

.padding-left-30 {
  padding-left: 30px !important; }

.padding-left-35 {
  padding-left: 35px !important; }

.padding-left-40 {
  padding-left: 40px !important; }

.padding-left-45 {
  padding-left: 45px !important; }

.padding-left-50 {
  padding-left: 50px !important; }

.padding-left-55 {
  padding-left: 55px !important; }

.padding-left-60 {
  padding-left: 60px !important; }

.padding-left-65 {
  padding-left: 65px !important; }

.padding-left-70 {
  padding-left: 70px !important; }

.padding-left-75 {
  padding-left: 75px !important; }

.padding-left-80 {
  padding-left: 80px !important; }

.padding-bottom-0 {
  padding-bottom: 0 !important; }

.padding-bottom-5 {
  padding-bottom: 5px !important; }

.padding-bottom-10 {
  padding-bottom: 10px !important; }

.padding-bottom-15 {
  padding-bottom: 15px !important; }

.padding-bottom-20 {
  padding-bottom: 20px !important; }

.padding-bottom-25 {
  padding-bottom: 25px !important; }

.padding-bottom-30 {
  padding-bottom: 30px !important; }

.padding-bottom-35 {
  padding-bottom: 35px !important; }

.padding-bottom-40 {
  padding-bottom: 40px !important; }

.padding-bottom-45 {
  padding-bottom: 45px !important; }

.padding-bottom-50 {
  padding-bottom: 50px !important; }

.padding-bottom-55 {
  padding-bottom: 55px !important; }

.padding-bottom-60 {
  padding-bottom: 60px !important; }

.padding-bottom-65 {
  padding-bottom: 65px !important; }

.padding-bottom-70 {
  padding-bottom: 70px !important; }

.padding-bottom-75 {
  padding-bottom: 75px !important; }

.padding-bottom-80 {
  padding-bottom: 80px !important; }

.padding-right-0 {
  padding-right: 0 !important; }

.padding-right-5 {
  padding-right: 5px !important; }

.padding-right-10 {
  padding-right: 10px !important; }

.padding-right-15 {
  padding-right: 15px !important; }

.padding-right-20 {
  padding-right: 20px !important; }

.padding-right-25 {
  padding-right: 25px !important; }

.padding-right-30 {
  padding-right: 30px !important; }

.padding-right-35 {
  padding-right: 35px !important; }

.padding-right-40 {
  padding-right: 40px !important; }

.padding-right-45 {
  padding-right: 45px !important; }

.padding-right-50 {
  padding-right: 50px !important; }

.padding-right-55 {
  padding-right: 55px !important; }

.padding-right-60 {
  padding-right: 60px !important; }

.padding-right-65 {
  padding-right: 65px !important; }

.padding-right-70 {
  padding-right: 70px !important; }

.padding-right-75 {
  padding-right: 75px !important; }

.padding-right-80 {
  padding-right: 80px !important; }

.width10 {
  width: 10%; }

.width15 {
  width: 15%; }

.width20 {
  width: 20%; }

.width25 {
  width: 25%; }

.width30 {
  width: 30%; }

.width35 {
  width: 35%; }

.width40 {
  width: 40%; }

.width45 {
  width: 45%; }

.width50 {
  width: 50%; }

.width55 {
  width: 55%; }

.width60 {
  width: 60%; }

.width65 {
  width: 65%; }

.width70 {
  width: 70%; }

.width75 {
  width: 75%; }

.width80 {
  width: 80%; }

.width85 {
  width: 85%; }

.width90 {
  width: 90%; }

.width95 {
  width: 95%; }

.width100 {
  width: 100%; }

@media (max-width: 1366px) {
  .pTN_on_laptop {
    padding-top: 0 !important; } }

@media (max-width: 1280px) {
  .pTN_on_desktop {
    padding-top: 0 !important; } }

@media (max-width: 1024px) {
  .pTN_on_desktopSmall {
    padding-top: 0 !important; } }

@media (max-width: 992px) {
  .pTN_on_tablet {
    padding-top: 0 !important; } }

@media (max-width: 768px) {
  .pTN_on_tabletSmall {
    padding-top: 0 !important; } }

@media (max-width: 767px) {
  .pTN_on_mobileBig {
    padding-top: 0 !important; } }

@media (max-width: 480px) {
  .pTN_on_mobile {
    padding-top: 0 !important; } }

@media (max-width: 375px) {
  .pTN_on_mobileSmall {
    padding-top: 0 !important; } }

@media (max-width: 1280px) {
  .pBN_on_desktop {
    padding-bottom: 0 !important; } }

@media (max-width: 1024px) {
  .pBN_on_desktopSmall {
    padding-bottom: 0 !important; } }

@media (max-width: 992px) {
  .pBN_on_tablet {
    padding-bottom: 0 !important; } }

@media (max-width: 768px) {
  .pBN_on_tabletSmall {
    padding-bottom: 0 !important; } }

@media (max-width: 767px) {
  .pBN_on_mobileBig {
    padding-bottom: 0 !important; } }

@media (max-width: 480px) {
  .pBN_on_mobile {
    padding-bottom: 0 !important; } }

@media (max-width: 375px) {
  .pBN_on_mobileSmall {
    padding-bottom: 0 !important; } }

@media (max-width: 1280px) {
  .pRN_on_desktop {
    padding-right: 0 !important; } }

@media (max-width: 1024px) {
  .pRN_on_desktopSmall {
    padding-right: 0 !important; } }

@media (max-width: 992px) {
  .pRN_on_tablet {
    padding-right: 0 !important; } }

@media (max-width: 768px) {
  .pRN_on_tabletSmall {
    padding-right: 0 !important; } }

@media (max-width: 767px) {
  .pRN_on_mobileBig {
    padding-right: 0 !important; } }

@media (max-width: 480px) {
  .pRN_on_mobile {
    padding-top: 0 !important; } }

@media (max-width: 375px) {
  .pRN_on_mobileSmall {
    padding-right: 0 !important; } }

@media (max-width: 1280px) {
  .pLN_on_desktop {
    padding-left: 0 !important; } }

@media (max-width: 1024px) {
  .pLN_on_desktopSmall {
    padding-left: 0 !important; } }

@media (max-width: 992px) {
  .pLN_on_tablet {
    padding-left: 0 !important; } }

@media (max-width: 768px) {
  .pLN_on_tabletSmall {
    padding-left: 0 !important; } }

@media (max-width: 767px) {
  .pLN_on_mobileBig {
    padding-left: 0 !important; } }

@media (max-width: 480px) {
  .pLN_on_mobile {
    padding-left: 0 !important; } }

@media (max-width: 375px) {
  .pLN_on_mobileSmall {
    padding-left: 0 !important; } }

.show_on_laptop {
  display: none !important; }
  @media (max-width: 1366px) {
    .show_on_laptop {
      display: inline-block !important; } }

.show_on_desktop {
  display: none !important; }
  @media (max-width: 1280px) {
    .show_on_desktop {
      display: inline-block !important; } }

.show_on_desktopSmall {
  display: none !important; }
  @media (max-width: 1024px) {
    .show_on_desktopSmall {
      display: inline-block !important; } }

.show_on_tablet {
  display: none !important; }
  @media (max-width: 992px) {
    .show_on_tablet {
      display: block !important; } }

.show_on_tabletSmall {
  display: none !important; }
  @media (max-width: 768px) {
    .show_on_tabletSmall {
      display: block !important; } }

.show_on_mobileBig {
  display: none !important; }
  @media (max-width: 767px) {
    .show_on_mobileBig {
      display: block !important; } }

.show_on_mobile {
  display: none !important; }
  @media (max-width: 480px) {
    .show_on_mobile {
      display: block !important; } }

.hide {
  display: none !important; }

@media (max-width: 1366px) {
  .hide_on_laptop {
    display: none !important; } }

@media (max-width: 1280px) {
  .hide_on_desktop {
    display: none !important; } }

@media (max-width: 1024px) {
  .hide_on_desktopSmall {
    display: none !important; } }

@media (max-width: 992px) {
  .hide_on_tablet {
    display: none !important; } }

@media (max-width: 768px) {
  .hide_on_tabletSmall {
    display: none !important; } }

@media (max-width: 767px) {
  .hide_on_mobileBig {
    display: none !important; } }

@media (max-width: 480px) {
  .hide_on_mobile {
    display: none !important; } }

@media (max-width: 375px) {
  .hide_on_mobileSmall {
    display: none; } }

.hidden_h1 {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden; }

.nopadding_LR {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.nopadding_L {
  padding-left: 0 !important; }

.nopadding_R {
  padding-right: 0 !important; }

.no_margin {
  margin: 0; }

.uppercase {
  text-transform: uppercase; }

.nouppercase {
  text-transform: none !important; }

.cursor {
  cursor: pointer; }

@media (max-width: 767px) {
  .text-xs-left {
    text-align: left !important; }
  .margin-top-xs-1 {
    margin-top: 1rem; }
  .margin-top-xs-2 {
    margin-top: 2rem; }
  .margin-top-xs-3 {
    margin-top: 3rem; }
  .margin-top-xs-4 {
    margin-top: 4rem; }
  .margin-top-xs-5 {
    margin-top: 5rem; }
  .margin-bottom-xs-1 {
    margin-bottom: 1rem; }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem; }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem; }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem; }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem; } }

ul {
  margin: 0;
  padding: 0; }
  ul.bulletList {
    padding: 0 0 0 18px; }
    ul.bulletList li {
      display: list-item;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 5px; }
      ul.bulletList li:last-child {
        margin-bottom: 0; }
      @media (max-width: 768px) {
        ul.bulletList li {
          font-size: 14px; } }
    ul.bulletList.alignRight {
      text-align: right;
      padding: 0 16px 0 0; }
      ul.bulletList.alignRight li {
        direction: rtl; }
    ul.bulletList.white li {
      color: #fff; }
    ul.bulletList.primary li {
      color: #020c2d; }
    ul.bulletList.size18 li {
      font-size: 18px; }
    ul.bulletList.size21 li {
      font-size: 21px;
      line-height: 34px; }
      @media (max-width: 768px) {
        ul.bulletList.size21 li {
          font-size: 18px;
          line-height: 30px; } }
      @media (max-width: 767px) {
        ul.bulletList.size21 li {
          font-size: 16px;
          line-height: 24px; } }
      @media (max-width: 480px) {
        ul.bulletList.size21 li {
          font-size: 14px;
          line-height: 21px; } }
    ul.bulletList.extraMargin li {
      margin-bottom: 20px; }
    ul.bulletList.fontLight li {
      font-family: "Montserrat", sans-serif; }
      ul.bulletList.fontLight li b {
        font-family: "Montserrat", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #232323;
  margin: 0 0 20px;
  line-height: 1.18; }
  h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
    font-family: "Montserrat", sans-serif;
    font-weight: 300; }
    h1 span .normal, h2 span .normal, h3 span .normal, h4 span .normal, h5 span .normal, h6 span .normal {
      font-family: inherit;
      font-weight: 300; }
  h1.reverse, h2.reverse, h3.reverse, h4.reverse, h5.reverse, h6.reverse {
    font-family: "Montserrat", sans-serif;
    font-weight: 300; }
    h1.reverse span, h2.reverse span, h3.reverse span, h4.reverse span, h5.reverse span, h6.reverse span {
      font-family: "Montserrat", sans-serif;
      font-weight: 700; }
      h1.reverse span .normal, h2.reverse span .normal, h3.reverse span .normal, h4.reverse span .normal, h5.reverse span .normal, h6.reverse span .normal {
        font-family: inherit;
        font-weight: 400; }
  h1.normal, h2.normal, h3.normal, h4.normal, h5.normal, h6.normal {
    font-family: "Montserrat", sans-serif;
    font-weight: 400; }
  h1.semi, h2.semi, h3.semi, h4.semi, h5.semi, h6.semi {
    font-family: "Montserrat", sans-serif;
    font-weight: 500; }
  h1.black, h2.black, h3.black, h4.black, h5.black, h6.black {
    font-family: "Montserrat", sans-serif;
    font-weight: 900; }
  h1.capitalize, h2.capitalize, h3.capitalize, h4.capitalize, h5.capitalize, h6.capitalize {
    text-transform: capitalize; }
  h1.white, h2.white, h3.white, h4.white, h5.white, h6.white {
    color: #fff; }
  @media (max-width: 992px) {
    h1 br, h2 br, h3 br, h4 br, h5 br, h6 br {
      display: none; } }

p {
  font-size: 16px;
  color: #343434;
  line-height: 1.6;
  margin-bottom: 20px; }
  p b {
    font-family: "Montserrat", sans-serif; }
  p.nmb {
    margin-bottom: 0; }
  p.small {
    font-size: 14px; }
  p.big {
    font-size: 18px; }
  p.light {
    font-family: "Montserrat", sans-serif;
    font-weight: 300; }
  p.primary {
    color: #020c2d; }
  p.bold {
    font-family: "Montserrat", sans-serif; }
  p.white {
    color: #fff; }
  @media (max-width: 1366px) {
    p.breakBr_laptop br {
      display: none; } }
  @media (max-width: 1280px) {
    p.breakBr_desktop br {
      display: none; } }
  @media (max-width: 1024px) {
    p.breakBr_desktopSmall br {
      display: none; } }
  @media (max-width: 992px) {
    p.breakBr_tablet br {
      display: none; } }
  @media (max-width: 1024px) {
    p {
      font-size: 14px; } }
  @media (max-width: 768px) {
    p br {
      display: none; } }
  @media (max-width: 767px) {
    p {
      font-size: 13px;
      line-height: 1.5; } }

.primary_color {
  color: #020c2d; }

.secondary_color {
  color: #ec181e; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.uppercase {
  text-transform: uppercase; }

.nouppercase {
  text-transform: none !important; }

.cursor {
  cursor: pointer; }

.boxShadow--Top {
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.03); }

.boxShadow--Bottom {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03); }

.z-top {
  z-index: 999999 !important; }

.mCustomScrollBox {
  padding-right: 15px; }
  .mCustomScrollBox .mCSB_scrollTools {
    width: 6px; }

.points_label {
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: 15px;
  color: white;
  line-height: 30px;
  margin-right: 15px;
  padding: 0 15px;
  background: #01b9f5;
  white-space: nowrap; }

.alert {
  position: relative;
  min-height: 1.6rem;
  margin: 1.6rem 0;
  line-height: 1.6rem;
  border-radius: .2rem;
  padding: 1.6rem 2rem;
  background: #f8f8f9;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
  transition: opacity .1s ease,color .1s ease, background .1s ease, box-shadow .1s ease,-webkit-box-shadow .1s ease; }
  .alert-success {
    background-color: #fcfff5;
    color: #2c662d;
    -webkit-box-shadow: 0 0 0 .1rem #a3c293 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 .1rem #a3c293 inset, 0 0 0 0 transparent; }
  .alert-error {
    background-color: #fff6f6;
    color: #9f3a38;
    -webkit-box-shadow: 0 0 0 .1rem #e0b4b4 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 .1rem #e0b4b4 inset, 0 0 0 0 transparent; }
  .alert-warning {
    background-color: #fffaf3;
    color: #573a08;
    -webkit-box-shadow: 0 0 0 .1rem #c9ba9b inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 .1rem #c9ba9b inset, 0 0 0 0 transparent; }
  .alert-info {
    background-color: #f6faff;
    color: #386a9f;
    -webkit-box-shadow: 0 0 0 .1rem #b4c0e0 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 .1rem #b4c0e0 inset, 0 0 0 0 transparent; }
  .alert-positioned {
    position: absolute;
    z-index: 9999;
    min-width: 50rem; }
    .alert-positioned.bottom {
      bottom: 4rem; }
    .alert-positioned.right {
      right: 4rem; }
    .alert-positioned.left {
      left: 4rem; }
    .alert-positioned.top {
      top: 4rem; }
    .alert-positioned.center {
      left: 50%;
      transform: translateX(-50%); }

.staticBanner {
  min-height: 32rem;
  background-repeat: no-repeat;
  background-size: cover; }
  .staticBanner.aboutusPage {
    background-image: url(../img/aboutus/banner-bg.jpg);
    background-size: 10%;
    background-position: left 2.5rem bottom 1.5rem; }
    @media (max-width: 1023px) {
      .staticBanner.aboutusPage {
        background-image: none; } }
  .staticBanner.privacyPage {
    background-image: url(../img/privacy/privacy-bg.jpg); }
    @media (max-width: 767px) {
      .staticBanner.privacyPage {
        min-height: 24rem; } }
    @media (max-width: 479px) {
      .staticBanner.privacyPage {
        min-height: 16rem; } }
  .staticBanner.featuresPage {
    background-image: url(../img/features/banner-bg.png);
    background-size: 15%;
    background-position: right -2rem bottom 6rem; }
    @media (max-width: 1023px) {
      .staticBanner.featuresPage {
        background-image: none; } }

.breadcrumb-custom {
  background: none;
  padding: 0; }

.btn {
  font-family: "Pacifico", cursive;
  font-size: 16px;
  border: none;
  line-height: 1;
  padding: 15px 30px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; }
  .btn.font_normal {
    font-family: "Pacifico", cursive; }
  .btn.font_semi {
    font-family: "Pacifico", cursive; }
  .btn.font_bold {
    font-family: "Pacifico", cursive; }
  .btn.elipse {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px; }
  .btn.rounded-3 {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }
  .btn.rounded-4 {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }
  .btn.rounded-5 {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px; }
  .btn.btn-sm {
    font-size: 14px;
    padding: 10px 25px; }
  .btn.btn-lg {
    font-size: 18px;
    padding: 17px 40px; }
    @media (max-width: 480px) {
      .btn.btn-lg {
        font-size: 15px;
        padding: 15px 25px; } }
  .btn.btn-primary {
    position: relative;
    z-index: 1;
    background: #020c2d;
    outline: none; }
    .btn.btn-primary:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      z-index: -1;
      width: 100%;
      background: #010618;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
    .btn.btn-primary:hover {
      background: #010618; }
      .btn.btn-primary:hover:before {
        height: 100%; }
  .btn.btn-secondary {
    background: #ec181e;
    color: #fff; }
    .btn.btn-secondary:hover {
      background: #ec181e; }
  .btn.btn-default {
    background: #cdcdcd;
    color: #000; }
    .btn.btn-default:hover {
      background: #bfbfbf; }
  .btn.btn-border {
    color: #232323;
    border: solid #909090 1px; }
    .btn.btn-border:hover {
      color: white;
      background: #020c2d;
      border-color: #020c2d; }
  .btn.btn-border-primary {
    color: #020c2d;
    border: solid #020c2d 1px; }
    .btn.btn-border-primary:hover {
      color: white;
      background: #020c2d; }
  .btn.btn-border-white {
    color: #fff;
    border: solid #fff 1px; }
    .btn.btn-border-white.rounded {
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px; }
    .btn.btn-border-white:hover {
      background: #fff;
      color: #222; }
  .btn.btn-dark {
    color: #fff;
    background: #676767; }
    .btn.btn-dark:hover {
      background: #232323; }
  .btn.btn-white {
    font-family: "Pacifico", cursive;
    color: #333;
    background: #fff; }
    .btn.btn-white:hover {
      color: #fff;
      background: #020c2d; }
  .btn.btn-block {
    display: block;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
    @media (max-width: 480px) {
      .btn.btn-block {
        padding: 15px; } }
  @media (max-width: 480px) {
    .btn {
      font-size: 13px;
      padding: 8px 15px; } }

.btnViewAll {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #666666;
  padding-right: 40px;
  text-transform: uppercase; }
  .btnViewAll .arrow {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: -1;
    width: 30px;
    height: 14px;
    margin-left: 5px;
    background-color: #777;
    transform: translateY(-50%);
    clip-path: polygon(0 44%, 61% 44%, 61% 20%, 83% 55%, 61% 83%, 61% 63%, 0 64%);
    transition: all .25s ease-in-out; }
  .btnViewAll:hover {
    color: #020c2d; }
    .btnViewAll:hover .arrow {
      width: 40px;
      background-color: #020c2d;
      transform: translate(10px, -50%); }

.backBtn {
  padding: 20px 0; }
  .backBtn a {
    color: #333333; }

.custom-card {
  border: none;
  background: white;
  margin-bottom: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }
  .custom-card.lightBg {
    background: #fafafa; }
  .custom-card .card-body {
    padding: 30px; }
    .custom-card .card-body.padding40 {
      padding: 40px; }
      @media (max-width: 767px) {
        .custom-card .card-body.padding40 {
          padding: 25px; } }
    .custom-card .card-body.padding60 {
      padding: 60px; }
      @media (max-width: 767px) {
        .custom-card .card-body.padding60 {
          padding: 30px; } }
    .custom-card .card-body.padding40__90 {
      padding: 40px 90px; }
      @media (max-width: 1365px) {
        .custom-card .card-body.padding40__90 {
          padding: 40px 30px; } }
      @media (max-width: 992px) {
        .custom-card .card-body.padding40__90 {
          padding: 30px 40px; } }
      @media (max-width: 768px) {
        .custom-card .card-body.padding40__90 {
          padding: 20px 30px; } }
      @media (max-width: 767px) {
        .custom-card .card-body.padding40__90 {
          padding: 30px; } }
    @media (max-width: 767px) {
      .custom-card .card-body {
        padding: 20px; } }

.commonDropdown {
  position: relative; }
  .commonDropdown__handle {
    cursor: pointer;
    background: url("../img/svg/arrow-down-angle.svg") calc(100% - 15px) center no-repeat white;
    background-size: 10px;
    border-radius: 4px;
    padding: 10px 70px 10px 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05); }
  .commonDropdown__menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1010;
    min-width: 180px;
    background: white;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05); }
    .commonDropdown__menu.right {
      left: auto;
      right: 0; }
    .commonDropdown__menu .item {
      cursor: pointer;
      padding: 10px 15px;
      border-bottom: 1px solid #eee; }
      .commonDropdown__menu .item:last-child {
        border-bottom: none; }
      .commonDropdown__menu .item img {
        max-height: 28px; }
      .commonDropdown__menu .item:hover {
        opacity: 0.75; }

.datepicker {
  direction: ltr; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }

.datepicker-rtl.dropdown-menu {
  left: auto; }

.datepicker-rtl table tr td span {
  float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0; }

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute; }

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none; }

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer; }

.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999; }

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default; }

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0; }

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000; }

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background-color: #fdf59a; }

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover:active {
  background-color: #fbf069\9; }

.datepicker table tr td.today:hover:hover {
  color: #000; }

.datepicker table tr td.today.active:hover {
  color: #fff; }

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover:hover, .datepicker table tr td.range.today.disabled:hover[disabled], .datepicker table tr td.range.today.disabled[disabled], .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today:hover[disabled], .datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a; }

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9; }

.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(grey));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: linear-gradient(to bottom, #b3b3b3, grey);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: grey grey #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover:hover, .datepicker table tr td.selected.disabled:hover[disabled], .datepicker table tr td.selected.disabled[disabled], .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected:hover[disabled], .datepicker table tr td.selected[disabled] {
  background-color: grey; }

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover:active {
  background-color: #666\9; }

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: #04c; }

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background-color: #039\9; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.datepicker table tr td span.focused, .datepicker table tr td span:hover {
  background: #eee; }

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default; }

.datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
  background-color: #04c; }

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background-color: #039\9; }

.datepicker table tr td span.new, .datepicker table tr td span.old {
  color: #999; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: #eee; }

.datepicker .next.disabled, .datepicker .prev.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-append.date .add-on, .input-prepend.date .add-on {
  cursor: pointer; }

.input-append.date .add-on i, .input-prepend.date .add-on i {
  margin-top: 3px; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0; }

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 20px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px; }

.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  color: #333;
  font-size: 13px;
  line-height: 20px; }

.datepicker.datepicker-inline td, .datepicker.datepicker-inline th, .datepicker.dropdown-menu td, .datepicker.dropdown-menu th {
  padding: 4px 5px; }

.form-yesno {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center; }
  .form-yesno label {
    display: flex;
    font-size: 15px;
    cursor: pointer;
    margin: 0 0 0 15px;
    align-items: center; }
    .form-yesno label:first-child {
      margin: 0; }
    .form-yesno label input {
      position: absolute;
      opacity: 0; }
    .form-yesno label .text {
      font-size: 16px;
      user-select: none;
      color: #020c2d; }
      @media screen and (max-width: 1024px) {
        .form-yesno label .text {
          font-size: 14px; } }
    .form-yesno label .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;
      border-radius: 50%;
      border: 1px solid #020c2d; }
      .form-yesno label .icon:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #ec181e;
        border-radius: 50%;
        opacity: 0;
        transition: all .25s ease-out; }
    .form-yesno label input:checked + .icon {
      border-color: #ec181e; }
      .form-yesno label input:checked + .icon:before {
        opacity: 1; }
  .form-yesno--column {
    flex-direction: column; }
    .form-yesno--column label {
      width: 100%;
      margin: 15px 0 0; }
  .form-yesno--square label .icon {
    border-radius: 0; }
    .form-yesno--square label .icon:before {
      border-radius: 0; }
  .form-yesno--smallSize label {
    margin-top: 7px; }
    .form-yesno--smallSize label .icon {
      width: 14px;
      height: 14px; }
      .form-yesno--smallSize label .icon:before {
        width: 8px;
        height: 8px; }
  @media screen and (max-width: 576px) {
    .form-yesno {
      flex-direction: column; }
      .form-yesno label {
        width: 100%;
        margin: 15px 0 0; } }

.formBox {
  margin: 0; }
  .formBox fieldset {
    position: relative;
    z-index: 1;
    margin-bottom: 30px; }
    .formBox fieldset:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 2px;
      margin-top: -1px;
      background: #ec181e;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
    .formBox fieldset .placeholder {
      position: absolute;
      top: 10px;
      left: 0;
      z-index: -1;
      font-size: 16px;
      color: #474747;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
      @media (max-width: 1024px) {
        .formBox fieldset .placeholder {
          font-size: 14px; } }
    .formBox fieldset .rightPlaceHolder {
      position: absolute;
      top: 15px;
      right: 10px;
      z-index: 2;
      font-size: 13px;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
    .formBox fieldset .bottomPlaceHolder {
      position: absolute;
      top: 105%;
      left: 0;
      z-index: 2;
      font-size: 13px;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
    .formBox fieldset .form-control {
      font-family: "Pacifico", cursive;
      font-size: 16px;
      color: #212121;
      border: none;
      height: 46px;
      outline: none;
      background: transparent;
      border-bottom: solid #7b7b7b 1px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
      .formBox fieldset .form-control:focus {
        border-bottom-color: #333; }
    .formBox fieldset select.form-control {
      cursor: pointer; }
    .formBox fieldset textarea.form-control {
      height: 100px;
      resize: none; }
    .formBox fieldset label.error {
      position: absolute;
      top: 105%;
      right: 0;
      color: red;
      display: none; }
    .formBox fieldset.input--filled:after {
      width: 100%; }
    .formBox fieldset.input--filled .form-control {
      border-bottom-color: #333; }
    .formBox fieldset.input--filled .placeholder {
      font-size: 14px;
      top: -15px;
      color: #ec181e; }
    @media (max-width: 767px) {
      .formBox fieldset {
        margin-bottom: 25px; } }
  .formBox .radioBtnBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .formBox .radioBtnBox li {
      position: relative;
      z-index: 1;
      display: block;
      font-size: 16px;
      margin-left: 25px; }
      .formBox .radioBtnBox li:first-child {
        margin-left: 0; }
      .formBox .radioBtnBox li label {
        display: flex;
        margin: 0;
        cursor: pointer;
        align-items: center; }
      .formBox .radioBtnBox li input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        z-index: -1; }
        .formBox .radioBtnBox li input:checked + .icon {
          border-color: #ec181e; }
          .formBox .radioBtnBox li input:checked + .icon:before {
            display: block; }
      .formBox .radioBtnBox li .icon {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        border: solid #ababab 1px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%; }
        .formBox .radioBtnBox li .icon:before {
          display: none;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background: #ec181e;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
      .formBox .radioBtnBox li .txt {
        font-size: 15px;
        color: #333;
        padding-left: 7px;
        white-space: nowrap;
        width: calc(100% - 25px); }
  .formBox .checkboxBtnBox {
    display: flex; }
    .formBox .checkboxBtnBox li {
      position: relative;
      z-index: 1;
      display: block;
      margin-bottom: 25px; }
      .formBox .checkboxBtnBox li:last-child {
        margin-bottom: 0; }
      .formBox .checkboxBtnBox li label {
        display: flex;
        cursor: pointer;
        align-items: center; }
        .formBox .checkboxBtnBox li label.tnc {
          align-items: flex-start; }
          .formBox .checkboxBtnBox li label.tnc .txt {
            width: calc(100% - 40px); }
      .formBox .checkboxBtnBox li input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        z-index: -1; }
        .formBox .checkboxBtnBox li input:checked + .icon {
          border-color: #ec181e; }
          .formBox .checkboxBtnBox li input:checked + .icon:before {
            display: block; }
          .formBox .checkboxBtnBox li input:checked + .icon:after {
            display: block; }
      .formBox .checkboxBtnBox li .icon {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        cursor: pointer;
        border: solid #ababab 1px; }
        .formBox .checkboxBtnBox li .icon:before {
          display: none;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          background: #ec181e; }
        .formBox .checkboxBtnBox li .icon:after {
          display: none;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          background-size: 10px; }
      .formBox .checkboxBtnBox li .txt {
        font-size: 18px;
        color: #333;
        padding-left: 20px;
        width: calc(100% - 25px); }
        @media (max-width: 768px) {
          .formBox .checkboxBtnBox li .txt {
            font-size: 16px;
            padding-left: 15px; } }
  .formBox.viewMode fieldset .placeholder {
    position: static;
    display: block;
    font-family: "Pacifico", cursive;
    color: #676767;
    margin-bottom: 10px; }
  .formBox.viewMode fieldset p {
    font-size: 18px;
    color: #333;
    margin-bottom: 0; }

label.error {
  font-size: 14px;
  color: red; }

.form-control {
  font-family: "Pacifico", cursive;
  font-size: 15px;
  height: auto;
  padding: 10px;
  color: #232323;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }
  .form-control.smPadding {
    font-size: 14px;
    padding: 4px; }

select.form-control.customDropdownIcon {
  background: url("../img/svg/caret-down.svg") 96% center no-repeat;
  background-size: 10px;
  -webkit-appearance: none;
  appearance: none; }

.styled__checkBox {
  position: relative;
  z-index: 1; }
  .styled__checkBox label {
    font-family: "Pacifico", cursive;
    display: flex;
    margin: 0;
    cursor: pointer;
    align-items: center; }
    .styled__checkBox label.tnc {
      align-items: flex-start; }
      .styled__checkBox label.tnc .txt {
        width: calc(100% - 40px); }
  .styled__checkBox input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1; }
    .styled__checkBox input:checked + .icon {
      border-color: #ec181e; }
      .styled__checkBox input:checked + .icon:before {
        display: block; }
  .styled__checkBox .icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: solid #cdcdcd 1px; }
    .styled__checkBox .icon:before {
      display: none;
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 16px;
      height: 16px;
      background: url("../img/svg/tick-white.svg") center no-repeat #ec181e;
      background-size: 12px; }
  .styled__checkBox .txt {
    font-size: 15px;
    color: #939393;
    padding-left: 10px;
    width: calc(100% - 15px); }
    .styled__checkBox .txt a {
      font-family: "Pacifico", cursive;
      color: #939393; }
      .styled__checkBox .txt a:hover {
        color: #333333;
        text-decoration: underline; }
    @media (max-width: 768px) {
      .styled__checkBox .txt {
        font-size: 16px;
        padding-left: 15px; } }

.headingBox {
  text-align: center;
  margin-bottom: 60px; }
  .headingBox.mb_sm {
    margin-bottom: 25px; }
  .headingBox.mb_md {
    margin-bottom: 40px; }
  .headingBox.mb_lg {
    margin-bottom: 80px; }
  .headingBox.left {
    text-align: left; }
  .headingBox h1, .headingBox h2, .headingBox h3, .headingBox h4, .headingBox h5, .headingBox h6 {
    margin-bottom: 10px; }
  .headingBox p {
    font-size: 18px;
    margin-bottom: 0;
    line-height: 28px; }
    @media (max-width: 1280px) {
      .headingBox p {
        font-size: 16px;
        line-height: 24px; } }
    @media (max-width: 992px) {
      .headingBox p {
        font-size: 14px;
        line-height: 21px; } }
  @media (max-width: 992px) {
    .headingBox {
      margin-bottom: 40px; } }
  @media (max-width: 480px) {
    .headingBox {
      margin-bottom: 30px; } }

.headingStyle2 {
  display: flex; }
  .headingStyle2 .btn-custom {
    font-family: "Pacifico", cursive;
    font-size: 12px;
    padding: 6px 15px;
    border: 1px solid #020c2d; }

.size56 {
  font-size: 56px; }
  .size56.white {
    color: #fff; }
  @media (max-width: 1366px) {
    .size56 {
      font-size: 48px; } }
  @media (max-width: 1024px) {
    .size56 {
      font-size: 40px;
      line-height: 1.2; }
      .size56 span {
        font-size: 100%; } }
  @media (max-width: 767px) {
    .size56 {
      font-size: 30px; } }
  @media (max-width: 480px) {
    .size56 {
      font-size: 24px; } }
  @media (max-width: 375px) {
    .size56 {
      font-size: 21px; } }

.size50 {
  font-size: 50px; }
  .size50.white {
    color: #fff; }
  @media (max-width: 1366px) {
    .size50 {
      font-size: 45px; } }
  @media (max-width: 1024px) {
    .size50 {
      font-size: 40px;
      line-height: 1.2; }
      .size50 span {
        font-size: 100%; } }
  @media (max-width: 767px) {
    .size50 {
      font-size: 30px; } }
  @media (max-width: 480px) {
    .size50 {
      font-size: 24px; } }
  @media (max-width: 375px) {
    .size50 {
      font-size: 21px; } }

.size45 {
  font-size: 45px; }
  .size45.white {
    color: #fff; }
  @media (max-width: 1280px) {
    .size45 {
      font-size: 36px; } }
  @media (max-width: 992px) {
    .size45 {
      font-size: 30px;
      line-height: 1.2; } }
  @media (max-width: 767px) {
    .size45 {
      font-size: 24px; } }
  @media (max-width: 480px) {
    .size45 {
      font-size: 21px; } }

.size40 {
  font-size: 40px; }
  .size40.white {
    color: #fff; }
  @media (max-width: 1280px) {
    .size40 {
      font-size: 32px; } }
  @media (max-width: 992px) {
    .size40 {
      font-size: 28px;
      line-height: 1.2; } }
  @media (max-width: 767px) {
    .size40 {
      font-size: 24px; } }
  @media (max-width: 480px) {
    .size40 {
      font-size: 21px; } }
  @media (max-width: 375px) {
    .size40 {
      font-size: 18px; } }

.size36 {
  font-size: 36px; }
  .size36.white {
    color: #fff; }
  @media (max-width: 1280px) {
    .size36 {
      font-size: 30px; } }
  @media (max-width: 992px) {
    .size36 {
      font-size: 24px;
      line-height: 1.2; }
      .size36 span {
        font-size: 100%; } }
  @media (max-width: 767px) {
    .size36 {
      font-size: 24px; } }
  @media (max-width: 480px) {
    .size36 {
      font-size: 21px; } }
  @media (max-width: 375px) {
    .size36 {
      font-size: 18px; } }

.size30 {
  font-size: 30px; }
  .size30.white {
    color: #fff; }
  @media (max-width: 1280px) {
    .size30 {
      font-size: 28px; } }
  @media (max-width: 992px) {
    .size30 {
      font-size: 24px;
      line-height: 1.2; } }
  @media (max-width: 767px) {
    .size30 {
      font-size: 24px; } }
  @media (max-width: 480px) {
    .size30 {
      font-size: 21px; } }
  @media (max-width: 375px) {
    .size30 {
      font-size: 18px; } }

.size27 {
  font-size: 27px; }
  .size27.white {
    color: #fff; }
  @media (max-width: 1280px) {
    .size27 {
      font-size: 24px; } }
  @media (max-width: 767px) {
    .size27 {
      font-size: 21px; } }
  @media (max-width: 480px) {
    .size27 {
      font-size: 18px; } }

.size24 {
  font-size: 24px;
  line-height: 30px; }
  .size24.white {
    color: #fff; }
  @media (max-width: 767px) {
    .size24 {
      font-size: 21px;
      line-height: 1.2; } }
  @media (max-width: 480px) {
    .size24 {
      font-size: 18px; } }

.size21 {
  font-size: 21px; }
  .size21.white {
    color: #fff; }
  @media (max-width: 992px) {
    .size21 {
      font-size: 18px;
      line-height: 1.2; } }
  @media (max-width: 767px) {
    .size21 {
      font-size: 16px; } }

.size18 {
  font-size: 18px; }
  .size18.white {
    color: #fff; }
  @media (max-width: 992px) {
    .size18 {
      font-size: 16px;
      line-height: 1.2; } }
  @media (max-width: 767px) {
    .size18 {
      font-size: 15px; } }

.siteLoaderWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99991;
  background-color: rgba(255, 255, 255, 0.98);
  display: flex;
  align-items: center;
  justify-content: center; }
  .siteLoaderWrap__container {
    position: relative;
    z-index: 1;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .siteLoaderWrap__container .spinner1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 5px solid #f3f3f3;
      border-top: 5px solid #020c2d;
      animation: spin 600ms linear infinite; }
    .siteLoaderWrap__container .spinner2 {
      width: 90px;
      height: 90px;
      background: url("../img/logo.png") center center no-repeat;
      background-size: 50px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes reverseSpin {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

.centerLoader {
  display: flex;
  padding: 2rem 0;
  justify-content: center; }

.noRecordsFound {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0; }
  .noRecordsFound img {
    display: block;
    margin-bottom: 2rem; }
  .noRecordsFound h3 {
    font-size: 2.1rem;
    color: #020c2d;
    font-weight: "Pacifico", cursive;
    margin: 0; }

.paginationWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end; }
  .paginationWrapper .semantic-ui-react-button-pagination {
    display: flex; }
    .paginationWrapper .semantic-ui-react-button-pagination .button {
      margin-left: 4px;
      font-size: "Pacifico", cursive;
      border: 1px solid #ddd;
      background-color: #fff; }
      .paginationWrapper .semantic-ui-react-button-pagination .button:first-child {
        margin-left: 0;
        border-left: 1px solid #ddd; }
      .paginationWrapper .semantic-ui-react-button-pagination .button.currentPage {
        background-color: #020c2d;
        color: white; }

.rating {
  position: relative;
  z-index: 1;
  border: none; }
  .rating input {
    opacity: 0;
    visibility: hidden;
    position: absolute; }
  .rating label {
    margin: 0;
    float: right;
    color: #ddd;
    line-height: 1;
    margin-top: 2px; }
    .rating label:before {
      display: inline-block;
      content: "\f005";
      font-family: FontAwesome;
      font-size: 1.25em;
      margin: 0 5px;
      line-height: 1; }
  .rating .half:before {
    content: "\f089";
    position: absolute; }

/***** CSS Magic to Highlight Stars on Hover *****/
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #FFD700; }

/* hover previous stars in list */
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #FFED85; }

.section {
  padding-top: 80px;
  padding-bottom: 80px; }
  .section.nopadding {
    padding: 0 !important; }
  .section.nopadding--top {
    padding-top: 0 !important; }
  .section.nopadding--bottom {
    padding-bottom: 0 !important; }
  .section.sm {
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 992px) {
      .section.sm {
        padding-top: 30px;
        padding-bottom: 30px; } }
  .section.md {
    padding-top: 60px;
    padding-bottom: 60px; }
    @media (max-width: 1280px) {
      .section.md {
        padding-top: 50px;
        padding-bottom: 50px; } }
    @media (max-width: 992px) {
      .section.md {
        padding-top: 40px;
        padding-bottom: 40px; } }
    @media (max-width: 767px) {
      .section.md {
        padding-top: 30px;
        padding-bottom: 30px; } }
  .section.xl {
    padding-top: 100px;
    padding-bottom: 100px; }
    @media (max-width: 1280px) {
      .section.xl {
        padding-top: 80px;
        padding-bottom: 80px; } }
    @media (max-width: 992px) {
      .section.xl {
        padding-top: 40px;
        padding-bottom: 40px; } }
    @media (max-width: 767px) {
      .section.xl {
        padding-top: 30px;
        padding-bottom: 30px; } }
  .section.xxl {
    padding-top: 120px;
    padding-bottom: 120px; }
    @media (max-width: 1366px) {
      .section.xxl {
        padding-top: 100px;
        padding-bottom: 100px; } }
    @media (max-width: 1280px) {
      .section.xxl {
        padding-top: 80px;
        padding-bottom: 80px; } }
    @media (max-width: 992px) {
      .section.xxl {
        padding-top: 50px;
        padding-bottom: 50px; } }
    @media (max-width: 767px) {
      .section.xxl {
        padding-top: 30px;
        padding-bottom: 30px; } }
  .section.travelPadding {
    padding: 150px 0 60px; }
  .section.white {
    background: white; }
  .section.dark {
    background: #353535; }
  .section.primary {
    background: #020c2d; }
  .section.secondary {
    background: #ec181e; }
  .section.light {
    background: #ecf2f7; }
  .section.light__grey {
    background: #dee2e2; }
  @media (max-width: 1280px) {
    .section {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media (max-width: 992px) {
    .section {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (max-width: 767px) {
    .section {
      padding-top: 30px;
      padding-bottom: 30px; } }

.custom-table {
  margin: 0;
  font-size: "Pacifico", cursive; }
  .custom-table th, .custom-table td {
    padding: 1.5rem;
    border: none;
    vertical-align: middle; }
  .custom-table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f7fd; }

.simpleTabs {
  padding: 0; }
  .simpleTabs .nav {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    .simpleTabs .nav li {
      display: block;
      font-size: 18px;
      margin-left: 40px; }
      .simpleTabs .nav li:first-child {
        margin-left: 0; }
      .simpleTabs .nav li a {
        display: block;
        color: #333333;
        padding: 12px 0 10px;
        border-bottom: 3px solid transparent; }
        .simpleTabs .nav li a.active {
          border-color: #ec181e; }
      @media (max-width: 767px) {
        .simpleTabs .nav li {
          font-size: 16px;
          width: 33%;
          margin-left: 1px;
          text-align: center; } }
    @media (max-width: 767px) {
      .simpleTabs .nav {
        display: none; } }
  @media (max-width: 767px) {
    .simpleTabs .tab-content .tab-pane {
      padding: 20px;
      border: solid #ddd 1px; } }
  .simpleTabs .handler {
    display: none;
    font-size: 16px;
    line-height: 40px;
    border: solid #ddd 1px;
    background-color: #ffffff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#cccccc));
    background-image: -webkit-linear-gradient(top, #ffffff, #cccccc);
    background-image: -moz-linear-gradient(top, #ffffff, #cccccc);
    background-image: -ms-linear-gradient(top, #ffffff, #cccccc);
    background-image: -o-linear-gradient(top, #ffffff, #cccccc);
    background-image: linear-gradient(top, #ffffff, #cccccc);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#ffffff', endColorStr='#cccccc'); }
    .simpleTabs .handler a {
      display: block;
      color: black;
      padding: 0 20px; }
    @media (max-width: 767px) {
      .simpleTabs .handler {
        display: block; } }

.slider-container {
  width: 300px; }

.slider-container .back-bar {
  position: relative;
  height: 2px;
  background: #ddd; }

.slider-container .back-bar .selected-bar {
  position: absolute;
  top: -4px;
  height: 8px;
  background: #ec181e; }

.slider-container .back-bar .pointer {
  position: absolute;
  top: -8px;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #ddd;
  cursor: col-resize;
  opacity: 1;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); }

.slider-container .back-bar .pointer.last-active {
  z-index: 3; }

.slider-container .back-bar .pointer-label {
  position: absolute;
  top: -25px;
  font-size: 13px;
  background: white;
  white-space: nowrap;
  line-height: 1; }

.slider-container .back-bar .focused {
  z-index: 10; }

.slider-container .clickable-dummy {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1; }

.slider-container .scale {
  top: 2px;
  position: relative; }

.slider-container .scale span {
  position: absolute;
  height: 5px;
  border-left: 1px solid #999;
  font-size: 0; }

.slider-container .scale ins {
  font-size: 9px;
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 5px;
  color: #999;
  line-height: 1;
  display: none; }

.slider-container.slider-readonly .clickable-dummy,
.slider-container.slider-readonly .pointer {
  cursor: auto; }

.theme-green .back-bar {
  height: 5px;
  border-radius: 2px;
  background-color: #eeeeee;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0); }

.theme-green .back-bar .selected-bar {
  border-radius: 2px;
  background-color: #a1fad0;
  background-image: -moz-linear-gradient(top, #bdfade, #76fabc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bdfade), to(#76fabc));
  background-image: -webkit-linear-gradient(top, #bdfade, #76fabc);
  background-image: -o-linear-gradient(top, #bdfade, #76fabc);
  background-image: linear-gradient(to bottom, #bdfade, #76fabc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbdfade', endColorstr='#ff76fabc', GradientType=0); }

.theme-green .back-bar .pointer {
  width: 14px;
  height: 14px;
  top: -5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #AAA;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0); }

.theme-green .back-bar .pointer-label {
  color: #999; }

.theme-green .back-bar .focused {
  color: #333; }

.theme-green .scale span {
  border-left: 1px solid #e5e5e5; }

.theme-green .scale ins {
  color: #999; }

.theme-blue .back-bar {
  height: 5px;
  border-radius: 2px;
  background-color: #eeeeee;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0); }

.theme-blue .back-bar .selected-bar {
  border-radius: 2px;
  background-color: #92c1f9;
  background-image: -moz-linear-gradient(top, #b1d1f9, #64a8f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b1d1f9), to(#64a8f9));
  background-image: -webkit-linear-gradient(top, #b1d1f9, #64a8f9);
  background-image: -o-linear-gradient(top, #b1d1f9, #64a8f9);
  background-image: linear-gradient(to bottom, #b1d1f9, #64a8f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffb1d1f9', endColorstr='#ff64a8f9', GradientType=0); }

.theme-blue .back-bar .pointer {
  width: 14px;
  height: 14px;
  top: -5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #AAA;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0); }

.theme-blue .back-bar .pointer-label {
  color: #999; }

.theme-blue .back-bar .focused {
  color: #333; }

.theme-blue .scale span {
  border-left: 1px solid #e5e5e5; }

.theme-blue .scale ins {
  color: #999; }

.priceRangeFilter {
  position: relative;
  z-index: 1;
  padding: 25px 0 0; }
  .priceRangeFilter__cancel {
    position: absolute;
    top: -45px;
    right: 0;
    font-size: 15px;
    cursor: pointer;
    color: #ec181e; }
  .priceRangeFilter__dropdown {
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between; }
    .priceRangeFilter__dropdown span {
      font-size: 15px;
      padding: 0 10px; }
    .priceRangeFilter__dropdown .form-control {
      padding: 4px 7px;
      cursor: pointer; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.25s ease-out;
  background-color: #05164e; }
  .header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: height 0.25s ease-out;
    position: relative; }
    @media (max-width: 1023px) {
      .header .container {
        padding-top: 20px;
        padding-bottom: 20px; } }
    @media (max-width: 766px) {
      .header .container {
        padding-top: 15px;
        padding-bottom: 15px; } }
  .header__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    transition: all 0.25s ease-out;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
    .header__logo:after {
      content: "";
      position: absolute;
      top: -110%;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      width: 250px;
      height: 250px;
      background-color: #05164e;
      border-radius: 50%; }
      @media (max-width: 1023px) {
        .header__logo:after {
          display: none; } }
    @media (max-width: 1023px) {
      .header__logo {
        width: 104px;
        position: static;
        transform: none; } }
    @media (max-width: 1023px) {
      .header__logo {
        width: 85px; } }
    @media (max-width: 480px) {
      .header__logo {
        width: 65px; } }
    .header__logo a {
      display: block;
      width: 100px;
      margin-top: 15px; }
      @media (max-width: 1023px) {
        .header__logo a {
          width: 100%;
          margin-top: 0; } }
      .header__logo a img {
        width: 100%; }
  .header__links {
    display: flex;
    width: 100%; }
    @media (max-width: 1023px) {
      .header__links {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        background-color: #05164e;
        transform-origin: 0 0 0;
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0); }
        .header__links.open {
          opacity: 1;
          visibility: visible;
          transform: scaleY(1); } }
    .header__links ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      width: 100%;
      transition: all 0.25s cubic-bezier(0.61, 0.03, 0.43, 0.9); }
      @media (max-width: 1023px) {
        .header__links ul {
          flex-direction: column;
          flex-wrap: wrap;
          margin-bottom: 0; } }
      .header__links ul > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        min-width: 112.5px; }
        .header__links ul > li.space {
          width: 154px; }
          @media (max-width: 1440px) {
            .header__links ul > li.space {
              width: 134px; } }
          @media (max-width: 1023px) {
            .header__links ul > li.space {
              display: none; } }
        @media (max-width: 1023px) {
          .header__links ul > li {
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            min-width: auto;
            border-top: 1px solid rgba(255, 255, 255, 0.5); }
            .header__links ul > li:last-child {
              border-bottom: 1px solid rgba(255, 255, 255, 0.5); } }
        .header__links ul > li > a {
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          color: #fecb00;
          letter-spacing: 0.05px;
          position: relative;
          font-size: 15px;
          line-height: 1.2;
          text-transform: uppercase; }
          @media (max-width: 1023px) {
            .header__links ul > li > a {
              padding: 25px;
              width: 100%; } }
          .header__links ul > li > a:hover {
            color: #ffffff; }
        .header__links ul > li.login-btn a {
          background-color: #020c2d;
          border: 2px solid #020c2d;
          padding: 7px 20px;
          color: #ffffff;
          border-radius: 3px; }
          @media (max-width: 1023px) {
            .header__links ul > li.login-btn a {
              padding: 15px;
              border-radius: 0; } }
          .header__links ul > li.login-btn a:hover {
            color: #020c2d;
            background-color: #ffffff; }
  .header .menuHandle {
    display: none; }
    @media (max-width: 992px) {
      .header .menuHandle {
        display: block;
        position: relative;
        width: 33px;
        height: 26px; } }
    .header .menuHandle a.menuBtn {
      position: absolute;
      top: 0;
      left: 0;
      width: 33px;
      height: 26px;
      cursor: pointer;
      z-index: 50;
      transition: all 20ms cubic-bezier(0.4, 0, 0.2, 1);
      transition-delay: 100ms; }
      .header .menuHandle a.menuBtn span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 33px;
        height: 3px;
        z-index: 1;
        background: #fff;
        transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1); }
        .header .menuHandle a.menuBtn span:last-child {
          top: 22px; }
        .header .menuHandle a.menuBtn span.cross {
          top: 9px;
          left: -7px;
          z-index: 2;
          background: none; }
          .header .menuHandle a.menuBtn span.cross:before, .header .menuHandle a.menuBtn span.cross:after {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 3px;
            background: #fff;
            transition: all 350ms cubic-bezier(0.4, 0, 0.2, 1); }
      .header .menuHandle a.menuBtn:hover span.cross {
        left: 0; }
    .header .menuHandle.active a span:first-child {
      opacity: 0; }
    .header .menuHandle.active a span:last-child {
      opacity: 0; }
    .header .menuHandle.active a span.cross {
      height: 26px;
      top: 0;
      left: 0; }
      .header .menuHandle.active a span.cross:before {
        transform: rotate(-45deg); }
      .header .menuHandle.active a span.cross:after {
        transform: rotate(45deg); }
  .header.sticky {
    position: fixed;
    width: 100%;
    background-color: #05164e; }
    .header.sticky__logo {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      transition: all 0.25s ease-out;
      display: flex;
      align-items: flex-end;
      justify-content: center; }
      .header.sticky__logo:after {
        content: "";
        position: absolute;
        top: -110%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        width: 250px;
        height: 250px;
        background-color: #05164e;
        border-radius: 50%; }
      .header.sticky__logo a {
        display: block;
        width: 100px;
        margin-top: 15px; }
        .header.sticky__logo a img {
          width: 100%; }

.homePage .header {
  background-color: transparent;
  box-shadow: none; }
  @media (max-width: 1023px) {
    .homePage .header {
      background-color: #05164e; } }
  .homePage .header__logo:after {
    display: none; }
  .homePage .header__logo a img {
    width: 100%; }
  .homePage .header.sticky {
    position: fixed;
    width: 100%;
    background-color: #05164e; }
    .homePage .header.sticky .header__logo:after {
      display: block; }
      @media (max-width: 1023px) {
        .homePage .header.sticky .header__logo:after {
          display: none; } }

.menuWrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
  max-width: 430px;
  width: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out; }
  .menuWrapper.showMenu {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out; }
  .menuWrapper .closeMenu {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 20px;
    z-index: 100; }
  .menuWrapper .menuNav {
    position: relative;
    z-index: 1; }
    .menuWrapper .menuNav li {
      position: relative;
      display: block;
      font-size: 16px;
      text-transform: uppercase; }
      .menuWrapper .menuNav li a {
        position: relative;
        display: block;
        color: #939393;
        padding: 15px 60px; }
      .menuWrapper .menuNav li:hover {
        background: #070707; }
        .menuWrapper .menuNav li:hover a {
          color: white; }
  .menuWrapper .btnBox {
    display: flex;
    padding: 40px 60px 0; }
    .menuWrapper .btnBox a {
      display: block;
      width: 40px;
      height: 40px;
      font-size: 21px;
      color: black;
      line-height: 40px;
      text-align: center;
      background: white;
      margin-left: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%; }
      .menuWrapper .btnBox a:first-child {
        margin-left: 0; }
      .menuWrapper .btnBox a:hover {
        color: white;
        background: #ec181e; }

.wrapper {
  width: 100%;
  overflow: hidden; }

.container {
  margin: 0 auto;
  width: 96%;
  max-width: 1400px; }
  @media (max-width: 1023px) {
    .container {
      width: 100%; } }

.mainFooter {
  position: relative;
  z-index: 1;
  padding: 40px 0;
  background-color: #020c2d; }
  .mainFooter__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .mainFooter__content .divider {
      width: 1px;
      background-color: #0b1c54;
      height: 125px; }
      @media (max-width: 1023px) {
        .mainFooter__content .divider {
          width: 75%;
          height: 1px;
          margin: 50px 0; } }
    .mainFooter__content .item.item--first {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .mainFooter__content .item.item--second {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 479px) {
        .mainFooter__content .item.item--second {
          flex-direction: column; } }
    .mainFooter__content .item.item--third {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .mainFooter__content .item h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      color: #ffffff;
      margin: 0 0 20px;
      text-transform: uppercase; }
    .mainFooter__content .item_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      margin: 0 0 15px; }
      .mainFooter__content .item_icon img {
        width: auto; }
    .mainFooter__content .item_email {
      display: inline-block;
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.67px; }
      .mainFooter__content .item_email:not(:last-child) {
        margin-bottom: 15px; }
    .mainFooter__content .item_logo {
      display: block;
      width: 154px;
      margin-right: 20px; }
      @media (max-width: 1440px) {
        .mainFooter__content .item_logo {
          width: 134px; } }
      @media (max-width: 1023px) {
        .mainFooter__content .item_logo {
          width: 104px; } }
      @media (max-width: 479px) {
        .mainFooter__content .item_logo {
          margin-right: 0;
          margin-bottom: 20px; } }
      .mainFooter__content .item_logo img {
        width: 100%; }
    .mainFooter__content .item_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .mainFooter__content .item_content h3 {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        margin: 0 0 20px;
        text-transform: uppercase; }
        .mainFooter__content .item_content h3 span {
          display: inline-block;
          font-weight: 800; }
      .mainFooter__content .item_content ul {
        display: flex;
        align-items: center; }
        .mainFooter__content .item_content ul li {
          display: block; }
          .mainFooter__content .item_content ul li a {
            display: block;
            margin-right: 10px; }
            .mainFooter__content .item_content ul li a img {
              width: 100px; }
    .mainFooter__content .item_socialLinks {
      display: flex;
      margin-top: 5px; }
      .mainFooter__content .item_socialLinks li {
        display: inline-block; }
        .mainFooter__content .item_socialLinks li a {
          display: flex;
          margin: 0 10px;
          color: white;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 0;
          font-size: 16px; }
          .mainFooter__content .item_socialLinks li a:hover {
            transform: rotateY(360deg); }
            .mainFooter__content .item_socialLinks li a:hover i {
              color: #ec181e; }
    @media (max-width: 1023px) {
      .mainFooter__content {
        flex-direction: column; } }

.backtoTop {
  display: none;
  position: fixed;
  right: 30px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  z-index: 999;
  z-index: 999;
  cursor: pointer;
  background: #020c2d;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; }
  .backtoTop:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: -1;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out; }
  .backtoTop:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: -2;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out; }
  .backtoTop:hover {
    background: #ec181e; }
    .backtoTop:hover:before {
      background: rgba(2, 12, 45, 0.2); }
    .backtoTop:hover:after {
      opacity: 0;
      background: rgba(2, 12, 45, 0.2);
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5); }
  .backtoTop img {
    float: left; }
  @media (max-width: 991px) {
    .backtoTop {
      right: 10px;
      bottom: 100px; } }

.mainBanner {
  position: relative; }
  @media (max-width: 1023px) {
    .mainBanner {
      margin-top: 11.9rem;
      background: #020c2d; } }
  @media (max-width: 767px) {
    .mainBanner {
      margin-top: 10.9rem;
      min-height: 56.8rem;
      padding: 0; } }
  @media (max-width: 480px) {
    .mainBanner {
      margin-top: 9rem; } }
  @media (max-width: 767px) {
    .mainBanner__img {
      display: none; } }
  .mainBanner__img img {
    width: 100%; }
  .mainBanner__content {
    position: absolute;
    top: 45%;
    left: 15%;
    transform: translateY(-50%);
    width: 52rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center; }
    @media (max-width: 1440px) {
      .mainBanner__content {
        left: 14%;
        top: 55%;
        width: 44.5rem; } }
    @media (max-width: 1365px) {
      .mainBanner__content {
        left: 10%; } }
    @media (max-width: 1279px) {
      .mainBanner__content {
        left: 7%; } }
    @media (max-width: 1023px) {
      .mainBanner__content {
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 0 1.5rem;
        width: 32.5rem; } }
    @media (max-width: 767px) {
      .mainBanner__content {
        left: 50%;
        width: 100%;
        flex-direction: column; } }
    .mainBanner__content h3 {
      font-size: 4rem;
      color: #fecb00;
      text-transform: uppercase;
      margin: 0; }
      .mainBanner__content h3 span {
        display: block;
        color: #ffffff;
        font-size: 5.5rem;
        font-weight: 700; }
        @media (max-width: 1440px) {
          .mainBanner__content h3 span {
            font-size: 4.5rem; } }
        @media (max-width: 1365px) {
          .mainBanner__content h3 span {
            font-size: 3.5rem; } }
        @media (max-width: 1023px) {
          .mainBanner__content h3 span {
            font-size: 2.5rem; } }
        @media (max-width: 767px) {
          .mainBanner__content h3 span {
            font-size: 4.5rem; } }
      @media (max-width: 1440px) {
        .mainBanner__content h3 {
          font-size: 3rem; } }
      @media (max-width: 1365px) {
        .mainBanner__content h3 {
          font-size: 2.5rem; } }
      @media (max-width: 1023px) {
        .mainBanner__content h3 {
          font-size: 2rem; } }
      @media (max-width: 767px) {
        .mainBanner__content h3 {
          font-size: 3rem;
          line-height: 1.5; } }
    .mainBanner__content h1 {
      font-size: 9.2rem;
      font-family: "Pacifico", cursive;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0.01rem;
      margin: 0 0 .5rem;
      background: #f9c802;
      background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
      background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      position: relative; }
      .mainBanner__content h1:before {
        content: attr(data-heading);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-shadow: .3rem 0 0 #04113c;
        z-index: -1;
        color: transparent; }
      @media (max-width: 1440px) {
        .mainBanner__content h1 {
          font-size: 8rem; } }
      @media (max-width: 1365px) {
        .mainBanner__content h1 {
          font-size: 6.5rem; } }
      @media (max-width: 1023px) {
        .mainBanner__content h1 {
          font-size: 5rem; } }
      @media (max-width: 767px) {
        .mainBanner__content h1 {
          font-size: 7rem;
          margin-bottom: 2rem; } }
    .mainBanner__content .banner-btn {
      display: inline-block;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 2.1rem;
      line-height: 1.2;
      letter-spacing: 0.015rem;
      color: #ffffff;
      text-transform: uppercase;
      background-color: #ee1920;
      padding: 1.5rem 2.5rem; }
      @media (max-width: 1023px) {
        .mainBanner__content .banner-btn {
          font-size: 1.8rem; } }
      @media (max-width: 767px) {
        .mainBanner__content .banner-btn {
          font-size: 1.6rem; } }

.aboutus {
  background: transparent url(../img/aboutus/aboutus-bg.png) no-repeat left top/100% auto;
  padding: 8rem 0; }
  @media (max-width: 1279px) {
    .aboutus {
      background-size: auto 100%;
      background-position: center center; } }
  @media (max-width: 1023px) {
    .aboutus {
      padding: 3rem 0; } }
  @media (max-width: 767px) {
    .aboutus {
      background: #020c2d none; } }
  .aboutus__heading {
    display: flex;
    flex-direction: column; }
    @media (max-width: 767px) {
      .aboutus__heading {
        align-items: center; } }
    .aboutus__heading h3 {
      font-size: 3.8rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      line-height: 1.2;
      color: #ffffff;
      letter-spacing: 0.01rem;
      margin: 0; }
    .aboutus__heading h2 {
      font-size: 5.5rem;
      font-family: "Pacifico", cursive;
      font-weight: 200;
      line-height: 1.75;
      margin: 0 0 1rem;
      background: #f9c802;
      background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
      background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      position: relative; }
      .aboutus__heading h2:before {
        content: attr(data-heading);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-shadow: .3rem 0 0 #04113c;
        z-index: -1;
        color: transparent; }
  .aboutus__list ul {
    display: flex;
    flex-direction: column; }
    .aboutus__list ul li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3rem;
      flex-wrap: wrap; }
      .aboutus__list ul li .img {
        display: flex; }
        .aboutus__list ul li .img img {
          width: 100%; }
      .aboutus__list ul li .content {
        display: flex;
        flex-direction: column;
        width: calc(100% - 2.7rem);
        padding-left: 1.5rem; }
        .aboutus__list ul li .content h4 {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 1.9rem;
          line-height: 1.2;
          letter-spacing: 0.01rem;
          color: #ffffff;
          margin: 0 0 1rem; }
        .aboutus__list ul li .content p {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          font-size: 1.6rem;
          line-height: 1.2;
          letter-spacing: 0.01rem;
          color: #ffffff;
          margin: 0; }

.featured {
  background: transparent url(../img/amazingfeature/amazingfeature-bg.png) no-repeat center bottom/50% auto;
  padding: 0; }
  @media (max-width: 1023px) {
    .featured {
      background-image: none;
      padding: 5rem 0 2rem; } }
  .featured__main {
    position: relative;
    min-height: 45rem; }
    @media (max-width: 1023px) {
      .featured__main {
        min-height: auto; } }
    .featured__main .heading {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (max-width: 1365px) {
        .featured__main .heading {
          top: 40%; } }
      @media (max-width: 1279px) {
        .featured__main .heading {
          top: 53%; } }
      @media (max-width: 1023px) {
        .featured__main .heading {
          position: static;
          transform: none;
          margin: 0 0 3rem;
          display: flex;
          align-items: center;
          justify-content: center; } }
      .featured__main .heading h2 {
        display: flex;
        align-items: center;
        font-size: 3.8rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.01rem;
        margin: 0 0 1rem;
        color: #1a0126; }
        @media (max-width: 1439px) {
          .featured__main .heading h2 {
            font-size: 2.8rem; } }
        @media (max-width: 767px) {
          .featured__main .heading h2 {
            font-size: 3.8rem;
            flex-direction: column; } }
        .featured__main .heading h2 span {
          display: inline-block;
          font-size: 5.5rem;
          font-family: "Pacifico", cursive;
          font-weight: 200;
          line-height: 1.75;
          margin-left: 1rem;
          background: #f9c802;
          background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
          background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
          background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
          background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
          background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          position: relative; }
          .featured__main .heading h2 span:before {
            content: attr(data-heading);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            text-shadow: .3rem 0 0 #e5dede;
            z-index: -1;
            color: transparent; }
          @media (max-width: 1439px) {
            .featured__main .heading h2 span {
              font-size: 4.5rem; } }
          @media (max-width: 767px) {
            .featured__main .heading h2 span {
              font-size: 5.5rem;
              display: block; } }
    @media (max-width: 1023px) {
      .featured__main .content {
        display: flex;
        flex-wrap: wrap; } }
    .featured__main .content_details {
      display: flex;
      width: 39rem;
      position: absolute; }
      .featured__main .content_details--first {
        bottom: 3rem;
        left: -4.5rem; }
      .featured__main .content_details--second {
        bottom: 18rem;
        left: .5rem; }
      .featured__main .content_details--third {
        bottom: 31rem;
        left: 13rem; }
      .featured__main .content_details--fourth {
        bottom: 31rem;
        right: 13rem; }
      .featured__main .content_details--fifth {
        bottom: 18rem;
        right: .5rem; }
      .featured__main .content_details--sixth {
        bottom: 3rem;
        right: -4.5rem; }
      .featured__main .content_details--first, .featured__main .content_details--second, .featured__main .content_details--third {
        text-align: right; }
        @media (max-width: 1023px) {
          .featured__main .content_details--first, .featured__main .content_details--second, .featured__main .content_details--third {
            text-align: left; } }
      .featured__main .content_details--first .featuredcard, .featured__main .content_details--second .featuredcard, .featured__main .content_details--third .featuredcard {
        flex-direction: row-reverse; }
        @media (max-width: 1023px) {
          .featured__main .content_details--first .featuredcard, .featured__main .content_details--second .featuredcard, .featured__main .content_details--third .featuredcard {
            flex-direction: row; } }
      .featured__main .content_details--fourth .featuredcard, .featured__main .content_details--fifth .featuredcard, .featured__main .content_details--sixth .featuredcard {
        flex-direction: row; }
      @media (max-width: 1365px) {
        .featured__main .content_details {
          width: 35rem; }
          .featured__main .content_details--first {
            bottom: 3rem;
            left: -2.5rem; }
          .featured__main .content_details--second {
            bottom: 16.5rem;
            left: 2.5rem; }
          .featured__main .content_details--third {
            bottom: 28rem;
            left: 13rem; }
          .featured__main .content_details--fourth {
            bottom: 28rem;
            right: 13rem; }
          .featured__main .content_details--fifth {
            bottom: 16.5rem;
            right: 2.5rem; }
          .featured__main .content_details--sixth {
            bottom: 3rem;
            right: -2.5rem; } }
      @media (max-width: 1279px) {
        .featured__main .content_details {
          width: 30rem; }
          .featured__main .content_details--first {
            bottom: 2rem;
            left: -3rem; }
          .featured__main .content_details--second {
            bottom: 13.5rem;
            left: .5rem; }
          .featured__main .content_details--third {
            bottom: 23rem;
            left: 10rem; }
          .featured__main .content_details--fourth {
            bottom: 23rem;
            right: 10rem; }
          .featured__main .content_details--fifth {
            bottom: 13.5rem;
            right: .5rem; }
          .featured__main .content_details--sixth {
            bottom: 2rem;
            right: -3rem; } }
      @media (max-width: 1023px) {
        .featured__main .content_details {
          width: 50%;
          position: static;
          margin-bottom: 3rem; } }
      @media (max-width: 767px) {
        .featured__main .content_details {
          width: 100%; } }
      .featured__main .content_details .featuredcard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap; }
        .featured__main .content_details .featuredcard__img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 8.9rem;
          height: 8.9rem;
          border-radius: 50%;
          background-color: #020c2d;
          border: 0.5rem solid #ffffff; }
          .featured__main .content_details .featuredcard__img img {
            width: auto; }
        .featured__main .content_details .featuredcard__details {
          display: flex;
          flex-direction: column;
          width: calc(100% - 10rem); }
          .featured__main .content_details .featuredcard__details h4 {
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.2;
            letter-spacing: 0.01rem;
            color: #000000;
            margin: 0 0 .5rem; }
            @media (max-width: 1440px) {
              .featured__main .content_details .featuredcard__details h4 {
                font-size: 1.7rem; } }
          .featured__main .content_details .featuredcard__details p {
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 1.2;
            letter-spacing: 0.01rem;
            color: #111111;
            margin: 0; }
            @media (max-width: 1440px) {
              .featured__main .content_details .featuredcard__details p {
                font-size: 1.4rem; } }

.howitworks__left {
  display: flex;
  align-items: center;
  justify-content: center; }
  .howitworks__left img {
    width: 90%; }
    @media (max-width: 1023px) {
      .howitworks__left img {
        width: 100%; } }

.howitworks__right {
  text-align: center;
  padding-left: 5%; }
  @media (max-width: 1023px) {
    .howitworks__right {
      padding-left: 0; } }
  .howitworks__right h2 {
    font-size: 5rem;
    font-family: "Pacifico", cursive;
    font-weight: 700;
    line-height: 1.2;
    color: #232323;
    margin: 0 0 3.5rem; }
    @media (max-width: 767px) {
      .howitworks__right h2 {
        font-size: 3rem; } }
  .howitworks__right p {
    font-size: 1.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    color: #232323;
    margin: 0 0 7rem; }
    @media (max-width: 1279px) {
      .howitworks__right p br {
        display: none; } }
    @media (max-width: 767px) {
      .howitworks__right p {
        font-size: 1.5rem; } }
  .howitworks__right--boxes {
    display: flex;
    flex-wrap: wrap; }
    .howitworks__right--boxes-box {
      width: calc((100% / 3) - 2rem);
      position: relative;
      padding: 6rem 2rem 4rem;
      margin-right: 3rem;
      text-align: center;
      box-shadow: 0 0 2.7rem 0.8rem rgba(7, 36, 184, 0.13);
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .howitworks__right--boxes-box:nth-child(3n) {
        margin-right: 0; }
      @media (max-width: 767px) {
        .howitworks__right--boxes-box {
          width: 100%;
          margin-right: 0; }
          .howitworks__right--boxes-box:not(:last-child) {
            margin-bottom: 8rem; } }
      .howitworks__right--boxes-box figure {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 9.2rem;
        height: 9.2rem;
        border-radius: 50%;
        background-color: #020c2d;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .howitworks__right--boxes-box figure img {
          width: auto; }
      .howitworks__right--boxes-box h3 {
        font-size: 2.1rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        line-height: 1.2;
        color: #232323;
        margin: 0 0 2rem; }
      .howitworks__right--boxes-box p {
        font-size: 1.5rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        line-height: 1.5;
        color: #232323;
        margin: 0; }

.playnows {
  background-color: #e9141a; }
  .playnows__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .playnows__content h3 {
      font-size: 3rem;
      font-weight: 600;
      color: #ffffff;
      margin: 0 0 3rem;
      text-transform: uppercase; }
      .playnows__content h3 span {
        display: inline-block;
        font-weight: 800; }
    .playnows__content ul {
      display: flex;
      align-items: center; }
      .playnows__content ul li {
        display: block; }
        .playnows__content ul li a {
          display: block;
          margin-right: 2rem; }
          .playnows__content ul li a img {
            width: auto; }

.testimonial__heading h3 {
  font-size: 3.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #1a0126;
  letter-spacing: 0.01rem;
  margin: 0; }

.testimonial__heading h2 {
  font-size: 6.5rem;
  font-family: "Pacifico", cursive;
  font-weight: 200;
  line-height: 1.75;
  margin: 0;
  background: #f9c802;
  background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
  background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
  background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
  background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
  background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  position: relative; }
  .testimonial__heading h2:before {
    content: attr(data-heading);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    text-shadow: .3rem 0 0 #04113c;
    z-index: -1;
    color: transparent; }

.testimonial__slider.slick-dotted.slick-slider {
  margin-bottom: 8rem; }

.testimonial__slider .slick-track {
  padding: 4rem 0; }

.testimonial__slider .item {
  background-color: #ffffff;
  padding: 4rem;
  margin: 0 2rem;
  border-radius: .5rem;
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center; }
  .testimonial__slider .item .discription {
    color: #111111;
    line-height: 1.75;
    margin: 0 0 2rem; }
  .testimonial__slider .item .starRating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 2rem; }
    .testimonial__slider .item .starRating li {
      display: inline-block; }
      .testimonial__slider .item .starRating li a {
        display: block;
        margin: 0 .5rem;
        font-size: 1.4rem; }
        .testimonial__slider .item .starRating li a.selected {
          color: #ffb100; }
        .testimonial__slider .item .starRating li a.unselected {
          color: #d0d0d0; }
  .testimonial__slider .item .user {
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: italic;
    line-height: 1.2;
    color: #1a0126;
    letter-spacing: 0.01rem;
    margin: 0 0 1rem; }

.testimonial__slider .slick-center .item {
  transform: scale(1.05); }

.testimonial__slider .slick-dots {
  bottom: 0;
  display: flex !important;
  align-items: center;
  justify-content: center; }
  .testimonial__slider .slick-dots li {
    background-color: #b3b3b3;
    opacity: 1; }
    .testimonial__slider .slick-dots li.slick-active {
      background-color: #ececec;
      opacity: 1; }

.testimonial__video > .container {
  position: relative; }

.testimonial__video figure {
  position: relative;
  z-index: 0;
  border-radius: 2.5rem;
  overflow: hidden;
  margin: 0; }
  @media (max-width: 767px) {
    .testimonial__video figure {
      min-height: 24rem; } }
  .testimonial__video figure:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65); }
  .testimonial__video figure img {
    width: 100%; }
    @media (max-width: 767px) {
      .testimonial__video figure img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        max-width: none;
        max-height: 100%; } }

.testimonial__video .playButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 0; }
  .testimonial__video .playButton img {
    width: auto;
    margin-bottom: 2rem; }
  .testimonial__video .playButton span {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1.2;
    letter-spacing: 0.01rem;
    color: #ffffff; }
    @media (max-width: 479px) {
      .testimonial__video .playButton span {
        font-size: 1.6rem; } }
  @media (max-width: 767px) {
    .testimonial__video .playButton {
      left: 50%; }
      .testimonial__video .playButton img {
        width: 7.5rem; } }

.contactus {
  background: #dbdbf0 url(../img/contactus/form-bg.png) no-repeat right 7.5% top 17.5%;
  margin-top: 7.8rem;
  padding: 12rem 0; }
  @media (max-width: 1023px) {
    .contactus {
      margin-top: 11.9rem;
      padding: 5rem 0; } }
  @media (max-width: 767px) {
    .contactus {
      margin-top: 10.9rem;
      padding: 3rem 0; } }
  @media (max-width: 480px) {
    .contactus {
      margin-top: 9rem; } }
  .contactus__details {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 1023px) {
      .contactus__details {
        padding-bottom: 5rem; } }
    .contactus__details h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 3rem;
      line-height: 1.2;
      color: #232323;
      margin: 0 0 3rem; }
      @media (max-width: 767px) {
        .contactus__details h3 {
          font-size: 3rem;
          text-align: center; } }
      .contactus__details h3 span {
        font-weight: 800; }
    .contactus__details ul {
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 479px) {
        .contactus__details ul {
          flex-direction: column; } }
      .contactus__details ul li {
        width: 50%;
        display: flex;
        flex-direction: column; }
        @media (max-width: 479px) {
          .contactus__details ul li {
            width: 100%; }
            .contactus__details ul li:nth-last-child(2) {
              padding-top: 2rem;
              border-top: 0.1rem solid rgba(0, 0, 0, 0.18);
              margin-top: 2rem; } }
        .contactus__details ul li:last-child {
          width: 100%;
          padding-top: 2rem;
          border-top: 0.1rem solid rgba(0, 0, 0, 0.18);
          margin-top: 2rem; }
        .contactus__details ul li h5 {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 3.2rem;
          letter-spacing: -0.005rem;
          color: #000000;
          margin: 0 0 1rem;
          position: relative;
          padding-left: 4rem; }
          .contactus__details ul li h5 img {
            width: auto;
            position: absolute;
            top: 1.6rem;
            left: 0;
            transform: translateY(-50%); }
        .contactus__details ul li a {
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.2;
          color: #020c2d;
          padding-left: 4rem; }
          .contactus__details ul li a:hover {
            color: #000000; }
        .contactus__details ul li p {
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.75;
          color: #020c2d;
          padding-left: 4rem;
          margin: 0; }
  .contactus__form {
    background-color: #e8eaec;
    box-shadow: 0 0 1.5rem 0.2rem rgba(0, 0, 0, 0.24);
    padding: 3rem;
    border-radius: 1rem; }
    @media (max-width: 479px) {
      .contactus__form {
        padding: 1.5rem; } }
    .contactus__form .form-group {
      margin: 0 0 3rem; }
      .contactus__form .form-group input,
      .contactus__form .form-group select,
      .contactus__form .form-group textarea {
        padding: 1.2rem 2rem;
        resize: none;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 1.2;
        color: #3f3e3f;
        border: .1rem solid #ffffff;
        border-radius: 0.5rem;
        background: white;
        background: -moz-linear-gradient(83deg, white 0%, #f2f2f4 100%);
        background: -webkit-linear-gradient(83deg, white 0%, #f2f2f4 100%);
        background: linear-gradient(83deg, white 0%, #f2f2f4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f2f2f4", GradientType=1); }
        .contactus__form .form-group input::-webkit-input-placeholder,
        .contactus__form .form-group select::-webkit-input-placeholder,
        .contactus__form .form-group textarea::-webkit-input-placeholder {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 1.2;
          color: #3f3e3f; }
        .contactus__form .form-group input::-moz-placeholder,
        .contactus__form .form-group select::-moz-placeholder,
        .contactus__form .form-group textarea::-moz-placeholder {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 1.2;
          color: #3f3e3f; }
        .contactus__form .form-group input:-ms-input-placeholder,
        .contactus__form .form-group select:-ms-input-placeholder,
        .contactus__form .form-group textarea:-ms-input-placeholder {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 1.2;
          color: #3f3e3f; }
        .contactus__form .form-group input:-moz-placeholder,
        .contactus__form .form-group select:-moz-placeholder,
        .contactus__form .form-group textarea:-moz-placeholder {
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 1.2;
          color: #3f3e3f; }
        @media (max-width: 479px) {
          .contactus__form .form-group input,
          .contactus__form .form-group select,
          .contactus__form .form-group textarea {
            padding: 1rem 1rem; } }
    .contactus__form button.btn-primary {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.2;
      color: #343434;
      border-radius: 1.5rem;
      overflow: hidden;
      padding: 1.5rem 5rem;
      transition: all 0.5s ease;
      width: 100%;
      background: #fec42d;
      background: -moz-linear-gradient(180deg, #fec42d 0%, #ff9c26 100%);
      background: -webkit-linear-gradient(180deg, #fec42d 0%, #ff9c26 100%);
      background: linear-gradient(180deg, #fec42d 0%, #ff9c26 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fec42d", endColorstr="#ff9c26", GradientType=1); }
      .contactus__form button.btn-primary:hover {
        background: #020c2d;
        color: #ffffff; }
      .contactus__form button.btn-primary:before, .contactus__form button.btn-primary:after {
        content: none; }

.aboutusPage {
  margin-top: 7.8rem;
  display: flex;
  align-items: center;
  padding: 8rem 0; }
  @media (max-width: 1023px) {
    .aboutusPage {
      margin-top: 11.9rem; } }
  @media (max-width: 767px) {
    .aboutusPage {
      margin-top: 10.9rem;
      padding: 3rem 0; } }
  @media (max-width: 480px) {
    .aboutusPage {
      margin-top: 9rem; } }
  .aboutusPage h2 {
    font-size: 6.5rem;
    font-family: "Pacifico", cursive;
    font-weight: 700;
    color: #020c2d;
    margin: 0 0 1.5rem;
    background: #f9c802;
    background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
    background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    position: relative; }
    .aboutusPage h2:before {
      content: attr(data-heading);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      text-shadow: 0.3rem 0 0 rgba(5, 22, 78, 0.6);
      z-index: -1;
      color: transparent; }
    @media (max-width: 767px) {
      .aboutusPage h2 {
        font-size: 4.5rem; } }
  .aboutusPage p {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #232323;
    letter-spacing: 0.01rem;
    margin: 0; }

.howitworks {
  padding: 8rem 0 0;
  background-color: #020c2d;
  position: relative;
  z-index: 0; }
  .howitworks:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7.5rem;
    background-color: #e9141a;
    z-index: -1; }
    @media (max-width: 1023px) {
      .howitworks:after {
        display: none; } }
  @media (max-width: 1279px) {
    .howitworks {
      background-size: auto 100%;
      background-position: center center; } }
  @media (max-width: 1023px) {
    .howitworks {
      padding: 5rem 0; } }
  @media (max-width: 767px) {
    .howitworks {
      background: #020c2d none;
      padding: 3rem 0; } }
  .howitworks__heading {
    display: flex;
    flex-direction: column;
    margin: 0 0 5rem; }
    @media (max-width: 767px) {
      .howitworks__heading {
        align-items: center; } }
    .howitworks__heading h2 {
      font-size: 5.5rem;
      font-family: "Pacifico", cursive;
      font-weight: 200;
      line-height: 1.2;
      margin: 0;
      background: #f9c802;
      background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
      background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      position: relative; }
      .howitworks__heading h2:before {
        content: attr(data-heading);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-shadow: .3rem 0 0 #04113c;
        z-index: -1;
        color: transparent; }
      @media (max-width: 767px) {
        .howitworks__heading h2 {
          font-size: 3.5rem; } }
  .howitworks__list {
    height: 100%;
    display: flex;
    align-items: center; }
    .howitworks__list ul {
      display: flex;
      flex-direction: column; }
      .howitworks__list ul li {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap; }
        .howitworks__list ul li:not(:last-child) {
          margin-bottom: 5rem; }
        .howitworks__list ul li .img {
          display: flex; }
          .howitworks__list ul li .img img {
            width: 100%; }
        .howitworks__list ul li .content {
          display: flex;
          flex-direction: column;
          width: calc(100% - 3.2rem); }
          .howitworks__list ul li .content h4 {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 1.9rem;
            line-height: 1.2;
            letter-spacing: 0.01rem;
            color: #ffffff;
            margin: 0 0 1rem; }
          .howitworks__list ul li .content p {
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 1.2;
            letter-spacing: 0.01rem;
            color: #ffffff;
            margin: 0; }
    .howitworks__list--left ul li {
      flex-direction: row-reverse; }
      @media (max-width: 1023px) {
        .howitworks__list--left ul li {
          flex-direction: row; } }
      @media (max-width: 767px) {
        .howitworks__list--left ul li:last-child {
          margin-bottom: 5rem; } }
      .howitworks__list--left ul li .content {
        text-align: right;
        padding-right: 1.5rem; }
        @media (max-width: 1023px) {
          .howitworks__list--left ul li .content {
            text-align: left;
            padding-right: 0;
            padding-left: 1.5rem; } }
    .howitworks__list--right ul li {
      flex-direction: row; }
      .howitworks__list--right ul li .content {
        text-align: left;
        padding-left: 1.5rem; }

.whyplaybharatludo {
  background: transparent url(../img/aboutus/whyplaybharatludo-bg.jpg) no-repeat center center/cover; }
  .whyplaybharatludo h2 {
    font-size: 7rem;
    font-family: "Pacifico", cursive;
    font-weight: 700;
    line-height: 1.75;
    color: #020c2d;
    margin: 0 0 1.5rem;
    background: #f9c802;
    background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
    background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    position: relative; }
    .whyplaybharatludo h2:before {
      content: attr(data-heading);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      text-shadow: 0.3rem 0 0 rgba(5, 22, 78, 0.6);
      z-index: -1;
      color: transparent; }
    @media (max-width: 767px) {
      .whyplaybharatludo h2 {
        font-size: 5rem; } }
  .whyplaybharatludo p {
    font-size: 1.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #ffffff;
    letter-spacing: 0.01rem;
    margin: 0; }

.privacyPage {
  display: flex;
  margin-top: 7.8rem;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1023px) {
    .privacyPage {
      margin-top: 11.9rem; } }
  @media (max-width: 767px) {
    .privacyPage {
      margin-top: 10.9rem;
      padding: 3rem 0; } }
  @media (max-width: 480px) {
    .privacyPage {
      margin-top: 9rem; } }
  .privacyPage h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 2.7rem;
    line-height: 1.5;
    letter-spacing: -0.005rem;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0; }
    @media (max-width: 479px) {
      .privacyPage h2 {
        font-size: 1.8rem; } }

.privacy {
  font-size: 16px;
  background-color: #dbdbf0; }
  .privacy ol {
    padding-left: 17px; }
    .privacy ol li {
      list-style-position: inside; }
  .privacy__policy:not(:last-child) {
    margin: 0 0 5rem; }
  .privacy__policy h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 2.7rem;
    line-height: 1.5;
    letter-spacing: -0.005rem;
    color: #020c2d;
    margin: 0 0 1.5rem; }
    @media (max-width: 479px) {
      .privacy__policy h5 {
        font-size: 1.8rem; } }
  .privacy__policy p {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.75;
    letter-spacing: -0.005rem;
    color: #000000;
    margin: 0; }
    .privacy__policy p:not(:last-child) {
      margin-bottom: 2.5rem; }
    @media (max-width: 479px) {
      .privacy__policy p {
        font-size: 1.4rem; } }
  .privacy__policy ul li {
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.75;
    letter-spacing: -0.005rem;
    color: #000000;
    position: relative;
    padding-left: 2rem; }
    .privacy__policy ul li:not(:last-child) {
      margin-bottom: 2.5rem; }
    .privacy__policy ul li:after {
      content: "";
      position: absolute;
      top: 1.4rem;
      left: 0;
      transform: translateY(-50%);
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      background-color: #020c2d; }
    @media (max-width: 479px) {
      .privacy__policy ul li {
        font-size: 1.4rem; } }

.featuresPage {
  margin-top: 7.8rem;
  display: flex;
  align-items: center;
  padding: 8rem 0; }
  @media (max-width: 1023px) {
    .featuresPage {
      margin-top: 11.9rem; } }
  @media (max-width: 767px) {
    .featuresPage {
      margin-top: 10.9rem;
      padding: 3rem 0; } }
  @media (max-width: 480px) {
    .featuresPage {
      margin-top: 9rem; } }
  .featuresPage h2 {
    font-size: 6.5rem;
    font-family: "Pacifico", cursive;
    font-weight: 700;
    color: #020c2d;
    margin: 0 0 1.5rem;
    background: #f9c802;
    background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
    background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
    background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    position: relative; }
    .featuresPage h2:before {
      content: attr(data-heading);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      text-shadow: 0.3rem 0 0 rgba(5, 22, 78, 0.6);
      z-index: -1;
      color: transparent; }
    @media (max-width: 767px) {
      .featuresPage h2 {
        font-size: 4.5rem; } }
  .featuresPage p {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #232323;
    letter-spacing: 0.01rem;
    margin: 0; }

.features {
  padding: 0;
  background: #ffffff url(../img/features/features-bg.png) no-repeat left -3rem center/60% auto;
  position: relative;
  z-index: 0; }
  @media (max-width: 767px) {
    .features {
      background-image: none; } }
  .features__list {
    height: 100%;
    min-height: 56rem;
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .features__list {
        min-height: auto; } }
    .features__list ul {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%; }
      .features__list ul .featuresbox {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 4rem;
        position: absolute; }
        @media (max-width: 767px) {
          .features__list ul .featuresbox {
            position: relative;
            width: 100%;
            margin-bottom: 3rem; } }
        .features__list ul .featuresbox__img {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 7rem;
          height: 7rem;
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: -0.5rem 0.75rem 0.9rem 0.2rem rgba(4, 36, 198, 0.11);
          display: flex;
          align-items: center;
          justify-content: center; }
          .features__list ul .featuresbox__img img {
            width: auto; }
        .features__list ul .featuresbox__content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 7rem;
          padding-left: 8.5rem;
          padding-right: 3rem; }
          @media (max-width: 479px) {
            .features__list ul .featuresbox__content {
              padding-left: 8rem;
              padding-right: 1rem; } }
          .features__list ul .featuresbox__content h4 {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.2;
            letter-spacing: 0.01rem;
            color: #000000;
            margin: 0; }
            @media (max-width: 479px) {
              .features__list ul .featuresbox__content h4 {
                font-size: 1.4rem; } }
          .features__list ul .featuresbox__content p {
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 1.2;
            letter-spacing: 0.01rem;
            color: #232323;
            margin: 0; }
            @media (max-width: 479px) {
              .features__list ul .featuresbox__content p {
                font-size: 1.2rem; } }
        .features__list ul .featuresbox--first {
          background-color: #01bdbe;
          top: 1rem;
          left: 0; }
          @media (max-width: 1023px) {
            .features__list ul .featuresbox--first {
              left: 0; } }
          @media (max-width: 767px) {
            .features__list ul .featuresbox--first {
              top: 0; } }
        .features__list ul .featuresbox--second {
          background-color: #a2cc38;
          top: 10rem;
          left: 8rem; }
          @media (max-width: 1023px) {
            .features__list ul .featuresbox--second {
              left: 0; } }
          @media (max-width: 767px) {
            .features__list ul .featuresbox--second {
              top: 0; } }
        .features__list ul .featuresbox--third {
          background-color: #f8615a;
          top: 19rem;
          left: 11rem; }
          @media (max-width: 1023px) {
            .features__list ul .featuresbox--third {
              left: 0; } }
          @media (max-width: 767px) {
            .features__list ul .featuresbox--third {
              top: 0; } }
        .features__list ul .featuresbox--fourth {
          background-color: #e99e26;
          bottom: 21rem;
          left: 11rem; }
          @media (max-width: 1023px) {
            .features__list ul .featuresbox--fourth {
              left: 0; } }
          @media (max-width: 767px) {
            .features__list ul .featuresbox--fourth {
              bottom: 0; } }
        .features__list ul .featuresbox--fifth {
          background-color: #71b1ed;
          bottom: 12rem;
          left: 8rem; }
          @media (max-width: 1023px) {
            .features__list ul .featuresbox--fifth {
              left: 0; } }
          @media (max-width: 767px) {
            .features__list ul .featuresbox--fifth {
              bottom: 0; } }
        .features__list ul .featuresbox--sixth {
          background-color: #9568b5;
          bottom: 3rem;
          left: 0; }
          @media (max-width: 1023px) {
            .features__list ul .featuresbox--sixth {
              left: 0; } }
          @media (max-width: 767px) {
            .features__list ul .featuresbox--sixth {
              bottom: 0; } }

.reviews {
  padding-top: 15rem;
  position: relative;
  z-index: 0; }
  .reviews::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: rgba(2, 12, 45, 0.13);
    z-index: -1; }
    @media (max-width: 767px) {
      .reviews::after {
        height: 30%; } }
  .reviews__img img {
    width: auto; }
  .reviews__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1023px) {
      .reviews__content {
        margin-bottom: 6rem;
        text-align: center; } }
    .reviews__content h3 {
      font-size: 4rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      line-height: 1.75;
      margin: 0 0 1rem; }
    .reviews__content a {
      display: inline-block; }
      .reviews__content a img {
        width: auto; }
  .reviews__slider {
    margin-top: 8rem; }
    .reviews__slider * {
      outline: none !important; }
    .reviews__slider .slick-track {
      padding: 4rem 0 6rem; }
    .reviews__slider .item {
      background-color: #ffffff;
      padding: 5rem 3rem;
      margin-right: 2rem;
      border-radius: .5rem;
      box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center; }
      @media (max-width: 767px) {
        .reviews__slider .item {
          margin: 0 1.5rem; } }
      .reviews__slider .item .propic {
        width: 10rem;
        height: 10rem;
        position: relative; }
        .reviews__slider .item .propic::after {
          content: "";
          width: 9.5rem;
          height: 7.5rem;
          background-image: url(../img/reviews/reviewsslider-propic-bg.jpg);
          position: absolute;
          top: -3rem;
          left: -5rem; }
        .reviews__slider .item .propic img {
          width: 10rem;
          height: 10rem;
          border-radius: 50%; }
      .reviews__slider .item .user {
        font-size: 1.5rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        line-height: 1.2;
        color: #252e3b;
        letter-spacing: 0.01rem;
        margin: 0 0 1rem; }
      .reviews__slider .item .discription {
        color: #111111;
        font-size: 1.6rem;
        line-height: 1.75;
        margin: 0 0 2rem; }
    .reviews__slider .slick-dots {
      bottom: 0;
      display: flex !important;
      align-items: center;
      justify-content: center; }
      .reviews__slider .slick-dots li {
        background-color: #a2a2a2;
        opacity: 1; }
        .reviews__slider .slick-dots li.slick-active {
          background-color: #3a6dee;
          opacity: 1; }

.download {
  padding-top: 20rem;
  position: relative;
  z-index: 0;
  background: #ffffff url(../img/download/download-bg.png) no-repeat left -27.5rem center/85%; }
  .download::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    height: 100%;
    background-color: rgba(2, 12, 45, 0.13);
    z-index: -1; }
  @media (max-width: 1023px) {
    .download {
      padding-top: 10rem;
      background-position: left 0 top 10rem;
      background-size: 100%; }
      .download::after {
        display: none; } }
  .download__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1023px) {
      .download__content {
        margin-bottom: 6rem;
        text-align: center; } }
    .download__content h3 {
      font-size: 5.5rem;
      font-family: "Pacifico", cursive;
      font-weight: 200;
      line-height: 1.75;
      margin: 0 0 1rem;
      background: #f9c802;
      background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
      background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
      background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      position: relative; }
      .download__content h3:before {
        content: attr(data-heading);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-shadow: .3rem 0 0 #04113c;
        z-index: -1;
        color: transparent; }
    .download__content p {
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 1.75;
      color: #232323;
      margin: 0 0 3rem; }
      @media (max-width: 1440px) {
        .download__content p {
          font-size: 1.4rem; } }
    .download__content ul {
      display: flex;
      align-items: center; }
      @media (max-width: 1023px) {
        .download__content ul {
          justify-content: center; } }
      .download__content ul li {
        display: block; }
        .download__content ul li a {
          display: block;
          margin-right: 1rem; }
          .download__content ul li a img {
            width: 12.5rem; }
  @media (max-width: 1023px) {
    .download__img {
      text-align: center; } }
  .download__img img {
    width: auto; }
