.modal.fade {
	padding: 60px 0;
	background: rgba(0, 0, 0, 0.60);
	
	.modal-dialog {
		width: 90%;
		margin: 0 auto;
		max-width: 775px;
		&.md { max-width: 600px; }

		.modelClose {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 10;
			line-height: 1px;
			
			img {
				width: 16px;
				opacity: 0.6;
			}
			
			&:hover {
				img { opacity: 1; }
			}
		}

		.modal-content {
			border:none;
			@include css3BorderRadius(0);
		}

		@media (max-width: $mobileBig) {
			max-width: 450px;
		}
	}
}

.modalContainer {
	position: relative;
	display: flex;
	align-items: stretch;
	
	.brand-logo {
		background: $primary_color;
		width: 275px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.circle {
			width: 272px;
			height: 272px;
			display: flex;
			border-radius: 50%;
			background: white;
			margin-right: -55px;
			align-items: center;
			justify-content: center;
			img { width: 161px; }
		}
		.element--lists, h4 { display: none; }

		@media (max-width: $mobileBig) {
			display: none;
		}
	}

	.contentBox {
		position: relative;
		width: 500px;
		padding: 40px;

		&.full-width { width: 100%; padding: 30px; }

		.btnVerify {
			text-align: center;
			margin-bottom: 15px;

			a {
				display: inline-block;
				font-family: $secondary_font;
				font-size: 16px;
				color: #212121;
				padding: 20px;
				line-height: .3;

				&:hover {
					background: #f1f1f1;
				}
			}
		}
		
		@media (max-width: $mobileBig) {
			width: 100%;
			padding: 30px;
		}
	}

	.checkIcon {
		padding: 30px 0;
		text-align: center;

		i {
			font-size: 75px;
			color: #6ac259;
		}
	}
	
	.form-group {
		position:relative;
		margin-bottom:25px;
		
		.form-control {
			font-family: $secondary_font;
			font-size:15px;
			color:#000;
			padding:11px 12px;
			border-color:#ccc;
			
			@include css3BoxShadow('0 1px 4px 0 rgba(0, 0, 0, 0.1)');
			
			&::-webkit-input-placeholder {
				color:#333;
			}
			&::-moz-placeholder {
				color:#333;
			}
			&:-ms-input-placeholder {
				color:#333;
			}
			&:-moz-placeholder {
				color:#333;
			}

			&:focus {
				border-color: #676767;
			}
		}
		
		.showPassword, .field-icon {
			position:absolute;
			top:0;
			right:15px;
			font-size:13px;
			color:#777;
			line-height:45px;
			text-transform:uppercase;
			
			&:hover {
				color:#000;
			}
		}
		
		.forgotPass {
			color:#222;
			
			&:hover {
				color:$primary_color;
			}
		}
	}
}

.popupStyle2 {
	width: 100%;
	padding: 40px;
}

.typeOfAddr {
	display: flex;

	&__list {
		margin-right: 15px;
	}
}

.orderdetailsPopup {
	// padding: 0;

	.productInfo {
		display: flex;
		padding: 15px 0;
		align-items: center;
		margin-bottom: 20px;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;

		figure {
			width: 25%;
			margin: 0;
		}
		.txtBox {
			width: 45%;
			padding-left: 10px;
		}
		.totals {
			width: 40%;
		}
	}
}

.trackorderPopup {
	padding: 0;

	&__shippingDetails {
		display: flex;
		padding: 10px 15px;
		border: 1px solid #ddd;
		justify-content: space-between;

		p {
			margin-bottom: 0;
		}
		span {
			font-size: 15px;
			color: $secondary_color;
		}
	}

	&__trackProgress {
		padding: 40px 0;

		ul {
			position: relative;
			z-index: 1;
			display: flex;
			justify-content: space-between;

			&:before {
				content: '';
				position: absolute;
				top: 80px;
				left: 10%;
				width: 80%;
				height: 4px;
				background: #f1f1f1;
			}
			&:after {
				content: '';
				position: absolute;
				top: 80px;
				left: 10%;
				width: 0;
				height: 4px;
				background: $secondary_color;
			}

			&.step1 { &:after { width: 0; } }
			&.step2 { &:after { width: 26.666%; } }
			&.step3 { &:after { width: 53.333%; } }
			&.step4 { &:after { width: 80%; } }

			li {
				position: relative;
				z-index: 1;
				display: block;
				width: 20%;

				&:before {
					content: '';
					position: absolute;
					top: 72px;
					left: 50%;
					width: 20px;
					height: 20px;
					margin-left: -10px;
					background: #cdcdcd;
					@include css3BorderRadius(50%);
				}
				&:after {
					display: none;
					content: '';
					position: absolute;
					top: 72px;
					left: 50%;
					width: 20px;
					height: 20px;
					margin-left: -10px;
					background: image('svg/tick-white.svg') center no-repeat $secondary_color;
					background-size: 9px;
					@include css3BorderRadius(50%);
				}

				// .icon {
				// 	display: block;
				// 	margin: auto;
				// 	width: 50px;
				// 	height: 50px;
				// 	margin-bottom: 50px;
				// 	background: image('track_order_sprite.png') no-repeat;

				// 	&--confirm 			{ background-position-y: 0; }
				// 	&--processing 		{ background-position-y: -50px; }
				// 	&--dispatched 		{ background-position-y: -100px; }
				// 	&--delivered 		{ background-position-y: -150px; }
				// }

				p {
					margin: 0;
					font-size: 15px;
					line-height: 18px;
					text-align: center;
				}

				&.active {
					&:after {
						display: block;
					}
					.icon {
						background-position-x: -50px;
					}
				}
			}
		}
	}
}

.writeReviewPopoup {
	padding: 30px;

	.bg-primary {
		margin: -30px -30px 10px -30px;
		padding: 20px 30px;
	}

	.orderId {
		font-size: 13px;
		color: #666666;
		text-align: right;
	}

	.productInfo {
		display: flex;
		padding: 0 0 15px;
		align-items: center;
		margin-bottom: 20px;
		border-bottom: 1px solid #ddd;

		figure {
			width: 25%;
			margin: 0;
		}
		.txtBox {
			width: 75%;
			padding-left: 10px;
		}
	}

	.yourRating {
		display: flex;
		align-items: center;
		
		.label {
			margin: 0;
			font-size: 14px;
			margin-right: 10px;
		}
	}

	.recommendProduct {
		display: flex;
		flex-direction: column;

		span {
			margin-bottom: 10px;
		}
	}

	.custom-form {
		margin: 0 auto;
		width: 90%;
		
		textarea.form-control {
			height: 60px;
		}
	}
}