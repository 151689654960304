.header {
	$self: &;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	transition: all 0.25s ease-out;
	background-color: #05164e;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: height 0.25s ease-out;

		position: relative;

		@media (max-width: $desktopSmall - 1) {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		@media (max-width: $mobileBig - 1) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	&__logo {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
		transition: all 0.25s ease-out;

		display: flex;
		align-items: flex-end;
		justify-content: center;

		&:after {
			content: "";
			position: absolute;
			top: -110%;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;

			width: 250px;
			height: 250px;
			background-color: #05164e;
			border-radius: 50%;

			@media (max-width: $desktopSmall - 1) {
				display: none;
			}
		}

		@media (max-width: $desktopSmall - 1) {
			width: 104px;
			position: static;
			transform: none;
		}

		@media (max-width: $desktopSmall - 1) {
			width: 85px;
		}

		@media (max-width: $mobile) {
			width: 65px;
		}

		a {
			display: block;
			width: 100px;
			margin-top: 15px;
			
			@media (max-width: $desktopSmall - 1) {
				width: 100%;
				margin-top: 0;
			}

			img {
				width: 100%;
			}
		}
	}

	&__links {
		display: flex;
		width: 100%;

		@media (max-width: $desktopSmall - 1) {
			position: absolute;
			top: 100%;
			right: 0;
			width: 100%;
			background-color: #05164e;
			transform-origin: 0 0 0;
			opacity: 0;
			visibility: hidden;
			transform: scaleY(0);

			&.open {
				opacity: 1;
				visibility: visible;
				transform: scaleY(1);
			}
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;
			white-space: nowrap;
			width: 100%;
			transition: all .25s cubic-bezier(.61, .03, .43, .9);

			@media (max-width: $desktopSmall - 1) {
				flex-direction: column;
				flex-wrap: wrap;
				margin-bottom: 0;
			}

			> li {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 30px 0;
				min-width: 112.5px;

				&.space {
					width: 154px;

					@media (max-width: $desktop_xl) {
						width: 134px;
					}

					@media (max-width: $desktopSmall - 1) {
						display: none;
					}
				}

				@media (max-width: $desktopSmall - 1) {
					flex-wrap: wrap;
					width: 100%;
					padding: 0;
					min-width: auto;
					border-top: 1px solid rgba($white_color, 0.5);
					
					&:last-child {
						border-bottom: 1px solid rgba($white_color, 0.5);
					}
				}

				>a {
					font-family: $primary_font;
					font-weight: 500;
					color: $mainMenu_Color;
					letter-spacing: 0.05px;
					position: relative;
					font-size: 15px;
					line-height: 1.2;
					text-transform: uppercase;

					@media (max-width: $desktopSmall - 1) {
						padding: 25px;
						width: 100%;
					}

					&:hover {
						color: $white_color;
					}
				}

				&.login-btn {

					a {
						background-color: $primary_color;
						border: 2px solid $primary_color;
						padding: 7px 20px;
						color: $white_color;
						border-radius: 3px;

						@media (max-width: $desktopSmall - 1) {
							padding: 15px;
							border-radius: 0;
						}

						&:hover {
							color: $primary_color;
							background-color: $white_color;
						}
					}
				}
			}
		}
	}

	.menuHandle {
		display: none;

		@media (max-width: $tablet) {
			display: block;
			position: relative;
			width: 33px;
			height: 26px;
		}

		a.menuBtn {
			position: absolute;
			top: 0;
			left: 0;
			width: 33px;
			height: 26px;
			cursor: pointer;
			z-index: 50;
			transition: all 20ms cubic-bezier(.4, 0, .2, 1);
			transition-delay: 100ms;

			span {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 33px;
				height: 3px;
				z-index: 1;
				background: #fff;
				transition: left 300ms cubic-bezier(.4, 0, .2, 1);

				&:last-child {
					top: 22px;
				}

				&.cross {
					top: 9px;
					left: -7px;
					z-index: 2;
					background: none;

					&:before,
					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						width: 100%;
						height: 3px;
						background: #fff;
						transition: all 350ms cubic-bezier(.4, 0, .2, 1);
					}
				}
			}

			&:hover {

				span.cross {
					left: 0;
				}
			}
		}

		&.active {

			a {

				span {

					&:first-child {
						opacity: 0;
					}

					&:last-child {
						opacity: 0;
					}

					&.cross {
						height: 26px;
						top: 0;
						left: 0;

						&:before {
							transform: rotate(-45deg);
						}

						&:after {
							transform: rotate(45deg);
						}
					}
				}
			}
		}
	}

	&.sticky {
		position: fixed;
		width: 100%;
		background-color: #05164e;

		&__logo {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 9;
			transition: all 0.25s ease-out;

			display: flex;
			align-items: flex-end;
			justify-content: center;

			&:after {
				content: "";
				position: absolute;
				top: -110%;
				left: 50%;
				transform: translateX(-50%);
				z-index: -1;

				width: 250px;
				height: 250px;
				background-color: #05164e;
				border-radius: 50%;
			}

			a {
				display: block;
				width: 100px;
				margin-top: 15px;

				img {
					width: 100%;
				}
			}
		}
	}
}

.homePage {

	.header {
		$self: &;

		background-color: transparent;
		box-shadow: none;

		@media (max-width: $desktopSmall - 1) {
			background-color: #05164e;
		}

		&__logo {

			&:after {
				display: none;
			}

			a {

				img {
					width: 100%;
				}
			}
		}

		&.sticky {
			position: fixed;
			width: 100%;
			background-color: #05164e;

			.header__logo {

				&:after {
					display: block;

					@media (max-width: $desktopSmall - 1) {
						display: none;
					}
				}
			}
		}
	}
}