$boxShadow: 0 0 5px 0 rgba(0,0,0,.05);

.commonDropdown {
    position: relative;

    &__handle {
        cursor: pointer;
        background: image('svg/arrow-down-angle.svg') calc(100% - 15px) center no-repeat white;
        background-size: 10px;
        border-radius: 4px;
        padding: 10px 70px 10px 20px;
        box-shadow: $boxShadow;
    }

    &__menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1010;
        min-width: 180px;
        background: white;
        border-radius: 0 0 5px 5px;
        box-shadow: $boxShadow;

        &.right { left: auto; right: 0; }

        .item {
            cursor: pointer;
            padding: 10px 15px;
            border-bottom: 1px solid #eee;

            &:last-child { border-bottom: none; }
            img { max-height: 28px; }
            &:hover { opacity: 0.75; }
        }
    }
}