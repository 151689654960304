.mainBanner {
    position: relative;

    @media (max-width: $desktopSmall - 1) {
        margin-top: 11.9rem;
        background: $primary_color;
    }

    @media (max-width: $mobileBig) {
        margin-top: 10.9rem;
        min-height: 56.8rem;
        padding: 0;
    }

    @media (max-width: $mobile) {
        margin-top: 9rem;
    }

    &__img {

        @media (max-width: $mobileBig) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    &__content {
        position: absolute;
        top: 45%;
        left: 15%;
        transform: translateY(-50%);
        width: 52rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;

        @media (max-width: $desktop_xl) {
            left: 14%;
            top: 55%;
            width: 44.5rem;
        }

        @media (max-width: $laptop - 1) {
            left: 10%;
        }

        @media (max-width: $desktop - 1) {
            left: 7%;
        }

        @media (max-width: $desktopSmall - 1) {
            top: 50%;
            left: 30%;
            transform: translate(-50%, -50%);
            text-align: center;
            padding: 0 1.5rem;
            width: 32.5rem;
        }

        @media (max-width: $mobileBig) {
            left: 50%;
            width: 100%;
            flex-direction: column;
        }

        h3 {
            font-size: 4rem;
            color: $yellow_color;
            text-transform: uppercase;
            margin: 0;

            span {
                display: block;
                color: $white_color;
                font-size: 5.5rem;
                font-weight: 700;

                @media (max-width: $desktop_xl) {
                    font-size: 4.5rem;
                }

                @media (max-width: $laptop - 1) {
                    font-size: 3.5rem;
                }

                @media (max-width: $desktopSmall - 1) {
                    font-size: 2.5rem;
                }

                @media (max-width: $mobileBig) {
                    font-size: 4.5rem;
                }
            }

            @media (max-width: $desktop_xl) {
                font-size: 3rem;
            }

            @media (max-width: $laptop - 1) {
                font-size: 2.5rem;
            }

            @media (max-width: $desktopSmall - 1) {
                font-size: 2rem;
            }

            @media (max-width: $mobileBig) {
                font-size: 3rem;
                line-height: 1.5;
            }
        }

        h1 {
            font-size: 9.2rem;
            font-family: $secondary_font;
            font-weight: 400;
            line-height: 1.75;
            letter-spacing: 0.01rem;
            margin: 0 0 .5rem;

            background: #f9c802;
            background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
            background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
            background-size: 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            position: relative;

            &:before {
                content: attr(data-heading);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                text-shadow: .3rem 0 0 #04113c;
                z-index: -1;
                color: transparent;
            }

            @media (max-width: $desktop_xl) {
                font-size: 8rem;
            }

            @media (max-width: $laptop - 1) {
                font-size: 6.5rem;
            }

            @media (max-width: $desktopSmall - 1) {
                font-size: 5rem;
            }

            @media (max-width: $mobileBig) {
                font-size: 7rem;
                margin-bottom: 2rem;
            }
        }
        
        .banner-btn {
            display: inline-block;
            font-family: $primary_font;
            font-weight: 700;
            font-size: 2.1rem;
            line-height: 1.2;
            letter-spacing: 0.015rem;
            color: $white_color;
            text-transform: uppercase;
            background-color: #ee1920;
            padding: 1.5rem 2.5rem;

            @media (max-width: $desktopSmall - 1) {
                font-size: 1.8rem;
            }

            @media (max-width: $mobileBig) {
                font-size: 1.6rem;
            }
        }
    }
}

.aboutus {
    background: transparent url(../img/aboutus/aboutus-bg.png) no-repeat left top / 100% auto;
    padding: 8rem 0;

    @media (max-width: $desktop - 1) {
        background-size: auto 100%;
        background-position: center center;
    }

    @media (max-width: $desktopSmall - 1) {
        padding: 3rem 0;
    }

    @media (max-width: $mobileBig) {
        background: $primary_color none;
    }

    &__heading {
        display: flex;
        flex-direction: column;

        @media (max-width: $mobileBig) {
            align-items: center;
        }

        h3 {
            font-size: 3.8rem;
            font-family: $primary_font;
            font-weight: 500;
            line-height: 1.2;
            color: $white_color;
            letter-spacing: 0.01rem;
            margin: 0;
        }

        h2 {
            font-size: 5.5rem;
            font-family: $secondary_font;
            font-weight: 200;
            line-height: 1.75;
            margin: 0 0 1rem;

            background: #f9c802;
            background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
            background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
            background-size: 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            position: relative;

            &:before {
                content: attr(data-heading);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                text-shadow: .3rem 0 0 #04113c;
                z-index: -1;
                color: transparent;
            }
        }
    }

    &__list {

        ul {
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 3rem;
                flex-wrap: wrap;

                .img {
                    display: flex;

                    img {
                        width: 100%;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 2.7rem);
                    padding-left: 1.5rem;

                    h4 {
                        font-family: $primary_font;
                        font-weight: 600;
                        font-size: 1.9rem;
                        line-height: 1.2;
                        letter-spacing: 0.01rem;
                        color: $white_color;
                        margin: 0 0 1rem;
                    }

                    p {
                        font-family: $primary_font;
                        font-weight: 300;
                        font-size: 1.6rem;
                        line-height: 1.2;
                        letter-spacing: 0.01rem;
                        color: $white_color;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.featured {
    background: transparent url(../img/amazingfeature/amazingfeature-bg.png) no-repeat center bottom / 50% auto;
    padding: 0;

    @media (max-width: $desktopSmall - 1) {
        background-image: none;
        padding: 5rem 0 2rem;
    }

    &__main {
        position: relative;
        min-height: 45rem;

        @media (max-width: $desktopSmall - 1) {
            min-height: auto;
        }

        .heading {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: $laptop - 1) {
                top: 40%;
            }

            @media (max-width: $desktop - 1) {
                top: 53%;
            }

            @media (max-width: $desktopSmall - 1) {
                position: static;
                transform: none;
                margin: 0 0 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            h2 {
                display: flex;
                align-items: center;
                font-size: 3.8rem;
                font-family: $primary_font;
                font-weight: 500;
                line-height: 1.2;
                letter-spacing: 0.01rem;
                margin: 0 0 1rem;
                color: #1a0126;

                @media (max-width: $desktop_xl - 1) {
                    font-size: 2.8rem;
                }

                @media (max-width: $mobileBig) {
                    font-size: 3.8rem;
                    flex-direction: column;
                }

                span {
                    display: inline-block;
                    font-size: 5.5rem;
                    font-family: $secondary_font;
                    font-weight: 200;
                    line-height: 1.75;
                    margin-left: 1rem;

                    background: #f9c802;
                    background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
                    background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
                    background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
                    background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
                    background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
                    background-size: 100%;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    position: relative;

                    &:before {
                        content: attr(data-heading);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        text-shadow: .3rem 0 0 #e5dede;
                        z-index: -1;
                        color: transparent;
                    }

                    @media (max-width: $desktop_xl - 1) {
                        font-size: 4.5rem;
                    }

                    @media (max-width: $mobileBig) {
                        font-size: 5.5rem;
                        display: block;
                    }
                }
            }
        }

        .content {

            @media (max-width: $desktopSmall - 1) {
                display: flex;
                flex-wrap: wrap;
            }

            &_details {
                display: flex;
                width: 39rem;
                position: absolute;

                &--first {
                    bottom: 3rem;
                    left: -4.5rem;
                }

                &--second {
                    bottom: 18rem;
                    left: .5rem;
                }

                &--third {
                    bottom: 31rem;
                    left: 13rem;
                }

                &--fourth {
                    bottom: 31rem;
                    right: 13rem;
                }

                &--fifth {
                    bottom: 18rem;
                    right: .5rem;
                }

                &--sixth {
                    bottom: 3rem;
                    right: -4.5rem;
                }

                &--first,
                &--second,
                &--third {
                    text-align: right;

                    @media (max-width: $desktopSmall - 1) {
                        text-align: left;
                    }
                }

                &--first,
                &--second,
                &--third {

                    .featuredcard {
                        flex-direction: row-reverse;

                        @media (max-width: $desktopSmall - 1) {
                            flex-direction: row;
                        }
                    }
                }

                &--fourth,
                &--fifth,
                &--sixth {

                    .featuredcard {
                        flex-direction: row;
                    }
                }

                @media (max-width: $laptop - 1) {
                    width: 35rem;

                    &--first {
                        bottom: 3rem;
                        left: -2.5rem;
                    }

                    &--second {
                        bottom: 16.5rem;
                        left: 2.5rem;
                    }

                    &--third {
                        bottom: 28rem;
                        left: 13rem;
                    }

                    &--fourth {
                        bottom: 28rem;
                        right: 13rem;
                    }

                    &--fifth {
                        bottom: 16.5rem;
                        right: 2.5rem;
                    }

                    &--sixth {
                        bottom: 3rem;
                        right: -2.5rem;
                    }
                }

                @media (max-width: $desktop - 1) {
                    width: 30rem;

                    &--first {
                        bottom: 2rem;
                        left: -3rem;
                    }

                    &--second {
                        bottom: 13.5rem;
                        left: .5rem;
                    }

                    &--third {
                        bottom: 23rem;
                        left: 10rem;
                    }

                    &--fourth {
                        bottom: 23rem;
                        right: 10rem;
                    }

                    &--fifth {
                        bottom: 13.5rem;
                        right: .5rem;
                    }

                    &--sixth {
                        bottom: 2rem;
                        right: -3rem;
                    }
                }

                @media (max-width: $desktopSmall - 1) {
                    width: 50%;
                    position: static;
                    margin-bottom: 3rem;
                }

                @media (max-width: $mobileBig) {
                    width: 100%;
                }

                .featuredcard {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &__img {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 8.9rem;
                        height: 8.9rem;
                        border-radius: 50%;
                        background-color: $primary_color;
                        border: .5rem solid $white_color;

                        img {
                            width: auto;
                        }
                    }

                    &__details {
                        display: flex;
                        flex-direction: column;
                        width: calc(100% - 10rem);

                        h4 {
                            font-family: $primary_font;
                            font-weight: 500;
                            font-size: 1.9rem;
                            line-height: 1.2;
                            letter-spacing: 0.01rem;
                            color: $black_color;
                            margin: 0 0 .5rem;

                            @media (max-width: $desktop_xl) {
                                font-size: 1.7rem;
                            }
                        }

                        p {
                            font-family: $primary_font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 1.2;
                            letter-spacing: 0.01rem;
                            color: #111111;
                            margin: 0;

                            @media (max-width: $desktop_xl) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.howitworks {

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 90%;

            @media (max-width: $desktopSmall - 1) {
                width: 100%;
            }
        }
    }

    &__right {
        text-align: center;
        padding-left: 5%;

        @media (max-width: $desktopSmall - 1) {
            padding-left: 0;
        }

        h2 {
            font-size: 5rem;
            font-family: $secondary_font;
            font-weight: 700;
            line-height: 1.2;
            color: $heading_color;
            margin: 0 0 3.5rem;

            @media (max-width: $mobileBig) {
                font-size: 3rem;
            }
        }

        p {
            font-size: 1.7rem;
            font-family: $primary_font;
            font-weight: 300;
            line-height: 1.5;
            color: $heading_color;
            margin: 0 0 7rem;

            br {

                @media (max-width: $desktop - 1) {
                    display: none;
                }
            }

            @media (max-width: $mobileBig) {
                font-size: 1.5rem;
            }
        }

        &--boxes {
            display: flex;
            flex-wrap: wrap;

            &-box {
                width: calc((100% / 3) - 2rem);
                position: relative;
                // background-color: $white_color;
                padding: 6rem 2rem 4rem;
                margin-right: 3rem;
                text-align: center;
                box-shadow: 0 0 2.7rem .8rem rgba(#0724b8, 0.13);
                border-radius: 1rem;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                @media (max-width: $mobileBig) {
                    width: 100%;
                    margin-right: 0;

                    &:not(:last-child) {
                        margin-bottom: 8rem;
                    }
                }

                figure {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 9.2rem;
                    height: 9.2rem;
                    border-radius: 50%;
                    background-color: $primary_color;
                    margin: 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: auto;
                    }
                }

                h3 {
                    font-size: 2.1rem;
                    font-family: $primary_font;
                    font-weight: 700;
                    line-height: 1.2;
                    color: $heading_color;
                    margin: 0 0 2rem;
                }

                p {
                    font-size: 1.5rem;
                    font-family: $primary_font;
                    font-weight: 300;
                    line-height: 1.5;
                    color: $heading_color;
                    margin: 0;
                }
            }
        }
    }
}

.playnows {
    background-color: #e9141a;

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            font-size: 3rem;
            font-weight: 600;
            color: $white_color;
            margin: 0 0 3rem;
            text-transform: uppercase;

            span {
                display: inline-block;
                font-weight: 800;
            }
        }

        ul {
            display: flex;
            align-items: center;

            li {
                display: block;

                a {
                    display: block;
                    margin-right: 2rem;

                    img {
                        width: auto;
                    }
                }
            }
        }
    }
}

.testimonial {

    &__heading {

        h3 {
            font-size: 3.8rem;
            font-family: $primary_font;
            font-weight: 500;
            line-height: 1.2;
            color: #1a0126;
            letter-spacing: 0.01rem;
            margin: 0;
        }

        h2 {
            font-size: 6.5rem;
            font-family: $secondary_font;
            font-weight: 200;
            line-height: 1.75;
            margin: 0;

            background: #f9c802;
            background: -moz-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9c802), color-stop(100%, #f19a05));
            background: -webkit-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -o-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: -ms-linear-gradient(top, #f9c802 0%, #f19a05 100%);
            background: linear-gradient(to bottom, #f9c802 0%, #f19a05 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9c802', endColorstr='#f19a05', GradientType=0);
            background-size: 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            position: relative;

            &:before {
                content: attr(data-heading);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                text-shadow: .3rem 0 0 #04113c;
                z-index: -1;
                color: transparent;
            }
        }
    }

    &__slider {

        &.slick-dotted.slick-slider {
            margin-bottom: 8rem;
        }

        .slick-track {
            padding: 4rem 0;
        }

        .item {
            background-color: $white_color;
            padding: 4rem;
            margin: 0 2rem;
            border-radius: .5rem;
            box-shadow: 0 0 1rem .1rem rgba($black_color, 0.05);

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            .discription {
                color: #111111;
                line-height: 1.75;
                margin: 0 0 2rem;
            }

            .starRating {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 2rem;

                li {
                    display: inline-block;

                    a {
                        display: block;
                        margin: 0 .5rem;
                        font-size: 1.4rem;

                        &.selected {
                            color: #ffb100;
                        }

                        &.unselected {
                            color: #d0d0d0;
                        }

                        i {}
                    }
                }
            }

            .user {
                font-size: 1.6rem;
                font-family: $primary_font;
                font-weight: 500;
                font-style: italic;
                line-height: 1.2;
                color: #1a0126;
                letter-spacing: 0.01rem;
                margin: 0 0 1rem;
            }
        }

        .slick-center {

            .item {
                transform: scale(1.05);
            }
        }

        .slick-dots {
            bottom: 0;
            display: flex !important;
            align-items: center;
            justify-content: center;

            li {
                background-color: #b3b3b3;
                opacity: 1;

                &.slick-active {
                    background-color: #ececec;
                    opacity: 1;
                }

                button {}
            }
        }
    }

    &__video {

        > .container {
            position: relative;
        }

        figure {
            position: relative;
            z-index: 0;
            border-radius: 2.5rem;
            overflow: hidden;
            margin: 0;

            @media (max-width: $mobileBig) {
                min-height: 24rem;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(#000000, 0.65);
            }

            img {
                width: 100%;

                @media (max-width: $mobileBig) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: auto;
                    height: auto;
                    max-width: none;
                    max-height: 100%;
                }
            }
        }

        .playButton {
            display: flex;
            align-items: center;
            flex-direction: column;

            position: absolute;
            top: 50%;
            left: 20%;
            transform: translate(-50%, -50%);
            z-index: 0;

            // &:before {
            //     content: "";
            //     width: 7.2rem;
            //     height: 7.2rem;
            //     position: absolute;
            //     left: 50%;
            //     top: 50%;
            //     border-radius: 50%;
            //     -webkit-box-shadow: 0rem .1rem 1.5rem .1rem rgba(255, 255, 255, 0.8);
            //     box-shadow: 0rem .1rem 1.5rem .1rem rgba(255, 255, 255, 0.8);
            //     -webkit-animation: youTube 2s linear infinite;
            //     animation: youTube 2s linear infinite;
            //     z-index: -1;
            // }

            // @-webkit-keyframes youTube {
            //     0% {
            //         -webkit-transform: translate(-50%, -50%) scale(0);
            //         transform: translate(-50%, -50%) scale(0);
            //     }

            //     100% {
            //         -webkit-transform: translate(-50%, -50%) scale(3);
            //         transform: translate(-50%, -50%) scale(3);
            //         opacity: 0;
            //     }
            // }

            // @keyframes youTube {
            //     0% {
            //         -webkit-transform: translate(-50%, -50%) scale(0);
            //         transform: translate(-50%, -50%) scale(0);
            //     }

            //     100% {
            //         -webkit-transform: translate(-50%, -50%) scale(3);
            //         transform: translate(-50%, -50%) scale(3);
            //         opacity: 0;
            //     }
            // }

            img {
                width: auto;
                margin-bottom: 2rem;
            }

            span {
                font-family: $primary_font;
                font-weight: 500;
                font-size: 2.2rem;
                line-height: 1.2;
                letter-spacing: 0.01rem;
                color: $white_color;

                @media (max-width: $mobile - 1) {
                    font-size: 1.6rem;
                }
            }

            @media (max-width: $mobileBig) {
                left: 50%;

                img {
                    width: 7.5rem;
                }
            }
        }
    }
}