.btn {
	font-family: $secondary_font;
	font-size: 16px;
	border: none;
	line-height: 1;
	padding: 15px 30px;
	@include css3BoxShadow(none);
	@include css3BorderRadius(0);
	@include css3Transition(400ms);
	
	// Font Families
	&.font_normal {
		font-family: $secondary_font;
	}
	&.font_semi {
		font-family: $secondary_font;
	}
	&.font_bold {
		font-family: $secondary_font;
	}

	// Rounded
	&.elipse {
		@include css3BorderRadius(25px);
	}
	&.rounded-3 {
		@include css3BorderRadius(3px);
	}
	&.rounded-4 {
		@include css3BorderRadius(4px);
	}
	&.rounded-5 {
		@include css3BorderRadius(5px);
	}

	// Buttons Sizes
	&.btn-sm {
		font-size: 14px;
		padding: 10px 25px;
	}
	&.btn-lg {
		font-size: 18px;
		padding: 17px 40px;

		@media (max-width: $mobile) {
			font-size: 15px;
			padding: 15px 25px;
		}
	}
	
	// Buttons Colors
	&.btn-primary {
		position: relative;
		z-index: 1;
		background: $primary_color;
		outline: none;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 0;
			z-index: -1;
			width: 100%;
			background: $primary_hover;
			// @include css3BorderRadius(4px);
			@include css3Transition(200ms);
		}

		&:hover {
			// color: #fff;
			// border-color: #3550a5;
			// background-color: #2f4998;
			// box-shadow: 0 0 0 0.2rem rgba(36, 58, 126, 0.55);
			background: $primary_hover;

			&:before {
				height: 100%;
			}
		}
	}
	&.btn-secondary {
		background: $secondary_color;
		color:#fff;
		
		&:hover {
			background: $secondary_color;
		}
	}
	&.btn-default {
		background: #cdcdcd;
		color: #000;
		
		&:hover {
			background: #bfbfbf;
		}
	}
	&.btn-border {
		color:#232323;
		border:solid #909090 1px;
		
		&:hover {
			color: white;
			background:$primary_color;
			border-color: $primary_color;
		}
	}
	&.btn-border-primary {
		color: $primary_color;
		border:solid $primary_color 1px;
		
		&:hover {
			color: white;
			background:$primary_color;
		}
	}
	&.btn-border-white {
		color:#fff;
		border:solid #fff 1px;
		
		&.rounded {
			@include css3BorderRadius(25px);
		}
		
		&:hover {
			background:#fff;
			color:#222;
		}
	}
	
	&.btn-dark {
		color:#fff;
		background: #676767;
		
		&:hover {
			background: #232323;
		}
	}
	
	&.btn-white {
		font-family:$secondary_font;
		color:#333;
		background:#fff;
		
		&:hover {
			color:#fff;
			background:$primary_color;
		}
	}

	&.btn-block {
		display: block;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;

		@media (max-width: $mobile) {
			padding: 15px;
		}
	}

	@media (max-width: $mobile) {
		font-size: 13px;
		padding: 8px 15px;
	}
}

.btnViewAll {
	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	color: #666666;
	padding-right: 40px;
	text-transform: uppercase;

	.arrow {
		position: absolute;
		top: 50%;
		right: 0;
		z-index: -1;
		width: 30px;
		height: 14px;
		margin-left: 5px;
		background-color: #777;
		transform: translateY(-50%);
		clip-path: polygon(0 44%, 61% 44%, 61% 20%, 83% 55%, 61% 83%, 61% 63%, 0 64%);
		transition: all .25s ease-in-out;
	}

	&:hover {
		color: $primary_color;
		.arrow {
			width: 40px;
			background-color: $primary_color;
			transform: translate(10px, -50%);
		}
	}
}

// Breadcrumb back button
.backBtn {
	padding: 20px 0;
	a { color: #333333; }
}
