.privacyPage {
    display: flex;
    margin-top: 7.8rem;
    align-items: center;
    justify-content: center;

    @media (max-width: $desktopSmall - 1) {
        margin-top: 11.9rem;
    }

    @media (max-width: $mobileBig) {
        margin-top: 10.9rem;
        padding: 3rem 0;
    }

    @media (max-width: $mobile) {
        margin-top: 9rem;
    }

    h2 {
        font-family: $primary_font;
        font-weight: 700;
        font-size: 2.7rem;
        line-height: 1.5;
        letter-spacing: -0.005rem;
        color: $white_color;
        text-transform: uppercase;
        margin: 0;

        @media (max-width: $mobile - 1) {
            font-size: 1.8rem;
        }
    }
}

.privacy {
    font-size: 16px;
    background-color: #dbdbf0;


    ol {
        padding-left: 17px;

        li {
            list-style-position: inside;
        }
    }
    &__policy {

        &:not(:last-child) {
            margin: 0 0 5rem;
        }

        h5 {
            font-family: $primary_font;
            font-weight: 700;
            font-size: 2.7rem;
            line-height: 1.5;
            letter-spacing: -0.005rem;
            color: $primary_color;
            margin: 0 0 1.5rem;

            @media (max-width: $mobile - 1) {
                font-size: 1.8rem;
            }
        }

        p {
            font-family: $primary_font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 1.75;
            letter-spacing: -0.005rem;
            color: $black_color;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 2.5rem;
            }

            @media (max-width: $mobile - 1) {
                font-size: 1.4rem;
            }
        }

        ul {
            
            li {
                display: block;
                font-family: $primary_font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 1.75;
                letter-spacing: -0.005rem;
                color: $black_color;
                position: relative;
                padding-left: 2rem;

                &:not(:last-child) {
                    margin-bottom: 2.5rem;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 1.4rem;
                    left: 0;
                    transform: translateY(-50%);
                    width: .8rem;
                    height: .8rem;
                    border-radius: 50%;
                    background-color: $primary_color;
                }

                @media (max-width: $mobile - 1) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}